import { Dropdown } from "react-bootstrap";
import { useAppSelector } from "../../../hooks";
import { selectUserData } from "../../../redux/reducer/user/userSlice";
import PageAgreeAction from "./PageAgreeAction";

//-------------------------------------------------------------------------------------
export default function PageAgreeSetupAction({ obj }: { obj: any }) {
    //redux
    const { user } = useAppSelector(selectUserData);
    //funView
    const chooseCollectionAction = () => {
        switch (obj.status) {
            case "open": {
                return (
                    <>
                        <PageAgreeAction driver="extend" obj={obj} />
                        <PageAgreeAction driver="close" obj={obj} />
                        <Dropdown.Divider />
                        <PageAgreeAction driver="view" obj={obj} />
                        {user.role === "A" && <PageAgreeAction driver="edit" obj={obj} />}
                        <Dropdown.Divider />
                        <PageAgreeAction driver="history" obj={obj} />
                    </>
                );
            }
            case "extend": {
                return (
                    <>
                        <PageAgreeAction driver="extend" obj={obj} />
                        <PageAgreeAction driver="close" obj={obj} />
                        <Dropdown.Divider />
                        <PageAgreeAction driver="view" obj={obj} />
                        {user.role === "A" && <PageAgreeAction driver="edit" obj={obj} />}
                        <Dropdown.Divider />
                        <PageAgreeAction driver="history" obj={obj} />
                    </>
                );
            }
            case "closed": {
                return (
                    <>
                        <PageAgreeAction driver="view" obj={obj} />
                        <Dropdown.Divider />
                        <PageAgreeAction driver="history" obj={obj} />
                    </>
                );
            }
            case "frozen": {
                return (
                    <>
                        <PageAgreeAction driver="view" obj={obj} />
                        <Dropdown.Divider />
                        <PageAgreeAction driver="history" obj={obj} />
                    </>
                );
            }
            case "expired": {
                return (
                    <>
                        <PageAgreeAction driver="depot" obj={obj} />
                        <PageAgreeAction driver="view" obj={obj} />
                        <Dropdown.Divider />
                        <PageAgreeAction driver="history" obj={obj} />
                    </>
                );
            }
        }
    };

    //view
    return <>{chooseCollectionAction()}</>;
}
