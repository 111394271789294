import { useState } from "react";
import { Button } from "react-bootstrap";
//comp
import ClientAddNav from "../navig/client/add/ClientAddNav";

import { AiOutlineUsergroupAdd } from "react-icons/ai";

//--------------------------------------------------------
export default function AddButton() {
    //hook
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //view
    return (
        <>
            <Button
                style={{ marginRight: "10px" }}
                size="lg"
                variant="info"
                onClick={() => {
                    handleShow();
                }}
            >
                <AiOutlineUsergroupAdd />
            </Button>
            <ClientAddNav show={show} handleClose={handleClose} />
        </>
    );
}
