import styles from "../../styles/User.module.css";
import { Container, Navbar, Nav } from "react-bootstrap";
//import SearchIndex from './search/SearchIndex';

//------------------------------------------------------

export default function PageUserNav() {
    return (
        <Navbar expand="lg" bg="secondary" variant="dark" className={styles.navBarRadius}>
            <Container fluid>
                <Navbar.Brand>KONTA PRACOWNIKÓW</Navbar.Brand>
                <Navbar>
                    <Nav className="me-auto"></Nav>
                    <Nav>{/* <SearchIndex /> */}</Nav>
                </Navbar>
            </Container>
        </Navbar>
    );
}
