import { Nav } from "react-bootstrap";
//redux
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { selectBoardData, setBoardData } from "../../../redux/reducer/board/boardSlice";

//-----------------------------------------------------------
export default function ItemNav() {
    //redux
    const dispatch = useAppDispatch();
    const data = useAppSelector(selectBoardData);

    //view
    return (
        <Nav.Item>
            <Nav.Link
                active={data === "item"}
                eventKey="item-link"
                onClick={() => {
                    dispatch(setBoardData("item"));
                }}
            >
                Przedmioty
            </Nav.Link>
        </Nav.Item>
    );
}
