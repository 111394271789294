import { Form, Row, Col, FloatingLabel } from "react-bootstrap";
//redux
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { selectUserData } from "../../../redux/reducer/user/userSlice";
import {
    setPageData,
    selectPageData,
    searchAgreeDataAsync,
    selectSearchData,
    setSearch,
} from "../../../redux/reducer/agree/agreeSliceP";

//---------------------------------------------------

export default function SearchByStatus() {
    //redux
    const dispatch = useAppDispatch();
    const { access_token } = useAppSelector(selectUserData);
    const searchData = useAppSelector(selectSearchData);
    const pageData = useAppSelector(selectPageData);

    //onchange
    const onChangeStatus = (ev: any) => {
        const { value } = ev.target;
        const search = { ...searchData, status: value };
        dispatch(setSearch(search));

        if (pageData > 1) dispatch(setPageData(0));
        dispatch(
            searchAgreeDataAsync({
                data: {
                    driver: { ps: "P" },
                    search,
                },
                access_token,
            })
        );
    };

    return (
        <Form>
            <Row className="g-0">
                <Col sm>
                    <FloatingLabel label="Status">
                        <Form.Select id="active" onChange={onChangeStatus}>
                            <option value="all">Wszystkie</option>
                            <option value="within">W terminie</option>
                            <option value="open">Otwarte</option>
                            <option value="extend">Przedłużone</option>
                            <option value="closed">Wykupione</option>
                            <option value="frozen">Zamrożone</option>
                            <option value="expired">Po terminie</option>
                            {/* <option value="depot">Na magazyn</option> */}
                        </Form.Select>
                    </FloatingLabel>
                </Col>
            </Row>
        </Form>
    );
}
