import { Table } from "react-bootstrap";

//--------------------------------------------------------------------------
export default function ItemViewFileList({ image }) {
    //view
    return (
        <Table bordered size="sm">
            <tbody>
                {image.map((path, inx) => {
                    return (
                        <tr key={inx}>
                            <td>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <img
                                                    width="350px"
                                                    alt="foto"
                                                    src={`${process.env.PUBLIC_URL}/item/image/${path}`}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
}
