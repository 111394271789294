//style
import styles from "../../../../styles/Item.module.css";
import { Col, Row, ListGroup } from "react-bootstrap";
//comp
import ItemViewFileList from "./ItemViewFileList";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";
//redux
import FormItem from "../FormItem";

//-------------------------------------------------------------------
export default function Index({ item }: { item: any }) {
    //Formikx
    const formik = useFormik({
        initialValues: {
            id: item.id,
            id_location: item.id_location,
            status: item.status,
            name: item.name,
            quality: item.quality,
            amount: item.amount,
            notes: item.notes,
            image: item.image,
        },
        validationSchema: Yup.object({}),
        onSubmit: () => {},
    });

    //view
    return (
        <Row>
            <Col className="d-grid gap-1">
                <ListGroup className={styles.textRight}>
                    <ListGroup.Item variant="primary">DANE PRZEDMIOTU</ListGroup.Item>
                </ListGroup>
                <FormItem formik={formik} disabled={true} />
                <ItemViewFileList image={item.image} />
            </Col>
        </Row>
    );
}
