// import UserEditModal from "../../modal/user/edit/admin/UserEditModal";
import styles from "../../../styles/User.module.css";
import { Table } from "react-bootstrap";
//redux
import { useAppSelector } from "../../../hooks";
import { selectUserData } from "../../../redux/reducer/user/userSlice";

//----------------------------------------------------------

export default function PageUserTab() {
    // const [modalShow, setModalShow] = useState(false);
    //redux
    const { users } = useAppSelector(selectUserData);
    // const dispatch = useDispatch();

    return (
        <>
            <Table striped bordered hover size="lg" responsive>
                <thead>
                    <tr className={styles.textCenter}>
                        <th style={{ width: "50px" }}>#</th>
                        <th>User name</th>
                        <th>Email</th>
                        <th>Phone</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((obj, inx) => {
                        return (
                            <tr key={inx} className={obj.active !== "U" ? "textCenter" : "textCenter inactiveUser"}>
                                <td>{inx + 1}</td>
                                <td>{obj.name}</td>
                                <td>{obj.email}</td>
                                <td>{obj.phone}</td>
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot></tfoot>
            </Table>
            {/* <UserEditModal show={modalShow} onHide={() => setModalShow(false)} /> */}
        </>
    );
}
