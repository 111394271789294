import { Modal, Button } from "react-bootstrap";
import AgreeIndex from "./AgreeIndex";
//redux
import { useAppDispatch } from "../../../../../hooks";
import { delSessionData } from "../../../../../redux/reducer/agree/agreeSliceCreate";
import { setStatusUpdate } from "../../../../../redux/reducer/agree/agreeSliceP";

//---------------------------------------------------------------

export default function AgreeModal({ show, onHide }: { show: boolean; onHide: any }) {
    //redux
    const dispatch = useAppDispatch();

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            //size="lg"
            dialogClassName="modal1095w"
            backdrop="static"
            keyboard={false}
            scrollable={true}
            onExited={() => {
                dispatch(delSessionData());
                dispatch(setStatusUpdate("init"));
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Przedłużenie umowy przedwstępnej</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AgreeIndex />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}> Close</Button>
            </Modal.Footer>
        </Modal>
    );
}
