import { useState } from "react";
//css
import styles from "../../../styles/Location.module.css";
import { ListGroup, Button, InputGroup, FormControl, Form, Row, Col, FloatingLabel } from "react-bootstrap";
import { BsSave } from "react-icons/bs";
//comp
import LocationSubmit from "./LocationSubmit";
//redux
import { useAppSelector, useAppDispatch } from "../../../hooks";
import {
    selectStatusAddLocation,
    selectUserData,
    updateLocatonDataAsync,
    setLocationData,
    setStatusUpdateLocation,
    selectStatusUpdateLocation,
} from "../../../redux/reducer/user/userSlice";

//----------------------------------------------------------------------------------------------
export default function LocationIndex() {
    //redux
    const { locations, access_token } = useAppSelector(selectUserData);
    const statusLocation = useAppSelector(selectStatusAddLocation);
    const statusUpdateLocation = useAppSelector(selectStatusUpdateLocation);
    const dispatch = useAppDispatch();
    //state
    const [newPoint, setNewPoint] = useState("");
    // const [] = useState();

    //onchange add location
    const onChangeHandler = (ev: any) => {
        const { value } = ev.target;
        setNewPoint(value);
    };

    //update location
    const updateLocation = (obj: any, inx: number) => {
        //const obj = dataPoint[inx];
        if (obj.location.length > 2) {
            dispatch(setStatusUpdateLocation(inx));
            dispatch(updateLocatonDataAsync({ data: { id: obj.id, location: obj.location }, access_token }));
        } else {
            alert("Wypełnij pole Punkt");
        }
    };

    //view
    return (
        <Form>
            <Row>
                <Col className="d-grid gap-1">
                    <hr />
                    <ListGroup className={styles.textRight}>
                        <ListGroup.Item variant="primary">DODAJ LOKALIZACJĘ</ListGroup.Item>
                    </ListGroup>
                    <FloatingLabel label="Nazwa nowej lokalizacji">
                        <Form.Control
                            id="point"
                            type="text"
                            value={newPoint}
                            onChange={onChangeHandler}
                            disabled={statusLocation !== "init"}
                            placeholder="Nazwa nowej lokalizacji"
                        />
                    </FloatingLabel>
                    <LocationSubmit newPoint={newPoint} />
                    <hr />
                    <ListGroup className={styles.textRight}>
                        <ListGroup.Item variant="primary">UPDATE LOKALIZACJI</ListGroup.Item>
                    </ListGroup>
                    <ListGroup className="textCenter">
                        {locations.map((obj, inx) => {
                            return (
                                <InputGroup key={inx}>
                                    <FormControl
                                        type="text"
                                        value={obj.location}
                                        // disabled={}
                                        onChange={(ev) => {
                                            dispatch(setLocationData({ inx, location: ev.target.value }));
                                        }}
                                    />
                                    <Button
                                        variant={statusUpdateLocation[inx]}
                                        onClick={() => {
                                            updateLocation(obj, inx);
                                        }}
                                    >
                                        <BsSave />
                                    </Button>
                                </InputGroup>
                            );
                        })}
                    </ListGroup>
                </Col>
            </Row>
        </Form>
    );
}
