import { Col, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
// //redux
import { selectUserData } from "../../../../../../redux/reducer/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks";
import { selectAgreeData } from "../../../../../../redux/reducer/agree/agreeSliceCreate";
import AgreeSubmit from "./AgreeSubmit";
import { selectSearchData } from "../../../../../../redux/reducer/agree/agreeSliceP";
import FormAgree from "../../FormAgree";
import TableAgree from "../../TableAgree";
import { selectStatus, updateAgreeDataAsync } from "../../../../../../redux/reducer/agree/agreeSliceS";

// //----------------------------------------------------
export default function AgreeForm() {
    //     //redux
    const dispatch = useAppDispatch();
    //     const search = useSelector(selectSearchData);
    const { id, notes, client, item } = useAppSelector(selectAgreeData);
    const { statusAgreeSupdate } = useAppSelector(selectStatus);
    const { user, access_token } = useAppSelector(selectUserData);
    const search = useAppSelector(selectSearchData);

    //     //Formik
    const formik = useFormik({
        initialValues: {
            notes: notes,
        },
        validationSchema: Yup.object({
            notes: Yup.string().nullable(),
        }),
        onSubmit: (values) => {
            //update values
            if (!item.some((x) => x.price === 0)) {
                const driver = {
                    ps: "S", //wybrano umowy przedstepne
                    id: id, //wybrano zapis nowej umowy
                };
                const data = {
                    id_location: user.id_location, //id lokalizacji
                    client: JSON.stringify(client), //dane klienta
                    item: JSON.stringify(item), //dane przedmiotu
                    notes: values.notes, //notatka
                };
                // const search = {
                //     ...searchData, //obecny stan parametrow wyszukiwania
                //     id_location: user.id_location, //update lokalizacji przypisanej userowi
                //     status: "open", //nowo utworzone umowy otrzymuja status open
                // };
                //save agreement
                dispatch(
                    updateAgreeDataAsync({
                        data: {
                            driver,
                            data,
                            search,
                        },
                        access_token,
                    })
                );
            } else {
                alert("Wpisz kwotę produktu.");
            }
        },
    });

    //view
    return (
        <Form>
            <Row>
                <Col className="d-grid gap-1 mb-1">
                    <FormAgree formik={formik} item={item} disabled={statusAgreeSupdate === "success"} />
                    <TableAgree item={item} />
                    <AgreeSubmit formik={formik} />
                </Col>
            </Row>
        </Form>
    );
}
