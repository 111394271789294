import { Offcanvas } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
    initialState,
    selectStatusData,
    setStatusPesel,
    setStatusUpdate,
    updateClientDataAsync,
} from "../../../../redux/reducer/client/clientSlice";
import { selectUserData } from "../../../../redux/reducer/user/userSlice";
import Index from "../../../client/form/add/Index";

//-------------------------------------------------------------------------------

export default function ClientAddNav({ show, handleClose }: { show: boolean; handleClose: object }) {
    //redux
    const dispatch = useAppDispatch();
    const { statusUpdate, statusPesel } = useAppSelector(selectStatusData);
    const { access_token } = useAppSelector(selectUserData);

    //view
    return (
        <>
            <Offcanvas
                show={show}
                onHide={handleClose}
                placement="end"
                scroll={true}
                backdrop={true}
                onExited={() => {
                    dispatch(setStatusPesel("init"));
                    dispatch(setStatusUpdate("init"));
                }}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Index
                        client={initialState.data.client}
                        callback={(values: any) => {
                            // update values
                            if (statusPesel === "success") {
                                dispatch(
                                    updateClientDataAsync({
                                        data: { data: values, search: initialState.search },
                                        access_token,
                                    })
                                );
                            } else {
                                alert("Duplikat PESEL.");
                            }
                        }}
                        disabled={statusUpdate !== "init"}
                    />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
