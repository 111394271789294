import { Col, Form, Row, ListGroup } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormClient from "../FormClient";
import styles from "../../../../styles/Client.module.css";

/**
 *
 * @client Dane klienta
 * @callback Funkcja do update
 * @disabled Disable all fields
 *
 * @returns
 */
export default function Index({ client }: { client: any }) {
    //Formik
    const formik = useFormik({
        initialValues: {
            id: client.id,
            firstname: client.firstname,
            surname: client.surname,
            nrDow: client.nrDow,
            pesel: client.pesel,
            address: client.address,
            phone: client.phone,
            notes: client.notes,
            active: client.active,
        },
        validationSchema: Yup.object({}),
        onSubmit: () => {},
    });

    //view
    return (
        <Form>
            <Row>
                <Col className="d-grid gap-1 mb-1">
                    <ListGroup className={styles.textRight}>
                        <ListGroup.Item variant="primary">DANE KLIENTA</ListGroup.Item>
                    </ListGroup>
                    <FormClient formik={formik} disabled={true} />
                </Col>
            </Row>
        </Form>
    );
}
