//bootstrap
import { Button, Spinner } from "react-bootstrap";
//redux
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { selectStatusData, setStatusUpdate } from "../../../../redux/reducer/item/itemSlice";

//----------------------------------------------------------

export default function ItemSubmit({ formik }: any) {
    //redux
    const dispatch = useAppDispatch();
    const { statusUpdate } = useAppSelector(selectStatusData);

    //switch buttons
    const switchButtons = () => {
        switch (statusUpdate) {
            case "init": {
                return (
                    <Button
                        variant="info"
                        size="lg"
                        onClick={() => {
                            formik.handleSubmit();
                        }}
                    >
                        Update przedmiot
                    </Button>
                );
            }
            case "load": {
                return (
                    <Button variant="primary" disabled>
                        <Spinner as="span" animation="border" role="status" aria-hidden="true" />
                        <span className="visually-hidden">Loading...</span>
                    </Button>
                );
            }
            case "success": {
                return (
                    <Button variant="success" size="lg" disabled>
                        Przedmiot updated
                    </Button>
                );
            }
            case "unsuccess": {
                return (
                    <Button
                        variant="warning"
                        size="lg"
                        onClick={() => {
                            dispatch(setStatusUpdate("init"));
                            //setViewFileList(null);
                            //setFileSelected([]);
                        }}
                    >
                        Zapis nieudany
                    </Button>
                );
            }
            case "failed": {
                return (
                    <Button
                        variant="danger"
                        size="lg"
                        onClick={() => {
                            dispatch(setStatusUpdate("init"));
                            //setViewFileList(null);
                            //setFileSelected([]);
                        }}
                    >
                        Połączenie nieudane
                    </Button>
                );
            }
            default: {
            }
        }
    };

    //view
    return <>{switchButtons()}</>;
}
