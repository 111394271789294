import { useState } from "react";
import Index from "../../../item/form/add/Index";
import { Offcanvas } from "react-bootstrap";
//redux
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { selectUserData } from "../../../../redux/reducer/user/userSlice";
import {
    selectStatusData,
    setStatusUpdate,
    updateItemDataAsync,
    selectSearchData,
    initialState,
} from "../../../../redux/reducer/item/itemSlice";

//-------------------------------------------------------------------------------
export default function ItemAddNav({ show, handleClose }: { show: boolean; handleClose: object }) {
    //hook
    const [files, setFiles] = useState([]);

    //redux
    const dispatch = useAppDispatch();
    const { statusUpdate } = useAppSelector(selectStatusData);
    const searchData = useAppSelector(selectSearchData);
    const { user, access_token } = useAppSelector(selectUserData);

    //view
    return (
        <>
            <Offcanvas
                show={show}
                onHide={handleClose}
                placement="end"
                scroll={false}
                backdrop={true}
                onExited={() => {
                    dispatch(setStatusUpdate("init"));
                    setFiles([]);
                }}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Index
                        item={initialState}
                        callback={(values: any) => {
                            const search = { ...searchData, id_location: user.id_location };
                            //update
                            dispatch(
                                updateItemDataAsync({
                                    data: { values, search, files },
                                    access_token,
                                })
                            );
                        }}
                        disabled={statusUpdate === "success"}
                        files={files}
                        setFiles={setFiles}
                    />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
