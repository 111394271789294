//styles
import { FloatingLabel, Form, Row, Col } from "react-bootstrap";
//comp
import { toDateRev } from "../../../helper/formatData";
//redux
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { selectUserData } from "../../../redux/reducer/user/userSlice";
import {
    setSearch,
    searchAgreeDataAsync,
    selectSearchData,
    selectPageData,
    setPageData,
} from "../../../redux/reducer/agree/agreeSliceP";

//-----------------------------------------------------------------------------------------------------
export default function SerchByDate() {
    //redux
    const dispatch = useAppDispatch();
    const { access_token } = useAppSelector(selectUserData);
    const searchData = useAppSelector(selectSearchData);
    const pageData = useAppSelector(selectPageData);

    //onchange
    const onChangeStart = (ev: any) => {
        const { value } = ev.target;
        const search = { ...searchData, start: value };
        if (pageData > 1) dispatch(setPageData(0));
        dispatch(setSearch(search));
        dispatch(
            searchAgreeDataAsync({
                data: {
                    driver: { ps: "S" },
                    search,
                },
                access_token,
            })
        );
    };
    const onChangeStop = (ev: any) => {
        const { value } = ev.target;
        const search = { ...searchData, stop: value };
        // if (pageData > 1) dispatch(setPageData(0));
        dispatch(setSearch(search));
        dispatch(
            searchAgreeDataAsync({
                data: {
                    driver: { ps: "S" },
                    search,
                },
                access_token,
            })
        );
    };

    //view
    return (
        <Form>
            <Row className="g-0">
                <Col sm>
                    <FloatingLabel label="Data start">
                        <Form.Control
                            type="date"
                            value={searchData.start}
                            max={toDateRev()}
                            onChange={onChangeStart}
                            placeholder="Data start"
                        />
                    </FloatingLabel>
                </Col>
                <Col sm>
                    <FloatingLabel label="Data end">
                        <Form.Control
                            type="date"
                            value={searchData.stop}
                            max={toDateRev()}
                            onChange={onChangeStop}
                            placeholder="Data end"
                        />
                    </FloatingLabel>
                </Col>
            </Row>
        </Form>
    );
}
