import { Col, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toDateRev } from "../../../../../../helper/formatData";
// //redux
import { useAppDispatch, useAppSelector } from "../../../../../../hooks";
import { selectAgreeData } from "../../../../../../redux/reducer/agree/agreeSliceCreate";
import FormAgree from "../../FormAgree";
import TableAgree from "../../TableAgree";
import AgreeSubmit from "./AgreeSubmit";
import { updateAgreeDataAsync, selectSearchData } from "../../../../../../redux/reducer/agree/agreeSliceP";
import { selectUserData } from "../../../../../../redux/reducer/user/userSlice";

// //----------------------------------------------------

export default function AgreeForm() {
    //redux
    const dispatch = useAppDispatch();
    const { id, proc, extend, term, notes, item } = useAppSelector(selectAgreeData);
    const { access_token } = useAppSelector(selectUserData);
    const search = useAppSelector(selectSearchData);

    //Formik
    const formik = useFormik({
        initialValues: {
            proc: proc,
            extend: extend,
            term: term,
            notes: notes,
        },
        validationSchema: Yup.object({}),
        onSubmit: () => {
            const driver = {
                ps: "P", //wybrano umowy przedstepne
                id: id, //wybrano zapis nowej umowy
            };
            const data = {
                status: "closed",
            };
            //save agreement
            dispatch(
                updateAgreeDataAsync({
                    data: {
                        driver,
                        data,
                        search,
                    },
                    access_token,
                })
            );
        },
    });

    //view
    return (
        <Form>
            <Row>
                <Col className="d-grid gap-1 mb-1">
                    <FormAgree formik={formik} item={item} disabled={true} />
                    <TableAgree
                        data={item}
                        proc={formik.values.proc}
                        term={{
                            start: extend,
                            stop: toDateRev(),
                        }}
                    />
                    <AgreeSubmit formik={formik} />
                </Col>
            </Row>
        </Form>
    );
}
