import { Offcanvas } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { selectClientData, setStatusPesel, setStatusUpdate } from "../../../../redux/reducer/client/clientSlice";
import Index from "../../../client/form/view/Index";

//-------------------------------------------------------------------------------

export default function ClientViewNav({ show, handleClose }: { show: boolean; handleClose: object }) {
    //redux
    const dispatch = useAppDispatch();
    const { client } = useAppSelector(selectClientData);

    //view
    return (
        <>
            <Offcanvas
                show={show}
                onHide={handleClose}
                placement="end"
                scroll={true}
                backdrop={true}
                onExited={() => {
                    dispatch(setStatusPesel("init"));
                    dispatch(setStatusUpdate("init"));
                }}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Index client={client} />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
