export function fetchFileApi(url: string, data: any, access_token: string) {
    const formData = new FormData();
    //add images
    data.files.forEach((file: any) => {
        formData.append("images[]", file);
    });
    //add values
    formData.append("values", JSON.stringify(data.values));
    //add search
    formData.append("search", JSON.stringify(data.search));

    //send data
    return fetch(url, {
        method: "POST",
        headers: {
            authorization: access_token,
        },
        body: formData,
    }).then((res) => res.json());
    //.catch(err => { console.log(err) });
}
