import { Table } from "react-bootstrap";
import { useAppSelector } from "../../../hooks";
//redux

import { selectData } from "../../../redux/reducer/history/histSlice";

//-----------------------------------------------

export default function HistIndex() {
    //redux
    const data = useAppSelector(selectData);

    //view
    return (
        <Table striped bordered hover size="sm" responsive>
            <thead>
                <tr>
                    <th>#</th>
                    <th style={{ width: "165px" }}>Dnia</th>
                    <th>Opis zdarzenia</th>
                    <th>Notatka</th>
                </tr>
            </thead>
            <tbody>
                {data.map((obj: any, inx) => {
                    return (
                        <tr key={inx}>
                            <td>{inx + 1}</td>
                            <td>{obj.created}</td>
                            <td>{obj.descript}</td>
                            <td>{obj.notes}</td>
                        </tr>
                    );
                })}
            </tbody>
            <tfoot></tfoot>
        </Table>
    );
}
