import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetchApi } from "../../../helper/fetchApi";
import { toDateRev } from "../../../helper/formatData";
import { RootState } from "../../store";

interface intSearch {
    id_location: number;
    status: string;
    start: string;
    stop: string;
}

interface intInitState {
    data: any;
    // dataPwSw: {
    //     pw: Array<any>;
    //     sw: Array<any>;
    // };
    search: intSearch;
    status: string;
    direction: string;
}

//init state
export const initialState: intInitState = {
    data: [],
    // dataPwSw: {
    //     pw: [],
    //     sw: [],
    // },
    search: {
        id_location: 0,
        status: "",
        start: toDateRev(),
        stop: toDateRev(),
    },
    status: "init",
    direction: "init",
};

//fetch async data from a server SAVE/UPDATE
export const searchReportDataAsync = createAsyncThunk(
    "/reportState/searchReportDataAsync",
    async (data: { data: intSearch; access_token: string }, { rejectWithValue }) => {
        try {
            const response = await fetchApi("repo/rows", data.data, data.access_token);
            return response;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

//create slice obj
export const reportSlice = createSlice({
    name: "reportState",
    initialState,
    reducers: {
        resetData: (state) => {
            state.data = initialState.data;
        },
        setSearch: (state, action: PayloadAction<any>) => {
            state.search = action.payload;
        },
        setDirection: (state, action: PayloadAction<any>) => {
            state.direction = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            //ROWS
            .addCase(searchReportDataAsync.pending, (state) => {
                state.status = "load";
            })
            .addCase(searchReportDataAsync.fulfilled, (state, action) => {
                //success connection?
                try {
                    //success login?
                    if (action.payload.active) {
                        state.data = action.payload.data;
                        state.data = action.payload.data.map((x: any) => {
                            return {
                                ...x,
                                client: JSON.parse(x.client),
                                item: JSON.parse(x.item),
                            };
                        });
                        state.status = "success";
                    } else {
                        state.data = initialState.data;
                        state.status = "unsuccess";
                    }
                } catch (err) {
                    state.data = initialState.data;
                    state.status = "failed";
                    console.log("TypeError: JsonError when attempting resolve data.");
                }
            })
            .addCase(searchReportDataAsync.rejected, (state) => {
                state.data = initialState.data;
                state.status = "failed";
                console.log("TypeError: NetworkError when attempting to fetch resource.");
            });
    },
});
//create actions obj
export const { resetData, setSearch, setDirection } = reportSlice.actions;

//get data from store
export const selectData = (state: RootState) => state.reportState.data;
export const selectSearch = (state: RootState) => state.reportState.search;
export const selectStatus = (state: RootState) => state.reportState.status;
export const selectDirection = (state: RootState) => state.reportState.direction;

//create reducer
export default reportSlice.reducer;
