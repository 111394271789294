import styles from "../../../styles/Client.module.css";
// import MoveModal from "../../../modal/agree/moveAgree/moveToDepot/MoveModal";
// import ExtendModal from "../../../modal/agree/extend/ExtendModal";
// import ReturnModal from "../../../modal/agree/return/ReturnModal";
// import HistModal from "../../../modal/hist/HistModal";
import { Table, ButtonGroup, DropdownButton } from "react-bootstrap";
import { toDate, toPLN } from "../../../helper/formatData"; // toStatusUP
//redux
import { selectData } from "../../../redux/reducer/agree/agreeSliceS";
// import { getHistDataAsync } from "../../../../redux/reducer/histSlice";
import { selectUserData } from "../../../redux/reducer/user/userSlice";
import { useAppSelector } from "../../../hooks";
import PageAgreeSetupAction from "./PageAgreeSetupAction";
import getLocationName from "../../../helper/getLocationName";
import { dictionaryAgreeS } from "../../../helper/dictionary";

//---------------------------------------------------------------

export default function PageAgreeTab() {
    //redux
    // const dispatch = useAppDispatch();
    // const filterData = useSelector(selectFilterData);
    const { data } = useAppSelector(selectData);
    const { locations } = useAppSelector(selectUserData);

    /**
     * View submenu
     * otwarte w terminie -> przedluz, rozlicz, edytuj, podglad, historia
     * otwarte zamrozone -> podglad, historia
     * otwarte po terminie -> na magazyn, podglad, historia
     * rozliczone -> podglad, historia
     * przedluzone -> przedluz, rozlicz, edytuj, podglad, historia
     * magazyn -> podglad, historia
     * @param {*} obj
     * @returns
     */

    //view
    return (
        <>
            <Table striped bordered hover size="lg" responsive>
                <thead className={styles.textCenter}>
                    <tr>
                        <th>#</th>
                        <th>ID</th>
                        <th style={{ width: "100px" }}>Źródło</th>
                        <th>Obraz</th>
                        <th>Nazwa Przedmiotu</th>
                        <th>Kwota</th>
                        <th style={{ width: "150px" }}>Kontrahent</th>
                        <th>Notatka</th>
                        <th style={{ width: "200px" }}>Lokalizacja</th>
                        <th style={{ width: "115px" }}>Status</th>
                        <th style={{ width: "115px" }}>Zawarta</th>
                        <th style={{ width: "115px" }}>Akcje</th>
                    </tr>
                </thead>
                <tbody className={styles.textCenter}>
                    {data.data.map((obj: any, inx: number) => {
                        return (
                            <tr key={inx} className="textCenter">
                                <td style={{ padding: "13px 0px" }}>{inx + 1}</td>
                                <td style={{ padding: "13px 0px" }}>{obj.id}</td>
                                <td>{dictionaryAgreeS(obj.source)}</td>
                                <td style={{ padding: "13px 0px" }}>
                                    {obj.item.map((obj2: any, inx: number) => {
                                        return (
                                            <img
                                                key={inx}
                                                alt="brak zdjęć"
                                                src={`${process.env.PUBLIC_URL}/item/image/${obj2.image[0]}`}
                                                width="150px"
                                            />
                                        );
                                    })}
                                </td>
                                <td style={{ padding: "13px 0px" }}>
                                    {obj.item
                                        .reduce((x: string, y: any) => {
                                            return x + ", " + y.name;
                                        }, "")
                                        .substring(1)}
                                </td>
                                <td style={{ padding: "13px 0px" }}>
                                    {toPLN(
                                        obj.item.reduce((x: string, y: any) => {
                                            return x + Number(y.price);
                                        }, 0)
                                    )}
                                </td>
                                <td style={{ padding: "13px 0px" }}>
                                    {obj.client.firstname} {obj.client.surname}
                                </td>
                                <td style={{ padding: "13px 0px" }}>{obj.notes}</td>

                                <td style={{ padding: "13px 0px" }}>{getLocationName(locations, obj.id_location)}</td>
                                <td style={{ padding: "13px 0px" }}>{dictionaryAgreeS(obj.status)}</td>
                                <td style={{ padding: "13px 0px" }}>{toDate(obj.created)}</td>
                                <td>
                                    <DropdownButton
                                        as={ButtonGroup}
                                        key={inx}
                                        id={`dropdown-button-drop-${inx}`}
                                        drop="start"
                                        variant="outline-secondary"
                                        title="Wybierz"
                                    >
                                        {Number(obj.movePoint) === 0 ? (
                                            <PageAgreeSetupAction obj={obj} />
                                        ) : (
                                            <strong> Oczekuje akceptacji przesuniecia do innego punktu</strong>
                                        )}
                                    </DropdownButton>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot></tfoot>
            </Table>
        </>
    );
}
