// import React, { useState } from "react";
import Index from "../../../item/form/view/Index";
import { Offcanvas } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { selectUnitData, setStatusUpdate } from "../../../../redux/reducer/item/itemSlice";

//-------------------------------------------------------------------------------

export default function ItemViewNav({ show, onHide }: { show: boolean; onHide: object }) {
    //redux
    const dispatch = useAppDispatch();
    const item = useAppSelector(selectUnitData);

    //view
    return (
        <>
            <Offcanvas
                show={show}
                onHide={onHide}
                placement="end"
                scroll={false}
                backdrop={true}
                onExited={() => {
                    dispatch(setStatusUpdate("init"));
                }}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Index item={item} />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
