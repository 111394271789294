import { Col, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
// //redux
import { useAppSelector } from "../../../../../../hooks";
import { selectAgreeData } from "../../../../../../redux/reducer/agree/agreeSliceCreate";
import FormAgree from "../../FormAgree";
import TableAgree from "../../TableAgree";

// //----------------------------------------------------

export default function AgreeForm() {
    //redux
    const { notes, item } = useAppSelector(selectAgreeData);

    //Formik
    const formik = useFormik({
        initialValues: {
            notes: notes,
        },
        validationSchema: Yup.object({}),
        onSubmit: () => {},
    });

    //view
    return (
        <Form>
            <Row>
                <Col className="d-grid gap-1 mb-1">
                    <FormAgree formik={formik} item={item} disabled={true} />
                    <TableAgree item={item} />
                </Col>
            </Row>
        </Form>
    );
}
