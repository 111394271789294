//bootstrap
import { Container } from "react-bootstrap";
//nav
import UserFormAdmin from "./admin/UserForm";
import UserFormUser from "./user/UserForm";
import UserFormPwd from "./UserFormPwd";
//redux
import { useAppSelector } from "../../../../hooks";
import { selectUserData } from "../../../../redux/reducer/user/userSlice";

//-------------------------------------------------------

export default function UserIndex() {
    //redux
    const { user } = useAppSelector(selectUserData);

    //view
    return (
        <Container fluid>
            {user.role === "A" ? <UserFormAdmin /> : <UserFormUser />}
            <br />
            <UserFormPwd />
        </Container>
    );
}
