import { useState } from "react";
import styles from "../../../styles/Client.module.css";
import { ButtonGroup, Col, Dropdown, DropdownButton, Row, Table } from "react-bootstrap";
import { toDate, toPLN } from "../../../helper/formatData";
import HistModal from "../../../comp/modal/history/HistModal";
import AgreeModalP from "../../../comp/modal/agree/agreeP/view/AgreeModal";
import AgreeModalS from "../../../comp/modal/agree/agreeS/view/AgreeModal";

// //redux
// import { selectData } from "../../../redux/reducer/report/reportSlice";
import { dictionaryAgreeP } from "../../../helper/dictionary";
import getLocationName from "../../../helper/getLocationName";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { selectUserData } from "../../../redux/reducer/user/userSlice";
import { getHistDataAsync } from "../../../redux/reducer/history/histSlice";
import { selectData, selectSearch } from "../../../redux/reducer/report/reportSlice";
import { setAllData } from "../../../redux/reducer/agree/agreeSliceCreate";

//---------------------------------------------------------------

export default function PageReportTab() {
    const [idAgree, setIdAgree] = useState(0);
    const [modalShowHist, setModalShowHist] = useState(false);
    const [modalShowAgree, setModalShowAgree] = useState(false);

    // redux
    const dispatch = useAppDispatch();
    const data = useAppSelector(selectData);
    const search = useAppSelector(selectSearch);
    const { locations, access_token } = useAppSelector(selectUserData);

    //view
    return (
        <>
            <Row>
                <Col>
                    <strong style={{ color: "white" }}>Wyniki</strong>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table striped bordered hover size="lg" responsive>
                        <thead className={styles.textCenter}>
                            <tr>
                                <th style={{ width: "80px" }}>#</th>
                                <th style={{ width: "50px" }}>ID</th>
                                <th>Przedmiot</th>
                                <th style={{ width: "250px" }}>Klient</th>
                                <th style={{ width: "120px" }}>Kwota</th>
                                <th style={{ width: "115px" }}>Zawarta</th>
                                <th style={{ width: "115px" }}>Termin</th>
                                <th style={{ width: "250px" }}>Lokalizacja</th>
                                <th style={{ width: "150px" }}>Status</th>
                                <th style={{ width: "115px" }}>Akcje</th>
                            </tr>
                        </thead>
                        <tbody className={styles.textCenter}>
                            {data.map((obj: any, inx: any) => {
                                return (
                                    <tr key={inx} className="textCenter">
                                        <td style={{ padding: "13px 0px" }}>{inx + 1}</td>
                                        <td style={{ padding: "13px 0px" }}>{obj.id}</td>
                                        <td style={{ padding: "13px 0px" }}>
                                            {obj.item
                                                .reduce((x: any, y: any) => {
                                                    return x + ", " + y.name;
                                                }, "")
                                                .substring(1)}
                                        </td>
                                        <td style={{ padding: "13px 0px" }}>
                                            {obj.client.firstname} {obj.client.surname}
                                        </td>
                                        <td style={{ paddingTop: "13px", textAlign: "right" }}>{toPLN(obj.price)}</td>
                                        <td style={{ padding: "13px 0px" }}>{toDate(obj.created)}</td>
                                        <td style={{ padding: "13px 0px" }}>{toDate(obj.term)}</td>
                                        <td style={{ padding: "13px 0px" }}>
                                            {getLocationName(locations, obj.id_location)}
                                        </td>
                                        <td style={{ padding: "13px 0px" }}>{dictionaryAgreeP(obj.status)}</td>

                                        <td>
                                            <DropdownButton
                                                as={ButtonGroup}
                                                key={inx}
                                                id={`dropdown-button-drop-${inx}`}
                                                drop="start"
                                                variant="outline-secondary"
                                                title="Wybierz"
                                            >
                                                <Dropdown.Item
                                                    eventKey="4"
                                                    onClick={() => {
                                                        dispatch(setAllData(obj));
                                                        setModalShowAgree(true);
                                                        setIdAgree(obj.id);
                                                    }}
                                                >
                                                    Podgląd
                                                </Dropdown.Item>

                                                <Dropdown.Item
                                                    eventKey="7"
                                                    onClick={() => {
                                                        dispatch(
                                                            getHistDataAsync({
                                                                data: {
                                                                    what: search.status.includes("ws")
                                                                        ? "AgreeP"
                                                                        : "AgreeS",
                                                                    id_what: obj.id,
                                                                },
                                                                access_token,
                                                            })
                                                        );
                                                        setModalShowHist(true);
                                                        setIdAgree(obj.id);
                                                    }}
                                                >
                                                    Historia
                                                </Dropdown.Item>
                                            </DropdownButton>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                        <tfoot className={styles.textRight}>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Suma: </td>
                                <td>
                                    {toPLN(
                                        data.reduce((x: any, y: any) => {
                                            return x + Number(y.price);
                                        }, 0)
                                    )}
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </Table>
                </Col>
                {search.status.includes("ws") ? (
                    <AgreeModalP show={modalShowAgree} onHide={() => setModalShowAgree(false)} />
                ) : (
                    <AgreeModalS show={modalShowAgree} onHide={() => setModalShowAgree(false)} />
                )}
                <HistModal
                    show={modalShowHist}
                    onHide={() => setModalShowHist(false)}
                    descript={`Historia umowy nr: ${idAgree}`}
                />
            </Row>
        </>
    );
}
