import { Dropdown } from "react-bootstrap";
import { useAppSelector } from "../../../hooks";
import { selectUserData } from "../../../redux/reducer/user/userSlice";
import PageAgreeAction from "./PageItemAction";

//-------------------------------------------------------------------------------------
export default function PageItemSetupAction({ obj }: { obj: any }) {
    //redux
    const { user } = useAppSelector(selectUserData);
    //funView
    const chooseCollectionAction = () => {
        switch (obj.status) {
            case "pending": {
                return (
                    <>
                        <PageAgreeAction driver="addToAgreement" obj={obj} />
                        <Dropdown.Divider />
                        <PageAgreeAction driver="view" obj={obj} />
                        {user.role === "A" && <PageAgreeAction driver="edit" obj={obj} />}
                        <Dropdown.Divider />
                        <PageAgreeAction driver="history" obj={obj} />
                    </>
                );
            }
            case "frozen": {
                return (
                    <>
                        <PageAgreeAction driver="view" obj={obj} />
                        <Dropdown.Divider />
                        <PageAgreeAction driver="history" obj={obj} />
                    </>
                );
            }
            case "depot": {
                return (
                    <>
                        <PageAgreeAction driver="view" obj={obj} />
                        <Dropdown.Divider />
                        <PageAgreeAction driver="history" obj={obj} />
                    </>
                );
            }
            case "sale": {
                return (
                    <>
                        <PageAgreeAction driver="view" obj={obj} />
                        <Dropdown.Divider />
                        <PageAgreeAction driver="history" obj={obj} />
                    </>
                );
            }
        }
    };

    //view
    return <>{chooseCollectionAction()}</>;
}
