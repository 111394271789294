import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { fetchApi } from "../../../helper/fetchApi";
import { fetchFileApi } from "../../../helper/fetchFileApi";
import { toDateRev } from "../../../helper/formatData";

//ts types interface
export interface intInitState {
    // dane, ktore mamy z servera po request
    data: {
        amount: number;
        data: Array<any>;
    };
    search: intSearch; // dane do refresh i dane do zapisu baz zmiany
    unit: intUnit; // dane to wewnetrzego update przedmiotu
    status: {
        statusItem: string; // status pobierania danych klientow
        statusUpdate: string; // status poprawy danych klienta
    };
    page: number; // do pafinacji
}
export interface intSearch {
    id_location: number;
    status: string;
    start: string;
    stop: string;
    text: string;
    interval: number;
}
export interface intUnit {
    id: number;
    id_location: number;
    name: string;
    quality: string;
    amount: number;
    image: Array<any>;
    notes: string;
    status: string;
    created?: string;
}

//init state
export const initialState: intInitState = {
    data: {
        amount: 0,
        data: [],
    },
    search: {
        id_location: 0,
        status: "all",
        start: "2022-06-01",
        stop: toDateRev(),
        text: "",
        interval: 0,
    },
    unit: {
        id: 0,
        id_location: 0,
        name: "",
        quality: "",
        amount: 1,
        image: [],
        notes: "",
        status: "",
        created: "",
    },
    status: {
        statusItem: "init",
        statusUpdate: "init",
    },
    page: 0,
};

//READ Item
//--------------------------------------------------------------------------------------------------
export const getItemDataAsync = createAsyncThunk(
    "/itemState/getItemDataAsync",
    async (data: { data: intSearch; access_token: string }, { rejectWithValue }) => {
        try {
            const response = await fetchApi("/item/rows", data.data, data.access_token);
            return response;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

//UPDATE/INSERT Item
//--------------------------------------------------------------------------------------------------
export const updateItemDataAsync = createAsyncThunk(
    "/itemState/updateItemDataAsync",
    async (
        data: { data: { values: intUnit; search: intSearch; files: Array<File> }; access_token: string },
        { rejectWithValue }
    ) => {
        try {
            const response = await fetchFileApi("/item/update", data.data, data.access_token);
            return response;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

//create slice obj
export const itemSlice = createSlice({
    name: "itemState",
    initialState,
    reducers: {
        setInitItemData: (state) => {
            state.data = initialState.data;
            state.search = initialState.search;
            state.unit = initialState.unit;
            state.status = initialState.status;
            state.page = initialState.page;
        },
        setUnitItemData: (state, action: PayloadAction<any>) => {
            state.unit = action.payload;
        },
        setStatusUpdate: (state, action: PayloadAction<any>) => {
            state.status.statusUpdate = action.payload;
        },
        setSearchData: (state, action: PayloadAction<any>) => {
            state.search = action.payload;
        },
        setPageData: (state, action: PayloadAction<any>) => {
            state.page = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder

            //GET ROWS
            //--------------------------------------------------------------------------------------------------
            .addCase(getItemDataAsync.pending, (state) => {
                state.status.statusItem = "load";
            })
            .addCase(getItemDataAsync.fulfilled, (state, action) => {
                try {
                    if (action.payload.active) {
                        state.data = {
                            amount: action.payload.data.amount,
                            data: action.payload.data.data.map((x: any) => {
                                return { ...x, image: JSON.parse(x.image) };
                            }),
                        };
                        state.status.statusItem = "success";
                    } else {
                        state.data = initialState.data;
                        state.status.statusItem = "unsuccess";
                    }
                } catch (err) {
                    state.status.statusItem = "failed";
                    console.log("TypeError: JsonError when attempting resolve data.");
                }
            })
            .addCase(getItemDataAsync.rejected, (state) => {
                state.status.statusItem = "failed";
                console.log("TypeError: NetworkError when attempting to fetch resource.");
            })

            //UPDATE Item
            //--------------------------------------------------------------------------------------------------
            .addCase(updateItemDataAsync.pending, (state) => {
                state.status.statusUpdate = "load";
            })
            .addCase(updateItemDataAsync.fulfilled, (state, action) => {
                //success connection?
                try {
                    //success login?
                    if (action.payload.active) {
                        state.data = {
                            amount: action.payload.data.amount,
                            data: action.payload.data.data.map((x: any) => {
                                return { ...x, image: JSON.parse(x.image) };
                            }),
                        };
                        state.status.statusUpdate = "success";
                        state.status.statusItem = "success";
                    } else {
                        state.status.statusUpdate = "unsuccess";
                    }
                } catch (err) {
                    state.status.statusUpdate = "failed";
                    console.log("TypeError: JsonError when attempting resolve data.");
                }
            })
            .addCase(updateItemDataAsync.rejected, (state) => {
                state.status.statusUpdate = "failed";
                console.log("TypeError: NetworkError when attempting to fetch resource.");
            });
    },
});

//create actions obj
export const { setInitItemData, setUnitItemData, setStatusUpdate, setSearchData, setPageData } = itemSlice.actions;

//get data from store
export const selectItemData = (state: RootState) => state.itemState.data;
export const selectUnitData = (state: RootState) => state.itemState.unit;
export const selectSearchData = (state: RootState) => state.itemState.search;
export const selectStatusData = (state: RootState) => state.itemState.status;
// export const selectStatusUpdate = (state: RootState) => state.itemState.status.statusUpdate;
export const selectPageData = (state: RootState) => state.itemState.page;

//create reducer
export default itemSlice.reducer;
