import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import boardReducer from "./reducer/board/boardSlice";
import userReducer from "./reducer/user/userSlice";
import clientReducer from "./reducer/client/clientSlice";
import itemReducer from "./reducer/item/itemSlice";
import agreeReducerCreate from "./reducer/agree/agreeSliceCreate";
import agreeReducerP from "./reducer/agree/agreeSliceP";
import agreeReducerS from "./reducer/agree/agreeSliceS";
import settingReducer from "./reducer/setting/settingSlice";
import extendReducer from "./reducer/extend/extendSlice";
import histReducer from "./reducer/history/histSlice";
import reportReducer from "./reducer/report/reportSlice";
import moveDepotReducer from "./reducer/moveDepot/moveDepotSlice";
import movePointReducer from "./reducer/movePoint/movePointSlice";
import getPointReducer from "./reducer/movePoint/getPointSlice";
import setPointReducer from "./reducer/movePoint/setPointSlice";

export const store = configureStore({
    reducer: {
        boardState: boardReducer,
        userState: userReducer,
        clientState: clientReducer,
        itemState: itemReducer,
        agreeStateCreate: agreeReducerCreate,
        agreeStateP: agreeReducerP,
        agreeStateS: agreeReducerS,
        settingState: settingReducer,
        extendState: extendReducer,
        histState: histReducer,
        reportState: reportReducer,
        moveDepotState: moveDepotReducer,
        movePointState: movePointReducer,
        getPointState: getPointReducer,
        setPointState: setPointReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
