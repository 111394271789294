import { useState } from "react";
import { Button } from "react-bootstrap";
import GetPointModal from "../../modal/agree/movePoint/getPoint/GetPointModal";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getDataAsync } from "../../../redux/reducer/movePoint/getPointSlice";
import { selectUserData } from "../../../redux/reducer/user/userSlice";

//------------------------------------------

export default function ImportAgree() {
    //hook
    const [showModal, setShowModal] = useState(false);
    //redux
    const dispatch = useAppDispatch();
    const { user, access_token } = useAppSelector(selectUserData);

    //view
    return (
        <>
            <Button
                size="sm"
                variant="warning"
                onClick={() => {
                    setShowModal(true);
                    dispatch(getDataAsync({ id: { id: user.id_location }, access_token }));
                }}
            >
                Import
            </Button>

            <GetPointModal show={showModal} onHide={() => setShowModal(false)} />
        </>
    );
}
