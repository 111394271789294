import { Nav, Spinner } from "react-bootstrap";
//comp
import Clocker from "./Clocker";

//redux
import { useAppDispatch, useAppSelector } from "../../hooks";
import { updateTokenDataAsync, selectUserData, selectStatusToken } from "../../redux/reducer/user/userSlice";

//------------------------------------------------------------------------
export default function ClockNav() {
    //redux
    const dispatch = useAppDispatch();
    const { user, access_token } = useAppSelector(selectUserData);
    const statusToken = useAppSelector(selectStatusToken);

    const updateToken = () => {
        dispatch(updateTokenDataAsync({ data: { id: user.id, email: user.email }, access_token }));
    };

    //set view clock
    const setView = () => {
        switch (statusToken) {
            case "load": {
                return (
                    <Nav.Item>
                        <Nav.Link eventKey="link-11">
                            <Spinner animation="border" variant="info" size="sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </Nav.Link>
                    </Nav.Item>
                );
            }
            case "success": {
                return <Clocker updateToken={updateToken} />;
            }
            case "unsuccess": {
                return <Nav.Link disabled>Token expired</Nav.Link>;
            }
            case "failed": {
                return (
                    <Nav.Link
                        onClick={() => {
                            updateToken();
                        }}
                    >
                        Connection failed
                    </Nav.Link>
                );
            }
            default: {
                return <></>;
            }
        }
    };

    //view
    return setView();
}
