//to day/month/year
export const toDate = (date?: string | undefined): string => {
    const newDate = date !== undefined ? new Date(date) : new Date();
    return newDate.toLocaleDateString();
};

// reverse present date
export const toDateRev = (date?: string | undefined) => {
    const newDate = date !== undefined ? new Date(date) : new Date();
    const nds = newDate.toLocaleDateString();
    return nds.substring(6, 10) + "-" + nds.substring(3, 5) + "-" + nds.substring(0, 2);
};

//first day of themonth
export const toDateFirstDayOfMonth = (date?: string | undefined) => {
    const newDate = date !== undefined ? new Date(date) : new Date();
    const newSetDate = new Date(newDate.getFullYear(), newDate.getMonth());
    return toDateRev(newSetDate.toString());
};

//add extra days
export const addDays = (days = 0) => {
    const x1 = new Date();
    const x2 = new Date(x1.setDate(x1.getDate() + days));
    const x3 = x2.toLocaleDateString();
    const x4 = x3.substr(6, 4) + "-" + x3.substr(3, 2) + "-" + x3.substr(0, 2);
    return x4;
};

//to pln
export const toPLN = (num: any) => {
    return num !== null ? Number(num).toFixed(2) : "";
};

//DO WYLICZANIA PROCENTU I KWOTY PRZY PRZEDLUZANIU I ZAMYKANIU UMOWY PRZEDWSTEPNEJ
//--------------------------------------------------------------------------------

//procent czy stala kwota: dzis -> termin
export const countProc = (range: any) => {
    const x1 = new Date(range.start);
    const x2 = new Date(range.stop);
    const x3 = new Date(x1.setDate(x1.getDate() + 2));
    const x4 = x2 > x3; //czy procent?
    return x4;
};

//procent na czas trwania umowy (naliczanie dniowe)
export const procUnit = (price: number, proc: number, range: any) => {
    const y1 = Number(Number.parseFloat(((price * proc) / 100).toString()).toFixed(2)); //kwota procentu
    const y2 = new Date(range.start); //data na dzis
    const y3 = new Date(y2.getFullYear(), y2.getMonth() + 1, 0).getDate(); //ile dni ma miesiac
    // const y4 = Number(Number.parseFloat((y1 / y3).toString()).toFixed(2)); //kwota procentu na dzien
    const y4 = Number(Number.parseFloat((y1 / y3).toString()));
    const y5 = Number(new Date(range.stop)); //czas w przyszlosci
    const y6 = Math.ceil((y5 - Number(y2)) / (60 * 60 * 24 * 1000)); //ile dni bedzie lezakowac
    const y7 = y4 * y6;
    return y7;
};

//suma procentow kwot lub suma procentow i cen razem do zapisu dla extend i return
export const procAmountValue = (countProc: any, procUnit: any, toDateRev: any, data: any, procAll: any) => {
    if (procAll) {
        if (countProc({ start: data.extend, stop: toDateRev() })) {
            const dataItem = JSON.parse(data.item);
            const result = dataItem.reduce((x: any, y: any) => {
                return (
                    x +
                    procUnit(Number(y.price), data.proc, {
                        start: data.extend,
                        stop: toDateRev(),
                    })
                );
            }, 0);
            return Number.parseFloat(result).toFixed(2);
        } else {
            return 5;
        }
    } else {
        if (countProc({ start: data.extend, stop: toDateRev() })) {
            const dataItem = JSON.parse(data.item);
            const result = dataItem.reduce((x: any, y: any) => {
                return (
                    x +
                    Number(y.price) +
                    procUnit(Number(y.price), data.proc, {
                        start: data.extend,
                        stop: toDateRev(),
                    })
                );
            }, 0);
            return Number.parseFloat(result).toFixed(2);
        } else {
            const dataItem = JSON.parse(data.item);
            const result = dataItem.reduce((x: any, y: any) => {
                return x + Number(y.price);
            }, 0);
            return Number.parseFloat(result + 5).toFixed(2);
        }
    }
};

//DO USTAWIAMIA STATUSU WIDOCZNEGO W APLIKACJI
//--------------------------------------------------------------------------
// status dla umow przedwstepnych
//ENUM('depot', 'open', 'closed', 'extend')
// export const toStatusUP = (term, created, toDateRev, status) => {
//     const dateNow = new Date(toDateRev());
//     const dateTerm = new Date(term);
//     const dateCreat = new Date(created);
//     return status === "closed"
//         ? "Rozliczona"
//         : status === "extend"
//         ? "Przedłużona"
//         : dateNow <= dateTerm
//         ? "W terminie"
//         : dateNow <= new Date(dateCreat.setDate(dateCreat.getDate() + 7))
//         ? "Zamrożona"
//         : "Po terminie";
// };
