//css
import styles from "../../../styles/Login.module.css";
//bootstrap
import { ListGroup, Row, Col, Form, FloatingLabel } from "react-bootstrap";
//formik
import { useFormik } from "formik";
import * as Yup from "yup";
import { BsArrow90DegUp } from "react-icons/bs";
//redux
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { loginDataAsync, selectUserData, selectStatusLogin } from "../../../redux/reducer/user/userSlice";
//comp
import LoginSubmit from "./LoginSubmit";

//--------------------------------------------------------------

export default function LoginForm() {
    //redux
    const dispatch = useAppDispatch();
    const { active, access_token } = useAppSelector(selectUserData);
    const status = useAppSelector(selectStatusLogin);

    //formik
    const formik = useFormik({
        initialValues: {
            email: "",
            pwd: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Email").required("Required").min(6, "Min 6 chars"),
            pwd: Yup.string().required("Required").min(8, "Min 8 chars"),
        }),
        onSubmit: (values) => {
            dispatch(loginDataAsync({ data: values, access_token }));
        },
    });

    //view
    return (
        <Form>
            <Row>
                <Col className="d-grid gap-1">
                    <ListGroup className={styles.textRight}>
                        <ListGroup.Item variant="primary">{active ? "YOU HAVE BEEN LOGEED" : "LOGIN"}</ListGroup.Item>
                    </ListGroup>
                    {!active && (
                        <>
                            <FloatingLabel label="E-mail address">
                                <Form.Control
                                    id="email"
                                    type="email"
                                    disabled={status !== "init"}
                                    placeholder="E-mail address"
                                    {...formik.getFieldProps("email")}
                                />
                            </FloatingLabel>
                            {formik.touched.email && formik.errors.email && (
                                <h6 className="validError">
                                    <BsArrow90DegUp />
                                    {formik.errors.email}
                                </h6>
                            )}

                            <FloatingLabel label="Password">
                                <Form.Control
                                    id="pwd"
                                    type="password"
                                    disabled={status !== "init"}
                                    placeholder="Password"
                                    {...formik.getFieldProps("pwd")}
                                />
                            </FloatingLabel>
                            {formik.touched.pwd && formik.errors.pwd && (
                                <h6 className="validError">
                                    <BsArrow90DegUp />
                                    {formik.errors.pwd}
                                </h6>
                            )}
                        </>
                    )}
                    <LoginSubmit formik={formik} />
                </Col>
            </Row>
        </Form>
    );
}
