import imageCompression from "browser-image-compression";

export function convertImg(imageCompression, file, feedback) {
    new Promise((resolve) => {
        imageCompression(file, { maxSizeMB: 0.2, maxWidthOrHeight: 512, useWebWorker: true }).then((b) => resolve(b));
    }).then((b) => {
        feedback(b);
    });
}

export function retrieveImg(globFiles, feedback) {
    new Promise((resolve) => {
        const files = globFiles.map((file) => {
            if (Math.round(file.size * 0.001) > 200) {
                convertImg(imageCompression, file, (f) => {
                    return f;
                });
            } else {
                return file;
            }
        });
        resolve(files);
    }).then((files) => {
        feedback(files);
    });
}
