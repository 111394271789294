import { Col, Row } from "react-bootstrap";
import AgreeForm from "../../../../agree/agreeP/form/add/count/AgreeForm";
import FormClientIndex from "../../../../client/form/view/Index";
import FormItemIndex from "../../../../item/form/view/Index";
import { selectAgreeData } from "../../../../../redux/reducer/agree/agreeSliceCreate";
import { useAppSelector } from "../../../../../hooks";
import { Fragment } from "react";

//-------------------------------------------------------------------

export default function AgreeIndex() {
    //redux
    const { client, item } = useAppSelector(selectAgreeData);

    //view
    return (
        <Row>
            <Col xs={3}>
                <FormClientIndex client={client} />
            </Col>
            <Col xs={4}>
                {item.map((obj, inx) => (
                    <Fragment key={inx}>
                        <FormItemIndex item={obj} />
                    </Fragment>
                ))}
            </Col>
            <Col xs={5}>
                <AgreeForm />
            </Col>
        </Row>
    );
}
