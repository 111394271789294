import { useEffect } from "react";
//helper
import { viewSpinner } from "../../helper/viewSpinner";
//comp
import PageItemNav from "./PageItemNav";
import PageItemTab from "./tab/PageItemTab";
//redux
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
    getItemDataAsync,
    setInitItemData,
    selectSearchData,
    selectStatusData,
} from "../../redux/reducer/item/itemSlice";
import { selectUserData } from "../../redux/reducer/user/userSlice";

//----------------------------------------------------------------

export default function PageItemIndex() {
    //redux
    const dispatch = useAppDispatch();
    const { user, access_token } = useAppSelector(selectUserData);
    const searchData = useAppSelector(selectSearchData);
    const { statusItem } = useAppSelector(selectStatusData);

    //hook
    useEffect(() => {
        const data = { ...searchData, id_location: user.id_location };
        dispatch(getItemDataAsync({ data, access_token }));
        return () => {
            dispatch(setInitItemData());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //view
    return (
        <>
            <PageItemNav />
            {viewSpinner(statusItem, <PageItemTab />)}
        </>
    );
}
