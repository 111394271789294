//style
import styles from "../../../../../../styles/Item.module.css";
import { Col, Row, ListGroup, Button } from "react-bootstrap";
//comp
import ItemViewFileList from "./ItemViewFileList";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";
//redux
import FormItem from "../../../../../item/form/FormItem";
import { useAppDispatch } from "../../../../../../hooks";
import { delItemData } from "../../../../../../redux/reducer/agree/agreeSliceCreate";

//-------------------------------------------------------------------
export default function IndexView({ item, files }: { item: any; files: Array<any> }) {
    //redux
    const dispatch = useAppDispatch();

    //Formikx
    const formik = useFormik({
        initialValues: {
            id: item.id,
            id_location: item.id_location,
            status: item.status,
            name: item.name,
            quality: item.quality,
            amount: item.amount,
            notes: item.notes,
            image: item.image,

            // id: 1, //item.id,
            // id_location: 2, //item.id_location,
            // status: "", //item.status,
            // name: "testy", //item.name,
            // quality: 1, //item.quality,
            // amount: 200, //item.amount,
            // notes: "TESTY", //item.notes,
            // image: [], //item.image,
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Wymagane").min(3, "Mix 3 znaki").max(50, "Max 50 znaków"),
            quality: Yup.string().required("Wymagane"),
            amount: Yup.number()
                .typeError("Dopuszczalne tylko cyfry")
                .required("Wymagane")
                .min(1, "Min wartość 1")
                .max(100, "Max wartość 100"),
            notes: Yup.string().nullable(),
        }),
        onSubmit: () => {},
    });

    //view
    return (
        <Row>
            <Col className="d-grid gap-1">
                <ListGroup className={styles.textRight}>
                    <ListGroup.Item variant="primary">DANE PRZEDMIOTU</ListGroup.Item>
                </ListGroup>
                <FormItem formik={formik} disabled={true} />
                <ItemViewFileList image={item.image} />

                <Button
                    variant="danger"
                    onClick={() => {
                        dispatch(delItemData(item.id));
                    }}
                >
                    Delete
                </Button>
            </Col>
        </Row>
    );
}
