//styles
import { FloatingLabel, Form, Row, Col } from "react-bootstrap";
//comp
import { toDateRev } from "../../helper/formatData";
//redux
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectSearch, setSearch, searchReportDataAsync } from "../../redux/reducer/report/reportSlice";
import { selectUserData } from "../../redux/reducer/user/userSlice";

//-----------------------------------------------------------------------------------------------------
export default function SerchByDate() {
    //redux
    const dispatch = useAppDispatch();
    const { access_token, user } = useAppSelector(selectUserData);
    const searchData = useAppSelector(selectSearch);

    //onchange
    const onChangeStart = (ev: any) => {
        const { value } = ev.target;
        const search = { ...searchData, start: value, id_location: user.id_location };
        dispatch(setSearch(search));
        dispatch(
            searchReportDataAsync({
                data: search,
                access_token,
            })
        );
    };
    const onChangeStop = (ev: any) => {
        const { value } = ev.target;
        const search = { ...searchData, stop: value, id_location: user.id_location };
        dispatch(setSearch(search));
        dispatch(
            searchReportDataAsync({
                data: search,
                access_token,
            })
        );
    };

    //view
    return (
        <Form>
            <Row className="g-0">
                <Col sm>
                    <FloatingLabel label="Data start">
                        <Form.Control
                            type="date"
                            value={searchData.start}
                            max={toDateRev()}
                            onChange={onChangeStart}
                            placeholder="Data start"
                        />
                    </FloatingLabel>
                </Col>
                <Col sm>
                    <FloatingLabel label="Data end">
                        <Form.Control
                            type="date"
                            value={searchData.stop}
                            max={toDateRev()}
                            onChange={onChangeStop}
                            placeholder="Data end"
                        />
                    </FloatingLabel>
                </Col>
            </Row>
        </Form>
    );
}
