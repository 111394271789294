import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setItemData } from "../../../redux/reducer/agree/agreeSliceCreate";
// import { setMoveData } from "../../../../redux/reducer/moveDepot/moveAgreeDepotSlice";
// import { setExtendData } from "../../../../redux/reducer/extend/extendSlice";
// import { setReturnData } from "../../../../redux/reducer/return/returnSlice";
// import { selectPointData } from "../../../../redux/reducer/point/pointSlice";

import { setUnitItemData } from "../../../redux/reducer/item/itemSlice";
import ItemViewNav from "../../../comp/navig/item/view/ItemViewNav";
import ItemEditNav from "../../../comp/navig/item/edit/ItemEditNav";
import AgreeModalHist from "../../../comp/modal/history/HistModal";
import { getHistDataAsync } from "../../../redux/reducer/history/histSlice";
import { selectUserData } from "../../../redux/reducer/user/userSlice";

export default function PageItemAction({ driver, obj }: { driver: string; obj: any }) {
    //redux
    const dispatch = useAppDispatch();
    const { access_token } = useAppSelector(selectUserData);

    //hooks
    const [modalEdit, setModalEdit] = useState(false);
    const [modalView, setModalView] = useState(false);
    const [modalHist, setModalHist] = useState(false);

    //funView
    const chooseAction = () => {
        switch (driver) {
            case "addToAgreement": {
                return (
                    <Dropdown.Item
                        eventKey="1"
                        onClick={() => {
                            dispatch(setItemData({ obj, status: true }));
                        }}
                    >
                        Dodaj do umowy
                    </Dropdown.Item>
                );
            }
            case "view": {
                return (
                    <Dropdown.Item
                        eventKey="2"
                        onClick={() => {
                            dispatch(setUnitItemData(obj));
                            setModalView(true);
                        }}
                    >
                        Podgląd
                    </Dropdown.Item>
                );
            }
            case "edit": {
                return (
                    <Dropdown.Item
                        eventKey="3"
                        onClick={() => {
                            dispatch(setUnitItemData(obj));
                            setModalEdit(true);
                        }}
                    >
                        Edytuj
                    </Dropdown.Item>
                );
            }

            // case "extend": {
            //     return (
            //         <Dropdown.Item
            //             onClick={() => {
            //                 // dispatch(setExtendData(obj));
            //                 // setModalShowExtend(true);
            //             }}
            //         >
            //             Przedłuż
            //         </Dropdown.Item>
            //     );
            // }
            // case "close": {
            //     return (
            //         <Dropdown.Item
            //             onClick={() => {
            //                 // dispatch(setReturnData(obj));
            //                 // setModalShowReturn(true);
            //             }}
            //         >
            //             Rozlicz
            //         </Dropdown.Item>
            //     );
            // }
            case "history": {
                return (
                    <Dropdown.Item
                        eventKey="4"
                        onClick={() => {
                            dispatch(
                                getHistDataAsync({
                                    data: {
                                        what: "item",
                                        id_what: obj.id,
                                    },
                                    access_token,
                                })
                            );
                            setModalHist(true);
                        }}
                    >
                        Historia
                    </Dropdown.Item>
                );
            }
            // }
            // case "depot": {
            //     return (
            //         <Dropdown.Item
            //             onClick={() => {
            //                 // dispatch(setMoveData(obj));
            //                 // setModalShowMove(true);
            //             }}
            //         >
            //             Na magazyn
            //         </Dropdown.Item>
            //     );
            // }
            // case "hide": {
            //     return <Dropdown.Item>Usuń</Dropdown.Item>;
            // }
        }
    };

    //view
    return (
        <>
            {chooseAction()}
            {/* show modal edit */}
            <ItemViewNav show={modalView} onHide={() => setModalView(false)} />
            <ItemEditNav show={modalEdit} onHide={() => setModalEdit(false)} />
            <AgreeModalHist
                show={modalHist}
                onHide={() => setModalHist(false)}
                descript={`Historia przedmiotu: ${obj.name}`}
            />
        </>
    );
}
