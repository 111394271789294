import { Col, Row, Button, Spinner } from "react-bootstrap";
//redux
import {
    selectStatus,
    moveDataAsync,
    selectMoveData,
    setStatus,
} from "../../../../../redux/reducer/moveDepot/moveDepotSlice";
import { toDate, toPLN } from "../../../../../helper/formatData";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { selectUserData } from "../../../../../redux/reducer/user/userSlice";
import { selectSearchData } from "../../../../../redux/reducer/agree/agreeSliceP";

//-------------------------------------------------------------------

export default function MoveIndex() {
    //redux
    const dispatch = useAppDispatch();
    const status = useAppSelector(selectStatus);
    const obj = useAppSelector(selectMoveData);
    // const search = useAppSelector(selectSearchData);
    const { user, access_token } = useAppSelector(selectUserData);
    const searchData = useAppSelector(selectSearchData);

    //JSON
    // const client = JSON.parse(obj.client);
    // const item = JSON.parse(obj.item);

    //switch buttons
    const switchButtons = () => {
        switch (status) {
            case "init": {
                return (
                    <Button
                        variant="info"
                        size="lg"
                        onClick={() => {
                            dispatch(
                                moveDataAsync({
                                    link: "/agree/moveAgreeUpDown",
                                    data: {
                                        ps: "S", //switch
                                        id: obj.id,
                                        id_location: user.id_location,
                                        price: obj.item.reduce((x: any, y: any) => {
                                            return x + Number(y.price);
                                        }, 0),
                                        search: {
                                            ...searchData,
                                            id_location: user.id_location,
                                        },
                                    },
                                    access_token: access_token,
                                })
                            );
                            // dispatch(setFilter(""));
                        }}
                    >
                        Przenieś umowę
                    </Button>
                );
            }
            case "load": {
                return (
                    <Button variant="primary" disabled>
                        <Spinner as="span" animation="border" role="status" aria-hidden="true" />
                        <span className="visually-hidden">Loading...</span>
                    </Button>
                );
            }
            case "success": {
                return (
                    <Button variant="success" size="lg" disabled>
                        Umowa została przeniesiona
                    </Button>
                );
            }
            case "unsuccess": {
                return (
                    <Button
                        variant="warning"
                        size="lg"
                        onClick={() => {
                            dispatch(setStatus("init"));
                        }}
                    >
                        Zapis nieudany
                    </Button>
                );
            }
            case "failed": {
                return (
                    <Button
                        variant="danger"
                        size="lg"
                        onClick={() => {
                            dispatch(setStatus("init"));
                        }}
                    >
                        Połączenie nieudane
                    </Button>
                );
            }
            default: {
            }
        }
    };

    //view
    return (
        <Row>
            <Col className="d-grid gap-3 pt-2">
                Umowa Nr: {obj.id}, zawarta dnia: {toDate(obj.created)}, pomiędzy: {obj.client.firstname}{" "}
                {obj.client.surname} a Lombard, na przedmiot/y:{" "}
                {obj.item
                    .reduce((x: string, y: any) => {
                        return x + ", " + y.name;
                    }, "")
                    .substring(1)}
                , na kwotę:{" "}
                {toPLN(
                    obj.item.reduce((x: number, y: any) => {
                        return x + Number(y.price);
                    }, 0)
                )}
                zł
                {switchButtons()}
            </Col>
        </Row>
    );
}
