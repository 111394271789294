import { Table, Row, Col } from "react-bootstrap";
//css
import styles from "../../../../../../styles/Item.module.css";

//--------------------------------------------------------------------------
export default function ItemViewFileList({ image }) {
    //view
    return (
        <Table bordered size="sm">
            <tbody>
                {image.map((path, inx) => {
                    return (
                        <tr key={inx}>
                            <td>
                                <img width="350px" alt="foto" src={`${process.env.PUBLIC_URL}/item/image/${path}`} />
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
}
