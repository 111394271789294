import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { fetchApi } from "../../../helper/fetchApi";
import { loginDataAsync } from "../user/userSlice";

//ts types interface
export interface intInitState {
    setting: {
        percent: number;
    };
    status: {
        statusUpdate: string;
    };
}
//init state
const initialState = {
    setting: {
        percent: 15, //default
    },
    status: {
        statusUpdate: "init",
    },
};

//GET, UPDADTE SETTINGS
export const saveSetDataAsync = createAsyncThunk(
    "/settingState/saveSetDataAsync",
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await fetchApi(`/setting/update`, data.data, data.access_token);
            return response;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const settingSlice = createSlice({
    name: "settingState",
    initialState,
    reducers: {
        setStatusPercent: (state, action: PayloadAction<any>) => {
            state.status.statusUpdate = action.payload;
        },
        setPercent: (state, action: PayloadAction<any>) => {
            state.setting.percent = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            //SETTINGS - save, update
            .addCase(saveSetDataAsync.pending, (state) => {
                state.status.statusUpdate = "load";
            })
            .addCase(saveSetDataAsync.fulfilled, (state, action) => {
                //success connection?
                try {
                    //success login?
                    if (action.payload.active) {
                        state.status.statusUpdate = "success";
                    } else {
                        state.status.statusUpdate = "unsuccess";
                    }
                } catch (err) {
                    state.status.statusUpdate = "failed";
                    console.log("TypeError: JsonError when attempting resolve data.");
                }
            })
            .addCase(saveSetDataAsync.rejected, (state) => {
                state.status.statusUpdate = "failed";
                console.log("TypeError: NetworkError when attempting to fetch resource.");
            })
            .addCase(loginDataAsync.fulfilled, (state, action) => {
                //success connection?
                try {
                    //success login?
                    if (action.payload.active) {
                        state.setting.percent = action.payload.setting.percent;
                    }
                } catch (err) {
                    console.log("TypeError: JsonError when attempting resolve data.");
                }
            });
    },
});

//create actions obj
export const { setPercent, setStatusPercent } = settingSlice.actions;

//get data from store
export const selectSetting = (state: RootState) => state.settingState.setting;
export const selectStatus = (state: RootState) => state.settingState.status;

//create reducer
export default settingSlice.reducer;
