import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

//ts types interface
export interface intInitState {
    data: {
        id: number;
        proc: number;
        term: string;
        notes: string;
        client: {
            id: number;
            firstname: string;
            surname: string;
            nrDow: string;
            pesel: string;
            address: string;
            phone: string;
            notes: string;
        };
        item: Array<any>;
        extend: string;
        created: string;
    };
    status: {
        statusClient: boolean;
        statusItem: boolean;
        // statusAgree: string;
    };
}

//init state
const initialState: intInitState = {
    data: {
        id: 0,
        proc: 0,
        term: "",
        notes: "",
        client: {
            id: 0,
            firstname: "",
            surname: "",
            nrDow: "",
            pesel: "",
            address: "",
            phone: "",
            notes: "",
        },
        item: [],
        extend: "",
        created: "",
    },
    status: {
        statusClient: false,
        statusItem: false,
    },
};

//create slice obj
export const agreeSliceCreate = createSlice({
    name: "agreeStateCreate",
    initialState,
    reducers: {
        //set
        setClientData: (state, action: PayloadAction<any>) => {
            state.data.client = action.payload.obj;
            state.status.statusClient = action.payload.status;
        },
        setItemData: (state, action: PayloadAction<any>) => {
            if (!state.data.item.some((x) => x.id === action.payload.obj.id)) {
                let arr: any = { ...action.payload.obj };
                arr.price = 0;
                state.data.item = [...state.data.item, arr];
                state.status.statusItem = action.payload.status;
            }
        },
        //update
        setAllData: (state, action: PayloadAction<any>) => {
            state.data = action.payload;
            // const data = action.payload;
            // state.data.id = data.id;
            // state.data.proc = data.proc;
            // state.data.term = data.term;
            // state.data.notes = data.notes;
            // state.data.client = data.client;
            // state.data.item = data.item;
        },
        delSessionData: (state) => {
            state.data.client = initialState.data.client;
            state.data.item = initialState.data.item;
            state.status.statusClient = false;
            state.status.statusItem = false;
        },
        //delete
        delItemData: (state, action: PayloadAction<any>) => {
            if (state.data.item.length > 1)
                state.data.item = state.data.item.filter((x) => Number(x.id) !== Number(action.payload));
        },
        updateItemData: (state, action: PayloadAction<any>) => {
            state.data.item[action.payload.inx].price = action.payload.price;
        },
    },
});

//create actions obj
export const { setClientData, setItemData, setAllData, delItemData, delSessionData, updateItemData } =
    agreeSliceCreate.actions;

//get data from store
export const selectAgreeData = (state: RootState) => state.agreeStateCreate.data;
export const selectAgreeStatus = (state: RootState) => state.agreeStateCreate.status;

//create reducer
export default agreeSliceCreate.reducer;
