import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { fetchApi } from "../../../helper/fetchApi";
import { toDateRev } from "../../../helper/formatData";
import { loginDataAsync } from "../user/userSlice";
import { moveDataAsync } from "../movePoint/movePointSlice";

//interface
interface intInitSearch {
    id_location: number; //lokaliacja przedmiotu
    //(= 0) wszystkie lokalizacje
    //(> 0) wybrana lokalizacja
    status: string; //status umowy
    start: string; //wyszukaj po czasie dodania
    stop: string; //wyszukaj po czasie dodania
    text: string; //wyszukaj po tekscie w polach
    interval: number; //paginacja strony
}
//interface
export interface intInitState {
    data: {
        data: {
            amount: 0;
            data: Array<any>;
        };
        // client: {
        //     id: number;
        //     firstname: string;
        //     surname: string;
        //     nrDow: string;
        //     pesel: string;
        //     address: string;
        //     phone: string;
        //     notes: string;
        // };
        // item: Array<any>;
    };
    search: intInitSearch;
    filter: string;
    source: string;
    status: {
        statusAgreeS: string;
        statusAgreeSupdate: string;
    };
    modal: {
        modalShowSP: boolean;
    };
    page: number;
}

//init state
const initialState: intInitState = {
    data: {
        data: {
            amount: 0,
            data: [],
        },
        // client: {
        //     id: 0,
        //     firstname: "",
        //     surname: "",
        //     nrDow: "",
        //     pesel: "",
        //     address: "",
        //     phone: "",
        //     notes: "",
        // },
        // item: [],
    },
    filter: "",
    source: "",
    search: {
        id_location: 0,
        status: "all",
        start: "2022-06-01",
        stop: toDateRev(),
        text: "",
        interval: 0,
    },
    status: {
        statusAgreeS: "init",
        statusAgreeSupdate: "init",
    },
    modal: {
        modalShowSP: false,
    },
    page: 0,
};
//------------------------------------------------------------
//interface for request
interface IsearchAgreeDataAsync {
    driver: {
        //sterowanie strumieniem php
        ps: "P" | "S"; //umowy przedwstepne lub skupu
    };
    search: intInitSearch;
}
//fetch async data GET
export const searchAgreeDataAsync = createAsyncThunk(
    "agreeStateS/searchAgreeDataAsync",
    async (data: { data: IsearchAgreeDataAsync; access_token: string }, { rejectWithValue }) => {
        try {
            const response = await fetchApi(`/agree/rows`, data.data, data.access_token);
            return response;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);
//-------------------------------------------------------------
//interface for request
interface IupdateAgreeDataAsync {
    driver: {
        //sterowanie strumieniem php
        ps: string; //umowy przedwstepne lub skupu
        id: number; //id umowy, (= 0) new record, (> 0) update record
    };
    data: {
        //dane umowy do save lub update
        id_location?: number; //id lokalizacji
        client?: any; //json format
        item?: any; //json format
        notes?: string; //notatki
        status?: string; //status
    };
    search: intInitSearch;
}
//fetch async data from a server SAVE/UPDATE
export const updateAgreeDataAsync = createAsyncThunk(
    "/agreeStateS/updateAgreeDataAsync",
    async (data: { data: IupdateAgreeDataAsync; access_token: string }, { rejectWithValue }) => {
        try {
            const response = await fetchApi("/agree/update", data.data, data.access_token);
            return response;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);
//----------------------------------------------------------
//create slice obj
export const agreeSliceS = createSlice({
    name: "agreeStateS",
    initialState,
    reducers: {
        // setClientData: (state, action: PayloadAction<any>) => {
        //     const client = JSON.parse(action.payload);
        //     state.data.client = client;
        // },
        // setItemData: (state, action: PayloadAction<any>) => {
        //     const item = JSON.parse(action.payload);
        //     state.data.item = item;
        // },
        setStatus: (state, action: PayloadAction<any>) => {
            state.status.statusAgreeS = action.payload;
        },
        setStatusUpdate: (state, action: PayloadAction<any>) => {
            state.status.statusAgreeSupdate = action.payload;
        },
        resetData: (state) => {
            state.data = initialState.data;
        },
        resetStatus: (state) => {
            state.status = initialState.status;
        },
        // resetFilter: (state) => {
        //     state.filter = initialState.filter;
        // },
        resetSearch: (state) => {
            state.search = initialState.search;
        },
        // setFilter: (state, action: PayloadAction<any>) => {
        //     state.filter = action.payload;
        // },
        setSearch: (state, action: PayloadAction<any>) => {
            state.search = action.payload;
        },
        // setSource: (state, action: PayloadAction<any>) => {
        //     state.source = action.payload;
        // },
        setModalShowSP: (state, action: PayloadAction<any>) => {
            state.modal.modalShowSP = action.payload;
        },
        //setup startu paginacji i limitu dla widoku
        setPageData: (state, action: PayloadAction<any>) => {
            state.page = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            //ROWS
            .addCase(searchAgreeDataAsync.pending, (state) => {
                state.status.statusAgreeS = "load";
            })
            .addCase(searchAgreeDataAsync.fulfilled, (state, action) => {
                //success connection?
                try {
                    //success login?
                    if (action.payload.active) {
                        const data = action.payload.data;
                        state.data.data.amount = data.amount;
                        state.data.data.data = data.data.map((x: any) => {
                            return { ...x, client: JSON.parse(x.client), item: JSON.parse(x.item) };
                        });
                        state.status.statusAgreeS = "success";
                    } else {
                        state.data = initialState.data;
                        state.status.statusAgreeS = "unsuccess";
                    }
                } catch (err) {
                    state.status.statusAgreeS = "failed";
                    console.log("TypeError: JsonError when attempting resolve data.");
                }
            })
            .addCase(searchAgreeDataAsync.rejected, (state) => {
                state.status.statusAgreeS = "failed";
                console.log("TypeError: NetworkError when attempting to fetch resource.");
            })
            //UPDATE
            .addCase(updateAgreeDataAsync.pending, (state) => {
                state.status.statusAgreeSupdate = "load";
            })
            .addCase(updateAgreeDataAsync.fulfilled, (state, action) => {
                //success connection?
                try {
                    //success login?
                    if (action.payload.active) {
                        const data = action.payload.data;
                        state.data.data.amount = data.amount;
                        state.data.data.data = data.data.map((x: any) => {
                            return { ...x, client: JSON.parse(x.client), item: JSON.parse(x.item) };
                        });
                        state.status.statusAgreeSupdate = "success";
                    } else {
                        state.status.statusAgreeSupdate = "unsuccess";
                    }
                } catch (err) {
                    state.status.statusAgreeSupdate = "failed";
                    console.log("TypeError: JsonError when attempting resolve data.");
                }
            })
            .addCase(updateAgreeDataAsync.rejected, (state) => {
                state.status.statusAgreeSupdate = "failed";
                console.log("TypeError: NetworkError when attempting to fetch resource.");
            })
            //SETUP ID LOCATION
            .addCase(loginDataAsync.fulfilled, (state, action) => {
                try {
                    if (action.payload.active) {
                        //full update
                        const data = action.payload;
                        state.search.id_location = data.user.id_location;
                    }
                } catch (err) {}
            })
            //UPDATE ROWS AFTER MOVE AGREE TO DIFFERENT POINT
            .addCase(moveDataAsync.fulfilled, (state, action) => {
                try {
                    if (action.payload.active) {
                        //full update
                        const data = action.payload.data;
                        state.data.data.amount = data.amount;
                        state.data.data.data = data.data.map((x: any) => {
                            return { ...x, client: JSON.parse(x.client), item: JSON.parse(x.item) };
                        });
                    }
                } catch (err) {}
            });
        //UPDATE DATA INSERT AFTER SAVE
        // .addCase(saveAgreeDataAsync.fulfilled, (state, action) => {
        //     //success connection?
        //     try {
        //         //success login?
        //         if (action.payload.active) {
        //             state.data = action.payload.data;
        //         }
        //     } catch (err) {
        //         console.log("TypeError: JsonError when attempting resolve data.");
        //     }
        // })
        // //UPDATE DATA INSERT AFTER UPDATE
        // .addCase(updateAgreeDataAsync.fulfilled, (state, action) => {
        //     //success connection?
        //     try {
        //         //success login?
        //         if (action.payload.active) {
        //             state.data = action.payload.data;
        //         }
        //     } catch (err) {
        //         console.log("TypeError: JsonError when attempting resolve data.");
        //     }
        // })
        // //UPDATE DATA INSERT AFTER EXTEND
        // .addCase(updateAgreePdataAsync.fulfilled, (state, action) => {
        //     //success connection?
        //     try {
        //         //success login?
        //         if (action.payload.active) {
        //             state.data = action.payload.data;
        //         }
        //     } catch (err) {
        //         console.log("TypeError: JsonError when attempting resolve data.");
        //     }
        // })
        // //UPDATE DATA AFTER RETURN AND CLOSE
        // .addCase(updateAgreePReturnAsync.fulfilled, (state, action) => {
        //     //success connection?
        //     try {
        //         //success login?
        //         if (action.payload.active) {
        //             state.data = action.payload.data;
        //         }
        //     } catch (err) {
        //         console.log("TypeError: JsonError when attempting resolve data.");
        //     }
        // })
        // //UPDATE DATA AFTER MOVE TO DEPOT
        // .addCase(moveAgreeDataAsync.fulfilled, (state, action) => {
        //     //success connection?
        //     try {
        //         //success login?
        //         if (action.payload.active) {
        //             state.data = action.payload.data;
        //         }
        //     } catch (err) {
        //         console.log("TypeError: JsonError when attempting resolve data.");
        //     }
        // });
    },
});
//create actions obj
export const {
    // setClientData,
    // setItemData,
    setStatus,
    setStatusUpdate,
    resetData,
    resetStatus,
    // setFilter,
    // resetFilter,
    setSearch,
    resetSearch,
    // setSource,
    setPageData,
    setModalShowSP,
} = agreeSliceS.actions;

//get data from store
export const selectData = (state: RootState) => state.agreeStateS.data;
export const selectStatus = (state: RootState) => state.agreeStateS.status;
export const selectSearchData = (state: RootState) => state.agreeStateS.search;
export const selectFilterData = (state: RootState) => state.agreeStateS.filter;
export const selectPageData = (state: RootState) => state.agreeStateS.page;
export const selectSource = (state: RootState) => state.agreeStateS.source;
export const selectModalStatus = (state: RootState) => state.agreeStateS.modal;

//create reducer
export default agreeSliceS.reducer;
