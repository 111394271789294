import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setAllData } from "../../../redux/reducer/agree/agreeSliceCreate";
// import { selectPointData } from "../../../../redux/reducer/point/pointSlice";

import AgreeModalEdit from "../../../comp/modal/agree/agreeS/edit/AgreeModal";
import AgreeModalView from "../../../comp/modal/agree/agreeS/view/AgreeModal";
import AgreeModalClose from "../../../comp/modal/agree/agreeS/close/AgreeModal";
import AgreeModalHist from "../../../comp/modal/history/HistModal";
import MoveModal from "../../../comp/modal/agree/moveDepo/backDepo/MoveModal";
import MovePointModal from "../../../comp/modal/agree/movePoint/movePoint/MovePointModal";
import GetPointModal from "../../../comp/modal/agree/movePoint/getPoint/GetPointModal";

import { getHistDataAsync } from "../../../redux/reducer/history/histSlice";
import { selectUserData } from "../../../redux/reducer/user/userSlice";
import { setMoveData } from "../../../redux/reducer/moveDepot/moveDepotSlice";
import { setMovePointData } from "../../../redux/reducer/movePoint/movePointSlice";

//--------------------------------------------------------------------------------------------------

export default function PageAgreeAction({ driver, obj }: { driver: string; obj: any }) {
    //redux
    const dispatch = useAppDispatch();
    const { access_token } = useAppSelector(selectUserData);

    //hooks
    const [modalEdit, setModalEdit] = useState(false);
    const [modalView, setModalView] = useState(false);
    const [modalHist, setModalHist] = useState(false);
    const [modalClose, setModalClose] = useState(false);
    const [modalMove, setModalMove] = useState(false);
    const [modalMovePoint, setModalMovePoint] = useState(false);
    const [modalGetPoint, setModalGetPoint] = useState(false);

    //funView
    const chooseAction = () => {
        switch (driver) {
            case "view": {
                return (
                    <Dropdown.Item
                        onClick={() => {
                            dispatch(setAllData(obj));
                            setModalView(true);
                        }}
                    >
                        Podgląd
                    </Dropdown.Item>
                );
            }
            case "edit": {
                return (
                    <Dropdown.Item
                        onClick={() => {
                            dispatch(setAllData(obj));
                            setModalEdit(true);
                        }}
                    >
                        Edytuj
                    </Dropdown.Item>
                );
            }
            case "pubAllegro": {
                return (
                    <Dropdown.Item
                        onClick={() => {
                            // dispatch(setReturnData(obj));
                            // setModalShowReturn(true);
                        }}
                    >
                        Wystaw na Allegro
                    </Dropdown.Item>
                );
            }
            case "backAllegro": {
                return (
                    <Dropdown.Item
                        onClick={() => {
                            // dispatch(setReturnData(obj));
                            // setModalShowReturn(true);
                        }}
                    >
                        Wycofaj z Allegro
                    </Dropdown.Item>
                );
            }
            case "salePoint": {
                return (
                    <Dropdown.Item
                        onClick={() => {
                            dispatch(setAllData(obj));
                            setModalClose(true);
                        }}
                    >
                        Sprzedaj w punkcie
                    </Dropdown.Item>
                );
            }
            case "moveAgree": {
                return (
                    <Dropdown.Item
                        onClick={() => {
                            dispatch(setMoveData(obj));
                            setModalMove(true);
                        }}
                    >
                        Cofnij do wstepnych
                    </Dropdown.Item>
                );
            }
            case "movePoint": {
                return (
                    <Dropdown.Item
                        onClick={() => {
                            dispatch(setMovePointData(obj));
                            setModalMovePoint(true);
                        }}
                    >
                        Zadeklaruj przeniesienie
                    </Dropdown.Item>
                );
            }
            case "history": {
                return (
                    <Dropdown.Item
                        onClick={() => {
                            dispatch(
                                getHistDataAsync({
                                    data: {
                                        what: "agreeS",
                                        id_what: obj.id,
                                    },
                                    access_token,
                                })
                            );

                            setModalHist(true);
                        }}
                    >
                        Historia
                    </Dropdown.Item>
                );
            }
        }
    };

    //view
    return (
        <>
            {chooseAction()}
            {/* show modal edit */}
            <AgreeModalEdit show={modalEdit} onHide={() => setModalEdit(false)} />
            <AgreeModalView show={modalView} onHide={() => setModalView(false)} />
            <AgreeModalHist
                show={modalHist}
                onHide={() => setModalHist(false)}
                descript={`Historia umowy skupu nr: ${obj.id}`}
            />
            <AgreeModalClose show={modalClose} onHide={() => setModalClose(false)} />
            <MoveModal show={modalMove} onHide={() => setModalMove(false)} />
            <MovePointModal show={modalMovePoint} onHide={() => setModalMovePoint(false)} />
            <GetPointModal show={modalGetPoint} onHide={() => setModalGetPoint(false)} />
        </>
    );
}
