import { useState } from "react";
import { Navbar, Nav, Container, ButtonGroup, Button } from "react-bootstrap";
//modal
import AgreeModalP from "../../../modal/agree/agreeP/add/AgreeModal";
import AgreeModalS from "../../../modal/agree/agreeS/add/AgreeModal";
//redux
import { selectAgreeData, delSessionData, selectAgreeStatus } from "../../../../redux/reducer/agree/agreeSliceCreate";
import OffcanvasClient from "./OffcanvasClient";
import OffcanvasItem from "./OffcanvasItem";
import { useAppDispatch, useAppSelector } from "../../../../hooks";

//------------------------------------------------------------
export default function AgreeNavPasek() {
    //modal
    const [modalShowUP, setModalShowUP] = useState(false);
    const [modalShowUS, setModalShowUS] = useState(false);
    const [offCanvasShowClient, setOffCanvasShowClient] = useState(false);
    const [offCanvasShowItem, setOffCanvasShowItem] = useState(false);

    //redux
    const dispatch = useAppDispatch();
    const { client, item } = useAppSelector(selectAgreeData);
    const { statusClient, statusItem } = useAppSelector(selectAgreeStatus);

    //variables
    const chechAccess = !(statusClient && statusItem);
    const checkNav = statusClient || statusItem;

    //view
    return (
        <>
            {checkNav && (
                <Navbar expand="lg" bg="warning" variant="light" fixed="bottom">
                    <Container fluid>
                        <Navbar.Brand>Pasek umowy</Navbar.Brand>
                        <Nav>
                            <ButtonGroup className="me-auto">
                                <Button
                                    disabled={client.id === 0}
                                    variant="secondary"
                                    size="sm"
                                    onClick={() => {
                                        setOffCanvasShowClient(true);
                                    }}
                                >
                                    Klient: {client.id > 0 && `${client.firstname} ${client.surname}`}
                                    {/* Klient: {selectClient.firstname} {selectClient.surname} */}
                                </Button>
                                <Button
                                    disabled={item.length === 0}
                                    variant="secondary"
                                    size="sm"
                                    onClick={() => {
                                        setOffCanvasShowItem(true);
                                    }}
                                >
                                    Przedmiot:
                                    {item
                                        .reduce((x, y) => {
                                            return x + ", " + y.name;
                                        }, "")
                                        .substring(1)}
                                </Button>
                            </ButtonGroup>
                        </Nav>
                        <Nav>
                            <ButtonGroup>
                                <Button
                                    disabled={chechAccess}
                                    variant="secondary"
                                    size="sm"
                                    onClick={() => {
                                        setModalShowUP(true);
                                    }}
                                >
                                    Utwórz umowę Przed
                                </Button>
                                <Button
                                    disabled={chechAccess}
                                    variant="secondary"
                                    size="sm"
                                    onClick={() => {
                                        setModalShowUS(true);
                                    }}
                                >
                                    Utwórz umowę Skupu
                                </Button>
                                <Button variant="danger" size="sm" onClick={() => dispatch(delSessionData())}>
                                    Anuluj sesje
                                </Button>
                            </ButtonGroup>
                        </Nav>
                    </Container>
                </Navbar>
            )}

            <AgreeModalP show={modalShowUP} onHide={() => setModalShowUP(false)} />
            <AgreeModalS show={modalShowUS} onHide={() => setModalShowUS(false)} />

            {/* Offcanvas for show selected Client */}
            <OffcanvasClient show={offCanvasShowClient} setShow={setOffCanvasShowClient} />
            <OffcanvasItem show={offCanvasShowItem} setShow={setOffCanvasShowItem} />
        </>
    );
}
