import { Table } from "react-bootstrap";
import { toPLN } from "../../../../helper/formatData";

//-----------------------------------------------------------------
export default function TableAgree({ item }: any) {
    //redux

    //variables
    let suma = 0;

    //view
    return (
        <Table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Nota</th>
                    <th className="textRight">Wartość</th>
                </tr>
            </thead>
            <tbody>
                {item.map((obj: any, inx: number) => {
                    const price = Number(obj.price);
                    suma += price;
                    return (
                        <tr key={inx}>
                            <td>{obj.id}</td>
                            <td>{obj.notes}</td>
                            <td className="textRight">{toPLN(price)}</td>
                        </tr>
                    );
                })}
            </tbody>
            <tfoot>
                <tr className="textRight">
                    <td></td>
                    <td>SUMA:</td>
                    <td>
                        <b>{toPLN(suma)}</b>
                    </td>
                </tr>
            </tfoot>
        </Table>
    );
}
