import { Form, Row, Col, FloatingLabel } from "react-bootstrap";
//redux
import { useAppDispatch, useAppSelector } from "../../hooks";
import { searchReportDataAsync, selectSearch, setSearch } from "../../redux/reducer/report/reportSlice";
import { selectUserData } from "../../redux/reducer/user/userSlice";

//---------------------------------------------------

export default function SearchByLocation() {
    //redux
    const dispatch = useAppDispatch();
    const searchData = useAppSelector(selectSearch);
    const { locations, user, access_token } = useAppSelector(selectUserData);

    //onchange
    const onChangeStatus = (ev: any) => {
        const { value } = ev.target;
        const search = { ...searchData, id_location: value };
        dispatch(setSearch(search));
        dispatch(
            searchReportDataAsync({
                data: search,
                access_token,
            })
        );
    };

    return (
        <Form>
            <Row className="g-0">
                <Col sm>
                    <FloatingLabel label="Lokalizacja">
                        <Form.Select id="active" onChange={onChangeStatus} defaultValue={user.id_location}>
                            <option key="0" value="0">
                                Wszystkie
                            </option>
                            {locations.map((obj) => {
                                return (
                                    <option key={obj.id} value={obj.id}>
                                        {obj.location}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </FloatingLabel>
                </Col>
            </Row>
        </Form>
    );
}
