import { Dropdown } from "react-bootstrap";
import { useAppSelector } from "../../../hooks";
import { selectUserData } from "../../../redux/reducer/user/userSlice";
import PageAgreeAction from "./PageClientAction";

//-------------------------------------------------------------------------------------
export default function PageClientSetupAction({ obj }: { obj: any }) {
    //redux
    const { user } = useAppSelector(selectUserData);
    //funView
    const chooseCollectionAction = () => {
        switch (obj.active) {
            case "A": {
                return (
                    <>
                        <PageAgreeAction driver="addToAgreement" obj={obj} />
                        <Dropdown.Divider />
                        <PageAgreeAction driver="view" obj={obj} />
                        <PageAgreeAction driver="active" obj={obj} />
                        {user.role === "A" && <PageAgreeAction driver="edit" obj={obj} />}
                        <Dropdown.Divider />
                        <PageAgreeAction driver="history" obj={obj} />
                    </>
                );
            }
            case "U": {
                return (
                    <>
                        <PageAgreeAction driver="view" obj={obj} />
                        <PageAgreeAction driver="active" obj={obj} />
                        <Dropdown.Divider />
                        <PageAgreeAction driver="history" obj={obj} />
                    </>
                );
            }
        }
    };

    //view
    return <>{chooseCollectionAction()}</>;
}
