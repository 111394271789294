import styles from "../../../styles/User.module.css";
import { Table, DropdownButton, ButtonGroup } from "react-bootstrap";
//comp
import UserNav from "../../../comp/navig/user/edit/admin/UserNav";
//redux
import { useAppSelector } from "../../../hooks";
import { selectUserData } from "../../../redux/reducer/user/userSlice";
import getLocationName from "../../../helper/getLocationName";

//----------------------------------------------------------

export default function PageUserTab() {
    //redux
    const { users, locations } = useAppSelector(selectUserData);

    //view
    return (
        <>
            <Table striped bordered hover size="lg" responsive>
                <thead className={styles.textCenter}>
                    <tr>
                        <th style={{ width: "50px" }}>#</th>
                        <th>User name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Location</th>
                        <th style={{ width: "120px" }}>Role</th>
                        <th style={{ width: "120px" }}>Status</th>
                        <th style={{ width: "180px" }}>LastLog</th>
                        <th style={{ width: "120px" }}>Created</th>
                        <th style={{ width: "120px" }}>Closed</th>
                        <th style={{ width: "90px" }}>Actions</th>
                    </tr>
                </thead>
                <tbody className={styles.textCenter}>
                    {users.map((obj, inx) => {
                        return (
                            <tr key={inx} className={obj.active === "U" ? styles.colorInactive : undefined}>
                                <td style={{ padding: "14px 0px" }}>{inx + 1}</td>
                                <td style={{ padding: "14px 0px" }}>{obj.name}</td>
                                <td style={{ padding: "14px 0px" }}>{obj.email}</td>
                                <td style={{ padding: "14px 0px" }}>{obj.phone}</td>
                                <td style={{ padding: "14px 0px" }}>{getLocationName(locations, obj.id_location)}</td>
                                <td style={{ padding: "14px 0px" }}>{obj.role === "A" ? "Admin" : "User"}</td>
                                <td style={{ padding: "14px 0px" }}>{obj.active === "A" ? "Active" : "Inactive"}</td>
                                <td style={{ padding: "14px 0px" }}>{obj.lastLog}</td>
                                <td style={{ padding: "14px 0px" }}>{obj.created}</td>
                                <td style={{ padding: "14px 0px" }}>{obj.closed}</td>
                                <td>
                                    <DropdownButton
                                        as={ButtonGroup}
                                        key={inx}
                                        id={`dropdown-button-user`}
                                        drop="start"
                                        variant="outline-secondary"
                                        title="Select"
                                        disabled={obj.active === "U"}
                                    >
                                        <>
                                            <UserNav placement="end" scroll={true} backdrop={true} obj={obj} />
                                        </>
                                    </DropdownButton>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot></tfoot>
            </Table>
            {/* <UserEditModal show={modalShow} onHide={() => setModalShow(false)} /> */}
        </>
    );
}
