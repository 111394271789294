import { FloatingLabel, Form, ListGroup } from "react-bootstrap";
import { BsArrow90DegUp } from "react-icons/bs";
import { addDays } from "../../../../helper/formatData";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { updateItemData } from "../../../../redux/reducer/agree/agreeSliceCreate";
import { selectUserData } from "../../../../redux/reducer/user/userSlice";
import styles from "../../../../styles/Item.module.css";

//--------------------------------------------------------------
export default function FormAgree({ formik, item, disabled }: { formik: any; item: Array<any>; disabled: boolean }) {
    //redux
    const dispatch = useAppDispatch();
    const { user } = useAppSelector(selectUserData);
    //onchange
    const onchangePrice = (inx: number, ev: any) => {
        const { value } = ev.target;
        if (!isNaN(value)) {
            dispatch(updateItemData({ inx: inx, price: value }));
        }
    };
    //view
    return (
        <>
            <ListGroup className={styles.textRight}>
                <ListGroup.Item variant="primary">DANE ROZLICZENIA</ListGroup.Item>
            </ListGroup>
            {item.map((obj, inx) => {
                return (
                    <FloatingLabel key={inx} label={`Wprowadź cenę dla ID: ${obj.id}`}>
                        <Form.Control
                            id={obj.id}
                            type="text"
                            value={obj.price}
                            disabled={disabled}
                            onChange={(e) => onchangePrice(inx, e)}
                            placeholder={`Wprowadź cenę dla ID: ${obj.id}`}
                        />
                    </FloatingLabel>
                );
            })}
            <FloatingLabel label="Oprocentowanie w skali miesięcznej">
                <Form.Control
                    id="proc"
                    type="text"
                    disabled={user.role === "U" || disabled}
                    placeholder="Oprocentowanie w skali miesięcznej"
                    {...formik.getFieldProps("proc")}
                />
            </FloatingLabel>
            {formik.touched.proc && formik.errors.proc && (
                <h6 className="validError">
                    <>
                        <BsArrow90DegUp />
                        {formik.errors.proc}
                    </>
                </h6>
            )}
            <FloatingLabel label="Start">
                <Form.Control
                    id="extend"
                    type="date"
                    disabled={true}
                    placeholder="Start"
                    min={addDays(0)}
                    max={addDays(user.role === "U" ? 30 : 365)}
                    {...formik.getFieldProps("extend")}
                />
            </FloatingLabel>
            {formik.touched.extend && formik.errors.extend && (
                <h6 className="validError">
                    <BsArrow90DegUp />
                    {formik.errors.extend}
                </h6>
            )}
            <FloatingLabel label="Termin">
                <Form.Control
                    id="term"
                    type="date"
                    disabled={disabled}
                    placeholder="Termin"
                    min={addDays(0)}
                    max={addDays(user.role === "U" ? 30 : 365)}
                    {...formik.getFieldProps("term")}
                />
            </FloatingLabel>
            {formik.touched.term && formik.errors.term && (
                <h6 className="validError">
                    <BsArrow90DegUp />
                    {formik.errors.term}
                </h6>
            )}
            <FloatingLabel label="Notatka">
                <Form.Control
                    id="notes"
                    as="textarea"
                    disabled={disabled}
                    placeholder="Notatka"
                    {...formik.getFieldProps("notes")}
                />
            </FloatingLabel>
            {formik.touched.notes && formik.errors.notes ? (
                <h6 className="validError">
                    <BsArrow90DegUp />
                    {formik.errors.notes}
                </h6>
            ) : null}
        </>
    );
}
