//css
import styles from "../../../../styles/Login.module.css"; //bootstrap
import { ListGroup, Row, Col, Form, FloatingLabel } from "react-bootstrap";
//formik
import { useFormik } from "formik";
import * as Yup from "yup";
import { BsArrow90DegUp } from "react-icons/bs";
//redux
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
    updatePwdDataAsync,
    selectStatusUpdatePwd,
    selectUserData,
    selectUserUserData,
    user,
} from "../../../../redux/reducer/user/userSlice";
import UserSubmit from "./UserSubmit2";

//--------------------------------------------------------------

export default function UserFormPwd() {
    //redux
    const dispatch = useAppDispatch();
    const data: user = useAppSelector(selectUserUserData);
    const statusUpdatePwd = useAppSelector(selectStatusUpdatePwd);
    const { access_token } = useAppSelector(selectUserData);

    //formik
    const formik = useFormik({
        initialValues: {
            id: data.id,
            pwd1: "",
            pwd2: "",
        },
        validationSchema: Yup.object({
            pwd1: Yup.string().required("Required").min(8, "Min 8 chars").max(255, "Max 100 chars"),
            pwd2: Yup.string().required("Required").min(8, "Min 8 chars").max(255, "Max 100 chars"),
        }),
        onSubmit: (values) => {
            if (values.pwd1 === values.pwd2) {
                //update values
                dispatch(updatePwdDataAsync({ data: { id: values.id, pwd: values.pwd1 }, access_token }));
            } else {
                alert("No match passwords.\nWrite again.");
            }
        },
    });

    //view
    return (
        <Form>
            <Row>
                <Col>
                    <Row>
                        <Col className="d-grid gap-1">
                            <ListGroup className={styles.textRight}>
                                <ListGroup.Item variant="secondary">UPDATE PASSWORD</ListGroup.Item>
                            </ListGroup>
                            <FloatingLabel label="New password">
                                <Form.Control
                                    id="pwd1"
                                    type="password"
                                    placeholder="New password"
                                    minLength={8}
                                    disabled={statusUpdatePwd !== "init"}
                                    {...formik.getFieldProps("pwd1")}
                                />
                            </FloatingLabel>
                            {formik.touched.pwd1 && formik.errors.pwd1 && (
                                <h6 className="validError">
                                    <BsArrow90DegUp />
                                    {formik.errors.pwd1}
                                </h6>
                            )}
                            <FloatingLabel label="New password again">
                                <Form.Control
                                    id="pwd2"
                                    type="password"
                                    placeholder="New password again"
                                    minLength={8}
                                    disabled={statusUpdatePwd !== "init"}
                                    {...formik.getFieldProps("pwd2")}
                                />
                            </FloatingLabel>
                            {formik.touched.pwd2 && formik.errors.pwd2 && (
                                <h6 className="validError">
                                    <BsArrow90DegUp />
                                    {formik.errors.pwd2}
                                </h6>
                            )}
                            <UserSubmit formik={formik} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
}
