import { Container, Row, Col, Table } from "react-bootstrap";
import { useAppSelector } from "../../../../../hooks";
import { selectPointData } from "../../../../../redux/reducer/movePoint/getPointSlice";
import GetPointButton from "./GetPointButton";

//------------------------------------------------------------------------------------

export default function GetPointIndex() {
    //redux
    const data = useAppSelector(selectPointData);

    //view
    return (
        <Container fluid>
            <Row>
                <Col>
                    <Table size="sm" striped bordered hover responsive style={{ textAlign: "center" }}>
                        <thead>
                            <tr>
                                <th>lp</th>
                                <th>ID</th>
                                <th>Lokalizacja (zródło)</th>
                                <th>Lokalizacja (docelowe)</th>
                                <th>Kwota</th>
                                <th>Notatka</th>
                                <th>Utworzony</th>
                                <th style={{ width: "100px" }}>Akcje</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((obj: any, inx: number) => {
                                return (
                                    <tr key={inx}>
                                        <td style={{ padding: "13px 0px" }}>{inx + 1}</td>
                                        <td style={{ padding: "13px 0px", width: "30px" }}>{obj.id_agree}</td>
                                        <td style={{ padding: "13px 0px" }}>{obj.location_source}</td>
                                        <td style={{ padding: "13px 0px" }}>{obj.location_dest}</td>
                                        <td style={{ padding: "13px 0px" }}>{obj.price}</td>
                                        <td style={{ padding: "13px 0px" }}>{obj.notes}</td>
                                        <td style={{ padding: "13px 0px" }}>{obj.created}</td>
                                        <td style={{ textAlign: "center" }}>
                                            <GetPointButton obj={obj} inx={inx} />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}
