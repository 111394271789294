import { Form, Row, Col, FloatingLabel } from "react-bootstrap";
//redux
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { selectUserData } from "../../../redux/reducer/user/userSlice";
import {
    setPageData,
    selectPageData,
    searchAgreeDataAsync,
    selectSearchData,
    setSearch,
} from "../../../redux/reducer/agree/agreeSliceS";

//---------------------------------------------------

export default function SearchByLocation() {
    //redux
    const dispatch = useAppDispatch();
    const { access_token } = useAppSelector(selectUserData);
    const searchData = useAppSelector(selectSearchData);
    const pageData = useAppSelector(selectPageData);
    const { locations, user } = useAppSelector(selectUserData);

    //onchange
    const onChangeStatus = (ev: any) => {
        const { value } = ev.target;
        const search = { ...searchData, id_location: value };
        dispatch(setSearch(search));
        if (pageData > 1) dispatch(setPageData(0));
        dispatch(
            searchAgreeDataAsync({
                data: {
                    driver: { ps: "S" },
                    search,
                },
                access_token,
            })
        );
    };

    return (
        <Form>
            <Row className="g-0">
                <Col sm>
                    <FloatingLabel label="Lokalizacja">
                        <Form.Select id="active" onChange={onChangeStatus} defaultValue={user.id_location}>
                            <option key="0" value="0">
                                Wszystkie
                            </option>
                            {locations.map((obj) => {
                                return (
                                    <option key={obj.id} value={obj.id}>
                                        {obj.location}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </FloatingLabel>
                </Col>
            </Row>
        </Form>
    );
}
