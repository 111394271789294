//css
import styles from "../../../../styles/Login.module.css";
//bootstrap
import { ListGroup, Row, Col, Form, FloatingLabel } from "react-bootstrap";
//formik
import { useFormik } from "formik";
import * as Yup from "yup";
import { BsArrow90DegUp } from "react-icons/bs";
//redux
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
    checkEmailDataAsync,
    registerDataAsync,
    setStatusEmail,
    selectStatusEmail,
    selectStatusRegister,
    selectUserData,
} from "../../../../redux/reducer/user/userSlice";
import UserSubmit from "./UserSubmit";

//--------------------------------------------------------------

export default function UserForm() {
    //redux
    const dispatch = useAppDispatch();
    const statusEmail = useAppSelector(selectStatusEmail);
    const statusRegister = useAppSelector(selectStatusRegister);
    const { locations, access_token } = useAppSelector(selectUserData);

    //formik
    const formik = useFormik({
        initialValues: {
            id_location: 0,
            name: "",
            email: "",
            phone: "",
            pwd: "",
        },
        validationSchema: Yup.object({
            id_location: Yup.number().required("Required").min(1, "select location"),
            name: Yup.string().required("Required").min(3, "Min 3 chars").max(100, "Max 100 chars"),
            email: Yup.string().email("Email").required("Required").min(6, "Min 6 chars").max(100, "Max 100 chars"),
            phone: Yup.string()
                .matches(/^[\s\d]*$/, "Only numbers")
                // .required("Required")
                .min(10, "Min 10 chars")
                .max(15, "Max 15 chars"),
            pwd: Yup.string().required("Required").min(8, "Min 8 chars").max(255, "Max 100 chars"),
        }),
        onSubmit: (values) => {
            if (statusEmail === "free") {
                //update values
                dispatch(registerDataAsync({ data: values, access_token }));
            } else {
                alert("Use different email.\nThat one is in use.");
            }
        },
    });

    //check email
    const checkEmail = (ev: any) => {
        const { value } = ev.target;
        if (value.length > 5) {
            dispatch(checkEmailDataAsync({ data: { email: value }, access_token }));
        } else {
            dispatch(setStatusEmail("init"));
        }
    };

    //view
    return (
        <Form>
            <Row>
                <Col className="d-grid gap-1 mb-1">
                    <ListGroup className={styles.textRight}>
                        <ListGroup.Item variant="primary">NEW USER</ListGroup.Item>
                    </ListGroup>
                    <FloatingLabel label="Full name">
                        <Form.Control
                            id="name"
                            type="text"
                            placeholder="Full name"
                            maxLength={100}
                            disabled={statusRegister !== "init"}
                            {...formik.getFieldProps("name")}
                        />
                    </FloatingLabel>
                    {formik.touched.name && formik.errors.name && (
                        <h6 className="validError">
                            <BsArrow90DegUp />
                            {formik.errors.name}
                        </h6>
                    )}

                    <FloatingLabel label="Phone">
                        <Form.Control
                            id="phone"
                            type="text"
                            placeholder="Phone"
                            maxLength={15}
                            disabled={statusRegister !== "init"}
                            {...formik.getFieldProps("phone")}
                        />
                    </FloatingLabel>
                    {formik.touched.phone && formik.errors.phone && (
                        <h6 className="validError">
                            <BsArrow90DegUp />
                            {formik.errors.phone}
                        </h6>
                    )}

                    <FloatingLabel label="Location">
                        <Form.Select
                            aria-label="Choose location"
                            id="id_location"
                            disabled={statusRegister !== "init"}
                            {...formik.getFieldProps("id_location")}
                        >
                            <option key={0} value={0}>
                                Location
                            </option>
                            {locations.map((obj, inx) => {
                                return (
                                    <option key={obj.id} value={obj.id}>
                                        {obj.location}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </FloatingLabel>
                    {formik.touched.id_location && formik.errors.id_location && (
                        <h6 className="validError">
                            <BsArrow90DegUp />
                            {formik.errors.id_location}
                        </h6>
                    )}

                    <FloatingLabel label={`Email(${statusEmail})`}>
                        <Form.Control
                            id="email"
                            type="email"
                            placeholder={`Email(${statusEmail})`}
                            maxLength={100}
                            disabled={statusRegister !== "init"}
                            autoComplete="none"
                            onBlurCapture={checkEmail}
                            onKeyUp={checkEmail}
                            {...formik.getFieldProps("email")}
                        />
                    </FloatingLabel>
                    {formik.touched.email && formik.errors.email && (
                        <h6 className="validError">
                            <BsArrow90DegUp />
                            {formik.errors.email}
                        </h6>
                    )}

                    <FloatingLabel label="Password">
                        <Form.Control
                            id="pwd"
                            type="password"
                            placeholder="Password"
                            maxLength={255}
                            disabled={statusRegister !== "init"}
                            autoComplete="new-password"
                            {...formik.getFieldProps("pwd")}
                        />
                    </FloatingLabel>
                    {formik.touched.pwd && formik.errors.pwd && (
                        <h6 className="validError">
                            <BsArrow90DegUp />
                            {formik.errors.pwd}
                        </h6>
                    )}
                    <UserSubmit formik={formik} />
                </Col>
            </Row>
        </Form>
    );
}
