//pages
import PageUserNav from "./PageUserNav";
import PageUserTabUser from "./user/PageUserTab";
import PageUserTabAdmin from "./admin/PageUserTab";
//redux
import { useAppSelector } from "../../hooks";
import { selectUserData } from "../../redux/reducer/user/userSlice";

//-------------------------------------------------------

export default function PageUserIndex() {
    //redux
    const { user } = useAppSelector(selectUserData);

    //view
    return (
        <>
            <PageUserNav />
            {user.role === "A" ? <PageUserTabAdmin /> : <PageUserTabUser />}
        </>
    );
}
