import { Table } from "react-bootstrap";
import { toPLN, countProc, procUnit } from "../../../../helper/formatData";
import { useAppSelector } from "../../../../hooks";
import { selectAgreeData } from "../../../../redux/reducer/agree/agreeSliceCreate";
//-----------------------------------------------------------------

export default function TableAgree(props: any) {
    //redux
    const { item } = useAppSelector(selectAgreeData);

    //variables
    let suma = 0;
    let sumaAmount = 0;
    let sumaProc = 0;

    const proc = Number(props.proc);
    const term = props.term;
    const count = countProc(term);
    const dataItem = props.data;

    //view
    return (
        <Table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Nota</th>
                    <th className="textRight">Cena</th>
                    <th className="textRight">{count ? "Proc" : "Stała"}</th>
                    <th className="textRight">Wartość</th>
                </tr>
            </thead>
            <tbody>
                {dataItem.map((obj: any, inx: number) => {
                    const price = Number(obj.price);
                    const value = count ? procUnit(price, proc, term) : inx > 0 ? 0 : 5;
                    const total = price + value;
                    suma += total;
                    sumaAmount += price;
                    sumaProc += value;
                    return (
                        <tr key={inx}>
                            <td>{obj.id}</td>
                            <td>{obj.notes}</td>
                            <td className="textRight">{toPLN(price)}</td>
                            <td className="textRight">{toPLN(value)}</td>
                            <td className="textRight">{toPLN(total)}</td>
                        </tr>
                    );
                })}
            </tbody>
            <tfoot>
                <tr className="textRight">
                    <td></td>
                    <td>SUMA:</td>
                    <td>
                        <b>{toPLN(sumaAmount)}</b>
                    </td>
                    <td>
                        <b>{toPLN(sumaProc)}</b>
                    </td>
                    <td>
                        <b>{toPLN(suma)}</b>
                    </td>
                </tr>
            </tfoot>
        </Table>
    );
}
