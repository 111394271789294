import { useState } from "react";
import { Nav, Offcanvas } from "react-bootstrap";
//helper
import getLocationName from "../../../helper/getLocationName";
//comp
import LocationIndex from "./LocationIndex";
//hook
import { useAppSelector } from "../../../hooks";
//redux
import { selectUserData } from "../../../redux/reducer/user/userSlice";

//----------------------------------------------------------------------
export default function LocationNav() {
    //redux
    const { locations, user } = useAppSelector(selectUserData);

    //hook
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //view
    return (
        <>
            <Nav.Item>
                <Nav.Link
                    eventKey="location-link"
                    onClick={() => {
                        handleShow();
                    }}
                >
                    {locations.length > 0 && (
                        <span style={{ color: "#0080FF" }}>{getLocationName(locations, user.id_location)}</span>
                    )}
                </Nav.Link>
            </Nav.Item>
            <Offcanvas show={show} onHide={handleClose} placement="end" scroll={true} backdrop={true}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Lokalizacje</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <LocationIndex />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
