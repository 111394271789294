//style
import styles from "../../../styles/Client.module.css";
import { Table, DropdownButton, ButtonGroup } from "react-bootstrap";
//helper
import { toDate } from "../../../helper/formatData";
import getLocationName from "../../../helper/getLocationName";
//redux
import { useAppSelector } from "../../../hooks";
import { selectItemData } from "../../../redux/reducer/item/itemSlice";
import { selectUserData } from "../../../redux/reducer/user/userSlice";
import { dictionaryItem } from "../../../helper/dictionary";
import PageItemSetupAction from "./PageItemSetupAction";

//----------------------------------------------------------
export default function PageItemtTab() {
    //redux
    const { data } = useAppSelector(selectItemData);
    const { locations } = useAppSelector(selectUserData);

    //view
    return (
        <>
            <Table striped bordered hover size="lg" responsive>
                <thead className={styles.textCenter}>
                    <tr>
                        <th style={{ width: "50px" }}>#</th>
                        <th>Id</th>
                        <th>Zdjęcia</th>
                        <th>Nazwa</th>
                        <th>Stan</th>
                        <th>Sztuk</th>
                        <th>Notatka</th>
                        <th>Lokalizacja</th>
                        <th style={{ width: "115px" }}>Status</th>
                        <th style={{ width: "115px" }}>Dodany</th>
                        <th style={{ width: "115px" }}>Akcje</th>
                    </tr>
                </thead>
                <tbody className={styles.textCenter}>
                    {data.map((obj: any, inx: number) => {
                        return (
                            <tr key={inx} className={obj.active === "U" ? styles.colorInactive : undefined}>
                                <td style={{ padding: "13px 0px" }}>{inx + 1}</td>
                                <td style={{ padding: "13px 0px" }}>{obj.id}</td>
                                <td style={{ width: "250px", padding: "13px 0px" }}>
                                    {obj.image.length > 0 ? (
                                        <img
                                            key={inx}
                                            alt="brak zdjęć"
                                            src={`${process.env.PUBLIC_URL}/item/image/${obj.image[0]}`}
                                            width="150px"
                                        />
                                    ) : (
                                        "brak zdjęć"
                                    )}
                                </td>
                                <td style={{ padding: "13px 0px" }}>{obj.name}</td>
                                <td style={{ padding: "13px 0px" }}>
                                    {obj.quality === "new" ? "Nowy" : obj.quality === "used" ? "Używany" : "Uszkodzony"}
                                </td>
                                <td style={{ padding: "13px 0px" }}>{obj.amount}</td>
                                <td style={{ padding: "13px 0px" }}>{obj.notes}</td>
                                <td style={{ padding: "13px 0px" }}>{getLocationName(locations, obj.id_location)}</td>
                                <td style={{ padding: "13px 0px" }}>{dictionaryItem(obj.status)}</td>
                                <td style={{ padding: "13px 0px" }}>{toDate(obj.created)}</td>
                                <td>
                                    <DropdownButton
                                        as={ButtonGroup}
                                        key={inx}
                                        id={`dropdown-button-drop-${inx}`}
                                        drop="start"
                                        variant="outline-secondary"
                                        title="Wybierz"
                                    >
                                        <PageItemSetupAction obj={obj} />
                                    </DropdownButton>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot></tfoot>
            </Table>
        </>
    );
}
