import { useState } from "react";
import UserIndex from "./UserIndex";
import { Offcanvas, Button } from "react-bootstrap";
//redux
import { useAppDispatch } from "../../../../hooks";
import { setStatusRegister } from "../../../../redux/reducer/user/userSlice";

//-------------------------------------------------------------------------------

export default function UserNav({ ...props }) {
    //redux
    const dispatch = useAppDispatch();

    //hook
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //view
    return (
        <>
            <Button
                variant="success"
                size="lg"
                onClick={() => {
                    dispatch(setStatusRegister("init"));
                    handleShow();
                }}
            >
                Add user
            </Button>
            <Offcanvas show={show} onHide={handleClose} {...props}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Adding User</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <UserIndex />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
