//style
import styles from "../../../styles/Client.module.css";
import { Table, DropdownButton, ButtonGroup } from "react-bootstrap";
//helper
import { toDate } from "../../../helper/formatData";

//redux
import { useAppSelector } from "../../../hooks";
import { selectClientData } from "../../../redux/reducer/client/clientSlice";
import PageClientSetupAction from "./PageClientSetupAction";

//----------------------------------------------------------
export default function PageClientTab() {
    //redux
    const { data } = useAppSelector(selectClientData);

    //view
    return (
        <>
            <Table striped bordered hover size="lg" responsive>
                <thead className={styles.textCenter}>
                    <tr>
                        <th style={{ width: "50px" }}>#</th>
                        <th>Id</th>
                        <th>Imię</th>
                        <th>Nazwisko</th>
                        <th>nrDowodu</th>
                        <th>Pesel</th>
                        <th>Adres</th>
                        <th>Telefon</th>
                        <th>Notatki</th>
                        <th style={{ width: "115px" }}>Dodany</th>
                        <th style={{ width: "115px" }}>Akcje</th>
                    </tr>
                </thead>
                <tbody className={styles.textCenter}>
                    {data.data.map((obj: any, inx: number) => {
                        return (
                            <tr key={inx} className={obj.active === "U" ? styles.colorInactive : undefined}>
                                <td style={{ padding: "13px 0px" }}>{inx + 1}</td>
                                <td style={{ padding: "13px 0px" }}>{obj.id}</td>
                                <td style={{ padding: "13px 0px" }}>{obj.firstname}</td>
                                <td style={{ padding: "13px 0px" }}>{obj.surname}</td>
                                <td style={{ padding: "13px 0px" }}>{obj.nrDow}</td>
                                <td style={{ padding: "13px 0px" }}>{obj.pesel}</td>
                                <td style={{ padding: "13px 0px" }}>{obj.address}</td>
                                <td style={{ padding: "13px 0px" }}>{obj.phone}</td>
                                <td style={{ padding: "13px 0px" }}>{obj.notes}</td>
                                <td style={{ padding: "13px 0px" }}>{toDate(obj.created)}</td>
                                <td>
                                    <DropdownButton
                                        as={ButtonGroup}
                                        key={inx}
                                        id={`dropdown-button-drop-${inx}`}
                                        drop="start"
                                        variant="outline-secondary"
                                        title="Wybierz"
                                    >
                                        <PageClientSetupAction obj={obj} />
                                    </DropdownButton>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot></tfoot>
            </Table>
        </>
    );
}
