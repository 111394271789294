import { Form, Row, Col, FloatingLabel } from "react-bootstrap";
//redux
import { useAppDispatch } from "../../hooks";
import { setDirection } from "../../redux/reducer/report/reportSlice";

//---------------------------------------------------

export default function SearchByDirection() {
    //redux
    const dispatch = useAppDispatch();

    //onchange
    const onChangeStatus = (ev: any) => {
        const { value } = ev.target;
        dispatch(setDirection(value));
    };

    return (
        <Form>
            <Row className="g-0">
                <Col sm>
                    <FloatingLabel label="Kierunek">
                        <Form.Select id="active" onChange={onChangeStatus}>
                            <option value=""></option>
                            <option value="spend">Wydatki</option>
                            <option value="income">Przychody</option>
                            <option value="mix">Wyniki</option>
                        </Form.Select>
                    </FloatingLabel>
                </Col>
            </Row>
        </Form>
    );
}
