//css
import styles from "../../../styles/Login.module.css";
//bootstrap
import { ListGroup, Row, Col } from "react-bootstrap";
//redux
import { useAppSelector } from "../../../hooks";
import { selectUserData } from "../../../redux/reducer/user/userSlice";

//---------------------------------------------------------------------

export default function LoginInfo() {
    //redux
    const { user } = useAppSelector(selectUserData);

    //view
    return (
        <Row>
            <Col className="mt-3">
                <ListGroup className={styles.textCenter}>
                    <ListGroup.Item variant="secondary" className={styles.textRight}>
                        USER INFORMATION
                    </ListGroup.Item>
                    <ListGroup.Item variant="info">
                        {user.name}({user.role})
                    </ListGroup.Item>
                    <ListGroup.Item variant="info">{user.phone}</ListGroup.Item>
                    <ListGroup.Item variant="info">{user.email}</ListGroup.Item>
                    <ListGroup.Item variant="info">{user.lastLog}</ListGroup.Item>
                </ListGroup>
            </Col>
        </Row>
    );
}
