import { Button } from "react-bootstrap";
import { AiOutlinePrinter } from "react-icons/ai";

//=======================================================

export default function PrintByReport() {
    return (
        <>
            <Button
                style={{ marginRight: "10px" }}
                size="lg"
                variant="info"
                onClick={() => {
                    alert("ok");
                }}
            >
                <AiOutlinePrinter />
            </Button>
        </>
    );
}
