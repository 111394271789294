//css
import styles from "../../../../styles/Login.module.css";
//bootstrap
import { ListGroup, Row, Col, Button } from "react-bootstrap";
//redux
import { useAppDispatch } from "../../../../hooks";
import { setBoardData } from "../../../../redux/reducer/board/boardSlice";
//nav
import UserNav from "../../user/edit/user/UserNav";

//------------------------------------------------

export default function LoginUser() {
    //redux
    const dispatch = useAppDispatch();

    //function
    const navButton = () => {
        return (
            <Row>
                <Col className="d-grid gap-1">
                    <ListGroup className={styles.textRight}>
                        <ListGroup.Item variant="secondary">MANAGE ACCOUNT</ListGroup.Item>
                    </ListGroup>
                    <Button
                        variant="success"
                        size="lg"
                        onClick={() => {
                            dispatch(setBoardData("users"));
                        }}
                    >
                        Show users
                    </Button>
                    <UserNav placement="end" scroll={true} backdrop={true} />
                </Col>
            </Row>
        );
    };

    //view
    return (
        <Row>
            <Col className="d-grid gap-1 mt-3">{navButton()}</Col>
        </Row>
    );
}
