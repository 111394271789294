import { useState } from "react";
import { Offcanvas, Dropdown } from "react-bootstrap";
//comp
import UserIndex from "../UserIndex";
//redux
import { useAppDispatch } from "../../../../../hooks";
import { setUserUserData, setStatusUpdateUser, setStatusUpdatePwd } from "../../../../../redux/reducer/user/userSlice";

//-------------------------------------------------------------------------------

export default function UserNav({ ...props }) {
    //redux
    const dispatch = useAppDispatch();

    //hook
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //view
    return (
        <>
            <Dropdown.Item
                eventKey="1"
                onClick={() => {
                    dispatch(setUserUserData(props.obj));
                    dispatch(setStatusUpdateUser("init"));
                    dispatch(setStatusUpdatePwd("init"));
                    handleShow();
                }}
            >
                Edit
            </Dropdown.Item>
            <Offcanvas show={show} onHide={handleClose} {...props}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Poprawa danych konta</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <UserIndex />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
