import { Offcanvas } from "react-bootstrap";
import { useAppSelector } from "../../../../hooks";
import { selectAgreeData } from "../../../../redux/reducer/agree/agreeSliceCreate";
import Index from "../../../client/form/view/Index";

//---------------------------------------------------------
export default function OffcanvasClient({ show, setShow }: { show: boolean; setShow: any }) {
    //redux
    const { client } = useAppSelector(selectAgreeData);
    //view
    return (
        <Offcanvas
            show={show}
            onHide={() => {
                setShow(false);
            }}
            placement="end"
            scroll={true}
            backdrop={true}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Index client={client} />
            </Offcanvas.Body>
        </Offcanvas>
    );
}
