import { Dropdown } from "react-bootstrap";
import { useAppSelector } from "../../../hooks";
import { selectUserData } from "../../../redux/reducer/user/userSlice";
import PageAgreeAction from "./PageAgreeAction";

//-------------------------------------------------------------------------------------
export default function PageAgreeSetupAction({ obj }: { obj: any }) {
    //redux
    const { user } = useAppSelector(selectUserData);
    //funView
    const chooseCollectionAction = () => {
        switch (obj.status) {
            case "pointActive": {
                return (
                    <>
                        <PageAgreeAction driver="pubAllegro" obj={obj} />
                        <PageAgreeAction driver="salePoint" obj={obj} />
                        {obj.source === "openAgree" && <PageAgreeAction driver="moveAgree" obj={obj} />}
                        {obj.source !== "openAgree" && <PageAgreeAction driver="movePoint" obj={obj} />}
                        <Dropdown.Divider />
                        <PageAgreeAction driver="view" obj={obj} />
                        {user.role === "A" && <PageAgreeAction driver="edit" obj={obj} />}
                        <PageAgreeAction driver="close" obj={obj} />
                        <Dropdown.Divider />
                        <PageAgreeAction driver="history" obj={obj} />
                    </>
                );
            }
            case "allegroActive": {
                return (
                    <>
                        <PageAgreeAction driver="backAllegro" obj={obj} />
                        <Dropdown.Divider />
                        <PageAgreeAction driver="view" obj={obj} />
                        <PageAgreeAction driver="close" obj={obj} />
                        <Dropdown.Divider />
                        <PageAgreeAction driver="history" obj={obj} />
                    </>
                );
            }
            case "pointSold": {
                return (
                    <>
                        <PageAgreeAction driver="view" obj={obj} />
                        <Dropdown.Divider />
                        <PageAgreeAction driver="history" obj={obj} />
                    </>
                );
            }
            case "allegroSold": {
                return (
                    <>
                        <PageAgreeAction driver="view" obj={obj} />
                        <Dropdown.Divider />
                        <PageAgreeAction driver="history" obj={obj} />
                    </>
                );
            }
        }
    };

    //view
    return <>{chooseCollectionAction()}</>;
}
