import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setAllData } from "../../../redux/reducer/agree/agreeSliceCreate";
// import { selectPointData } from "../../../../redux/reducer/point/pointSlice";

import AgreeModalCharge from "../../../comp/modal/agree/agreeP/edit/AgreeModal";
import AgreeModalView from "../../../comp/modal/agree/agreeP/view/AgreeModal";
import AgreeModalExtend from "../../../comp/modal/agree/agreeP/extend/AgreeModal";
import AgreeModalClose from "../../../comp/modal/agree/agreeP/return/AgreeModal";
import AgreeModalHist from "../../../comp/modal/history/HistModal";
import { getHistDataAsync } from "../../../redux/reducer/history/histSlice";
import MoveModal from "../../../comp/modal/agree/moveDepo/toDepo/MoveModal";
import { selectUserData } from "../../../redux/reducer/user/userSlice";
import { setMoveData } from "../../../redux/reducer/moveDepot/moveDepotSlice";

//------------------------------------------------------------------------------------

export default function PageAgreeAction({ driver, obj }: { driver: string; obj: any }) {
    //redux
    const dispatch = useAppDispatch();
    const { access_token } = useAppSelector(selectUserData);

    //hooks
    const [modalEdit, setModalEdit] = useState(false);
    const [modalView, setModalView] = useState(false);
    const [modalExtend, setModalExtend] = useState(false);
    const [modalClose, setModalClose] = useState(false);
    const [modalHist, setModalHist] = useState(false);
    const [modalMove, setModalMove] = useState(false);

    //funView
    const chooseAction = () => {
        switch (driver) {
            case "view": {
                return (
                    <Dropdown.Item
                        onClick={() => {
                            dispatch(setAllData(obj));
                            setModalView(true);
                        }}
                    >
                        Podgląd
                    </Dropdown.Item>
                );
            }
            case "edit": {
                return (
                    <Dropdown.Item
                        onClick={() => {
                            dispatch(setAllData(obj));
                            setModalEdit(true);
                        }}
                    >
                        Edytuj
                    </Dropdown.Item>
                );
            }
            case "extend": {
                return (
                    <Dropdown.Item
                        onClick={() => {
                            // dispatch(setExtendData(obj));
                            dispatch(setAllData(obj));
                            setModalExtend(true);
                        }}
                    >
                        Przedłuż
                    </Dropdown.Item>
                );
            }
            case "close": {
                return (
                    <Dropdown.Item
                        onClick={() => {
                            // dispatch(setReturnData(obj));
                            dispatch(setAllData(obj));
                            setModalClose(true);
                        }}
                    >
                        Wykup
                    </Dropdown.Item>
                );
            }
            case "history": {
                return (
                    <Dropdown.Item
                        onClick={() => {
                            dispatch(
                                getHistDataAsync({
                                    data: {
                                        what: "agreeP",
                                        id_what: obj.id,
                                    },
                                    access_token,
                                })
                            );
                            setModalHist(true);
                        }}
                    >
                        Historia
                    </Dropdown.Item>
                );
            }
            case "depot": {
                return (
                    <Dropdown.Item
                        onClick={() => {
                            dispatch(setMoveData(obj));
                            setModalMove(true);
                        }}
                    >
                        Na magazyn
                    </Dropdown.Item>
                );
            }
            // case "hide": {
            //     return <Dropdown.Item>Usuń</Dropdown.Item>;
            // }
        }
    };

    //view
    return (
        <>
            {chooseAction()}
            {/* show modal edit */}
            <AgreeModalCharge show={modalEdit} onHide={() => setModalEdit(false)} />
            <AgreeModalView show={modalView} onHide={() => setModalView(false)} />
            <AgreeModalExtend show={modalExtend} onHide={() => setModalExtend(false)} />
            <AgreeModalClose show={modalClose} onHide={() => setModalClose(false)} />
            <AgreeModalHist
                show={modalHist}
                onHide={() => setModalHist(false)}
                descript={`Historia umowy wstępnej nr: ${obj.id}`}
            />

            <MoveModal show={modalMove} onHide={() => setModalMove(false)} />
            {/* <ReturnModal show={modalShowReturn} onHide={() => setModalShowReturn(false)} /> */}
        </>
    );
}
