//style
import styles from "../../../../styles/Item.module.css";
import { Col, Row, ListGroup } from "react-bootstrap";
//comp
import ItemAddFileList from "./ItemAddFileList";
import ItemSubmit from "./ItemSubmit";
//formik
import { useFormik } from "formik";
import * as Yup from "yup";
//redux
import { useAppSelector } from "../../../../hooks";
import { selectUserData } from "../../../../redux/reducer/user/userSlice";
import FormItem from "../FormItem";

//-------------------------------------------------------------------
export default function Index({ item, callback, disabled, files, setFiles }: any) {
    //redux
    const { user } = useAppSelector(selectUserData);

    //Formik
    const formik = useFormik({
        initialValues: {
            id: 0,
            id_location: user.id_location,
            name: item.unit.name,
            quality: item.unit.quality,
            amount: item.unit.amount,
            notes: item.unit.notes,
            image: item.unit.image,
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Wymagane").min(3, "Mix 3 znaki").max(50, "Max 50 znaków"),
            quality: Yup.string().required("Wymagane"),
            amount: Yup.number()
                .typeError("Dopuszczalne tylko cyfry")
                .required("Wymagane")
                .min(1, "Min wartość 1")
                .max(100, "Max wartość 100"),
            notes: Yup.string().nullable(),
        }),
        onSubmit: (values) => {
            callback(values);
        },
    });

    //view
    return (
        <Row>
            <Col className="d-grid gap-1">
                <ListGroup className={styles.textRight}>
                    <ListGroup.Item variant="primary">DODAJ NOWY PRZEDMIOT</ListGroup.Item>
                </ListGroup>
                <FormItem formik={formik} disabled={disabled} />

                <ItemAddFileList files={files} setFiles={setFiles} />
                <ItemSubmit formik={formik} />
            </Col>
        </Row>
    );
}
