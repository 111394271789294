import { Col, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toDateRev } from "../../../../../../helper/formatData";
// //redux
import { useAppSelector } from "../../../../../../hooks";
import { selectAgreeData } from "../../../../../../redux/reducer/agree/agreeSliceCreate";
import FormAgree from "../../FormAgree";
import TableAgree from "../../TableAgree";
import { createDecipheriv } from "crypto";

// //----------------------------------------------------

export default function AgreeForm() {
    //redux
    const { proc, term, notes, item, created } = useAppSelector(selectAgreeData);

    //Formik
    const formik = useFormik({
        initialValues: {
            proc: proc,
            term: term,
            notes: notes,
            created: created,
        },
        validationSchema: Yup.object({}),
        onSubmit: () => {},
    });

    //view
    return (
        <Form>
            <Row>
                <Col className="d-grid gap-1 mb-1">
                    <FormAgree formik={formik} item={item} disabled={true} />
                    <TableAgree
                        data={item}
                        proc={formik.values.proc}
                        term={{
                            start: toDateRev(),
                            stop: formik.values.term,
                        }}
                    />
                </Col>
            </Row>
        </Form>
    );
}
