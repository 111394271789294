import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

//ts types interface
export interface intInitState {
    data: string;
}

//init state
const initialState: intInitState = {
    data: "",
};

//create slice obj
export const boardSlice = createSlice({
    name: "boardState",
    initialState,
    reducers: {
        setBoardData: (state, action: PayloadAction<any>) => {
            state.data = action.payload;
        },
    },
});

//create actions obj
export const { setBoardData } = boardSlice.actions;

//get data from store
export const selectBoardData = (state: RootState) => state.boardState.data;

//create reducer
export default boardSlice.reducer;
