import { Modal, Button } from "react-bootstrap";
import HistIndex from "./HistIndex";
//redux
import { initData, selectStatus } from "../../../redux/reducer/history/histSlice";
import { useAppSelector, useAppDispatch } from "../../../hooks";
import { viewSpinner } from "../../../helper/viewSpinner";

//---------------------------------------------------------------
export default function AgreeModal({ show, onHide, descript }: { show: boolean; onHide: any; descript: string }) {
    //redux
    const dispatch = useAppDispatch();
    const statusHist = useAppSelector(selectStatus);

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
            // dialogClassName="modal1095w"
            backdrop="static"
            keyboard={false}
            scrollable={true}
            onExited={() => {
                dispatch(initData());
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">{descript}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{viewSpinner(statusHist, <HistIndex />)}</Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}
