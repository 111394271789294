import { Col, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addDays, toDateRev } from "../../../../../../helper/formatData";
// //redux
import { useAppDispatch, useAppSelector } from "../../../../../../hooks";
import { selectAgreeData } from "../../../../../../redux/reducer/agree/agreeSliceCreate";
import FormAgree from "../../FormAgree";
import TableAgree from "../../TableAgree";
import AgreeSubmit from "./AgreeSubmit";
import { selectUserData } from "../../../../../../redux/reducer/user/userSlice";
import { selectSearchData, updateAgreeDataAsync } from "../../../../../../redux/reducer/agree/agreeSliceP";
import { useState } from "react";

// //----------------------------------------------------

export default function AgreeForm() {
    //redux
    const dispatch = useAppDispatch();
    const { id, proc, notes, item, extend, term } = useAppSelector(selectAgreeData);
    const { user, access_token } = useAppSelector(selectUserData);
    const search = useAppSelector(selectSearchData);

    //hook
    const [copyItem] = useState(item);

    //Formik
    const formik = useFormik({
        initialValues: {
            proc: proc,
            extend: extend,
            term: term,
            notes: notes,
        },
        validationSchema: Yup.object({}),
        onSubmit: () => {},
    });

    //Formik
    const formikUpdate = useFormik({
        initialValues: {
            proc: proc,
            extend: toDateRev(),
            term: toDateRev(),
            notes: notes,
        },
        validationSchema: Yup.object({
            proc: Yup.string().required("Wymagane"),
            term: Yup.date()
                .required("Wymagane")
                .min(addDays(0), "Poza min zakresem")
                .max(addDays(user.role === "U" ? 30 : 365), "Poza max zakresem"),
            notes: Yup.string().nullable(),
        }),
        onSubmit: (values) => {
            //update values
            if (!item.some((x: any) => x.price === 0)) {
                const driver = {
                    ps: "P", //wybrano umowy przedstepne
                    id: id, //wybrano zapis nowej umowy
                };
                const data = {
                    id_location: user.id_location, //id lokalizacji
                    // client: JSON.stringify(client), //dane klienta
                    item: JSON.stringify(item), //dane przedmiotu
                    proc: values.proc, //zaimportowane oprocentowanie
                    extend: values.extend, //start obowiazywania
                    term: values.term, //ustaony termin
                    notes: values.notes, //notatka
                    status: "extend",
                };
                //save agreement
                dispatch(
                    updateAgreeDataAsync({
                        data: {
                            driver,
                            data,
                            search,
                        },
                        access_token,
                    })
                );
            } else {
                alert("Wpisz kwotę produktu.");
            }
        },
    });

    //view
    return (
        <Form>
            <Row>
                <Col className="d-grid gap-1 mb-1">
                    <FormAgree formik={formik} item={copyItem} disabled={true} />
                    <TableAgree
                        data={copyItem}
                        proc={formik.values.proc}
                        term={{
                            start: extend,
                            stop: toDateRev(),
                        }}
                    />

                    <FormAgree formik={formikUpdate} item={item} disabled={false} />
                    <TableAgree
                        data={item}
                        proc={formikUpdate.values.proc}
                        term={{
                            start: toDateRev(),
                            stop: formikUpdate.values.term,
                        }}
                    />

                    <AgreeSubmit formik={formikUpdate} />
                </Col>
            </Row>
        </Form>
    );
}
