//bootstrap
import { Container } from "react-bootstrap";
//comp
import LoginForm from "./LoginForm";
import LoginInfo from "./LoginInfo";
import LoginAdmin from "./admin/LoginUser";
import LoginUser from "./user/LoginUser";
//redux
import { useAppSelector } from "../../../hooks";
import { selectUserData } from "../../../redux/reducer/user/userSlice";

//------------------------------------------------------------------------------------------------------------------

export default function LogIndex() {
    //redux
    const { active, user } = useAppSelector(selectUserData);

    //view
    return (
        <Container fluid>
            <LoginForm />
            {active && <LoginInfo />}
            {active && (user.role === "A" ? <LoginAdmin /> : <LoginUser />)}
        </Container>
    );
}
