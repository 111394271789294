import React from "react";
import { Modal, Button } from "react-bootstrap";
import GetPointIndex from "./GetPointIndex";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { initData } from "../../../../../redux/reducer/movePoint/setPointSlice";
//redux
import { searchAgreeDataAsync, selectSearchData } from "../../../../../redux/reducer/agree/agreeSliceS";
import { selectUserData } from "../../../../../redux/reducer/user/userSlice";

//---------------------------------------------------------------

export default function GetPointModal({ show, onHide }: { show: boolean; onHide: any }) {
    const dispatch = useAppDispatch();
    const searchData = useAppSelector(selectSearchData);
    const { user } = useAppSelector(selectUserData);
    const { access_token } = useAppSelector(selectUserData);

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-retrieve-vcenter"
            centered
            //size="lg"
            dialogClassName="modal1095w"
            backdrop="static"
            keyboard={false}
            scrollable={true}
            onExited={() => {
                dispatch(initData());
                const search = {
                    ...searchData,
                    id_location: user.id_location,
                };
                dispatch(
                    searchAgreeDataAsync({
                        data: {
                            driver: { ps: "S" },
                            search,
                        },
                        access_token,
                    })
                );
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-retrieve-vcenter">
                    Zaakceptuj transfer umów z innych punktów
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <GetPointIndex />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}
