import { FloatingLabel, Form, ListGroup } from "react-bootstrap";
import { BsArrow90DegUp } from "react-icons/bs";
import styles from "../../../../styles/Item.module.css";
import { useAppDispatch } from "../../../../hooks";
import { updateItemData } from "../../../../redux/reducer/agree/agreeSliceCreate";

//--------------------------------------------------
export default function FormAgree({ formik, item, disabled }: { formik: any; item: Array<any>; disabled: boolean }) {
    //redux
    const dispatch = useAppDispatch();
    //onchange
    const onchangePrice = (inx: number, ev: any) => {
        const { value } = ev.target;
        if (!isNaN(value)) {
            dispatch(updateItemData({ inx: inx, price: value }));
        }
    };

    return (
        <>
            <ListGroup className={styles.textRight}>
                <ListGroup.Item variant="primary">DANE ROZLICZENIA</ListGroup.Item>
            </ListGroup>

            {item.map((obj, inx) => {
                return (
                    <FloatingLabel key={inx} label={`Wprowadź cenę dla ID: ${obj.id}`}>
                        <Form.Control
                            id={`price${inx}`}
                            type="text"
                            value={obj.price}
                            disabled={disabled}
                            onChange={(e) => onchangePrice(inx, e)}
                            placeholder={`Wprowadź cenę dla ID: ${obj.id}`}
                        />
                    </FloatingLabel>
                );
            })}

            <FloatingLabel label="Notatka">
                <Form.Control
                    id="notes"
                    as="textarea"
                    disabled={disabled}
                    placeholder="Notatka"
                    {...formik.getFieldProps("notes")}
                />
            </FloatingLabel>
            {formik.touched.notes && formik.errors.notes && (
                <h6 className="validError">
                    <BsArrow90DegUp />
                    {formik.errors.notes}
                </h6>
            )}
        </>
    );
}
