import { Modal, Button } from "react-bootstrap";
import AgreeIndex from "./AgreeIndex";
//redux
import { delSessionData } from "../../../../../redux/reducer/agree/agreeSliceCreate";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { selectStatus } from "../../../../../redux/reducer/agree/agreeSliceP";
import { setStatusUpdate } from "../../../../../redux/reducer/agree/agreeSliceP";
import { setBoardData } from "../../../../../redux/reducer/board/boardSlice";

//---------------------------------------------------------------

export default function AgreeModal({ show, onHide }: { show: boolean; onHide: any }) {
    //redux
    const { statusAgreePupdate } = useAppSelector(selectStatus);
    const dispatch = useAppDispatch();

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            //size="lg"
            dialogClassName="modal1095w"
            backdrop="static"
            keyboard={false}
            scrollable={true}
            onExited={() => {
                if (statusAgreePupdate === "success") {
                    dispatch(delSessionData());
                    dispatch(setStatusUpdate("init"));
                    dispatch(setBoardData("agreeP"));
                }
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Utwórz umowę przedwstępną</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AgreeIndex />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}
