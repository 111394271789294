import { useEffect } from "react";
//style
import styles from "../../../../styles/Item.module.css";
import { Col, Row, ListGroup } from "react-bootstrap";
//comp
import ItemEditFileList from "./ItemEditFileList";
import ItemSubmit from "./ItemSubmit";
//formik
import { useFormik } from "formik";
import * as Yup from "yup";
//redux
import { useAppSelector, useAppDispatch } from "../../../../hooks";
import { selectUserData } from "../../../../redux/reducer/user/userSlice";
import { setStatusUpdate } from "../../../../redux/reducer/item/itemSlice";
import FormItem from "../FormItem";

//-------------------------------------------------------------------
export default function Index({ item, callback, disabled, files, setFiles }: any) {
    //hook
    useEffect(() => {
        dispatch(setStatusUpdate("init"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //redux
    const dispatch = useAppDispatch();

    const { user } = useAppSelector(selectUserData);

    //Formik
    const formik = useFormik({
        initialValues: {
            id: item.id,
            id_location: user.id_location,
            status: item.status,
            name: item.name,
            quality: item.quality,
            amount: item.amount,
            notes: item.notes,
            image: item.image,
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Wymagane").min(3, "Mix 3 znaki").max(50, "Max 50 znaków"),
            quality: Yup.string().required("Wymagane"),
            amount: Yup.number()
                .typeError("Dopuszczalne tylko cyfry")
                .required("Wymagane")
                .min(1, "Min wartość 1")
                .max(100, "Max wartość 100"),
            notes: Yup.string().nullable(),
        }),
        onSubmit: (values) => {
            callback(values);
        },
    });

    //view
    return (
        <Row>
            <Col className="d-grid gap-1">
                <ListGroup className={styles.textRight}>
                    <ListGroup.Item variant="secondary">EDYTUJ PRZEDMIOT</ListGroup.Item>
                </ListGroup>

                <FormItem formik={formik} disabled={disabled} />

                <ItemEditFileList files={files} setFiles={setFiles} />
                <ItemSubmit formik={formik} />
            </Col>
        </Row>
    );
}
