//bootstrap
import { Button, Spinner } from "react-bootstrap";
//redux
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { setStatusUpdateUser, selectStatusUpdateUser } from "../../../../redux/reducer/user/userSlice";

//----------------------------------------------------------

export default function UserSubmit1({ formik }: any) {
    //redux
    const dispatch = useAppDispatch();
    const statusUpdateUser = useAppSelector(selectStatusUpdateUser);

    //function
    const switchButtons = () => {
        switch (statusUpdateUser) {
            case "init": {
                return (
                    <Button
                        variant="primary"
                        size="lg"
                        onClick={() => {
                            formik.handleSubmit();
                        }}
                    >
                        Update user
                    </Button>
                );
            }
            case "load": {
                return (
                    <Button variant="primary" disabled>
                        <Spinner as="span" animation="border" role="status" aria-hidden="true" />
                        <span className="visually-hidden">Loading...</span>
                    </Button>
                );
            }
            case "success": {
                return (
                    <Button variant="success" size="lg" disabled={true}>
                        User added
                    </Button>
                );
            }
            case "unsuccess": {
                return (
                    <Button
                        variant="warning"
                        size="lg"
                        onClick={() => {
                            dispatch(setStatusUpdateUser("init"));
                        }}
                    >
                        Edit unable
                    </Button>
                );
            }
            case "failed": {
                return (
                    <Button
                        variant="danger"
                        size="lg"
                        onClick={() => {
                            dispatch(setStatusUpdateUser("init"));
                        }}
                    >
                        Connecion unable
                    </Button>
                );
            }
            default: {
            }
        }
    };

    //view
    return <>{switchButtons()}</>;
}
