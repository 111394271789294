import { useEffect } from "react";
import { viewSpinner } from "../../helper/viewSpinner";
//redux
import { searchAgreeDataAsync, selectSearchData, selectStatus } from "../../redux/reducer/agree/agreeSliceP";
import {
    selectUserData, //token
} from "../../redux/reducer/user/userSlice";
// import { setPointSelect } from "../../../../redux/reducer/point/pointSlice";
import PageAgreeTab from "./tab/PageAgreeTab";
import PageAgreeNav from "./PageAgreeNav";
import { useAppDispatch, useAppSelector } from "../../hooks";

//--------------------------------------------------------------------

export default function PageAgreeIndex() {
    //redux
    const dispatch = useAppDispatch();
    const { statusAgreeP } = useAppSelector(selectStatus);
    const { access_token } = useAppSelector(selectUserData);
    // const filterData = useAppSelector(selectFilterData);
    const searchData = useAppSelector(selectSearchData);
    const { user } = useAppSelector(selectUserData);

    useEffect(() => {
        // dispatch(resetStatus());
        // dispatch(resetSearch());
        // dispatch(resetFilter());
        // dispatch(setPointSelect(user.id_location));
        const search = {
            ...searchData,
            id_location: user.id_location,
        };
        dispatch(
            searchAgreeDataAsync({
                data: {
                    driver: { ps: "P" },
                    search,
                },
                access_token,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <PageAgreeNav />
            {viewSpinner(statusAgreeP, <PageAgreeTab />)}
        </>
    );
}
