import { Col, Row, Button, Spinner, Form, FloatingLabel } from "react-bootstrap";
import { BsArrow90DegUp } from "react-icons/bs";

import { useFormik } from "formik";
import { toPLN, toDate } from "../../../../../helper/formatData";
import * as Yup from "yup";
//redux
import { selectUserData } from "../../../../../redux/reducer/user/userSlice";
import {
    moveDataAsync,
    selectMovePointData,
    selectStatus,
    setStatus,
} from "../../../../../redux/reducer/movePoint/movePointSlice";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { selectSearchData } from "../../../../../redux/reducer/agree/agreeSliceS";

//-------------------------------------------------------------------

export default function MovePointIndex() {
    //redux
    const dispatch = useAppDispatch();
    const { user, access_token, locations } = useAppSelector(selectUserData);
    const searchData = useAppSelector(selectSearchData);
    const dataPoint = locations.filter((x: any) => x.id !== user.id_location); //not current location
    const status = useAppSelector(selectStatus);
    const obj = useAppSelector(selectMovePointData);

    const price = obj.item.reduce((x: any, y: any) => {
        return x + Number(y.price);
    }, 0);
    //Formik
    const formik = useFormik({
        initialValues: {
            search: {
                ...searchData,
                id_location: user.id_location,
            },
            id_agree: obj.id,
            id_location_source: user.id_location,
            id_location_dest: "",
            price: price,
            notes: "",
        },
        validationSchema: Yup.object({
            id_location_dest: Yup.string().required("Wymagane"),
            notes: Yup.string().nullable(),
        }),
        onSubmit: (values) => {
            dispatch(
                moveDataAsync({
                    values,
                    access_token,
                })
            );
        },
    });
    //switch buttons
    const switchButtons = () => {
        switch (status) {
            case "init": {
                return (
                    <Button
                        variant="info"
                        size="lg"
                        onClick={() => {
                            formik.handleSubmit();
                        }}
                    >
                        Przenieś Umowę
                    </Button>
                );
            }
            case "load": {
                return (
                    <Button variant="primary" disabled>
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        <span className="visually-hidden">Loading...</span>
                    </Button>
                );
            }
            case "success": {
                return (
                    <Button variant="success" size="lg" disabled>
                        Umowa zadeklarowana
                    </Button>
                );
            }
            case "unsuccess": {
                return (
                    <Button
                        variant="warning"
                        size="lg"
                        onClick={() => {
                            dispatch(setStatus("init"));
                        }}
                    >
                        Zapis nieudany
                    </Button>
                );
            }
            case "failed": {
                return (
                    <Button
                        variant="danger"
                        size="lg"
                        onClick={() => {
                            dispatch(setStatus("init"));
                        }}
                    >
                        Połączenie nieudane
                    </Button>
                );
            }
            default: {
            }
        }
    };
    //view
    return (
        <Form>
            <Row>
                <Col className="d-grid gap-1 mt-1">
                    Umowa Nr: {obj.id}, zawarta dnia: {toDate(obj.created)}, pomiędzy: {obj.client.firstname}{" "}
                    {obj.client.surname} a Lombard, na przedmiot/y:{" "}
                    {obj.item
                        .reduce((x: any, y: any) => {
                            return x + ", " + y.name;
                        }, "")
                        .substring(1)}
                    , na kwotę: {toPLN(price)}
                    zł
                </Col>
            </Row>
            <Row>
                <Col className="d-grid gap-1 mt-3">
                    <FloatingLabel label="Lokalizacja">
                        <Form.Select
                            aria-label="Choose location"
                            id="id_location_dest"
                            disabled={status !== "init"}
                            {...formik.getFieldProps("id_location_dest")}
                        >
                            <option value={0}>Wybierz lokalizację</option>
                            {dataPoint.map((obj: any, inx: number) => {
                                return (
                                    <option key={inx} value={obj.id}>
                                        {obj.location}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </FloatingLabel>
                    {formik.touched.id_location_dest && formik.errors.id_location_dest ? (
                        <h6 className="validError">
                            <BsArrow90DegUp />
                            {formik.errors.id_location_dest}
                        </h6>
                    ) : null}
                    <FloatingLabel label="Notatka">
                        <Form.Control
                            id="notes"
                            as="textarea"
                            disabled={status !== "init"}
                            placeholder="Notatka"
                            {...formik.getFieldProps("notes")}
                        />
                    </FloatingLabel>
                    {formik.touched.notes && formik.errors.notes ? (
                        <h6 className="validError">
                            <BsArrow90DegUp />
                            {formik.errors.notes}
                        </h6>
                    ) : null}
                    {switchButtons()}
                </Col>
            </Row>
        </Form>
    );
}
