import { useState } from "react";
import LoginIndex from "./LoginIndex";
import { Nav, Offcanvas } from "react-bootstrap";

//-------------------------------------------------------------------------------

export default function LoginNav({ ...props }) {
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //view
    return (
        <>
            <Nav.Link eventKey="login-link" onClick={handleShow}>
                {props.name}
            </Nav.Link>
            <Offcanvas show={show} onHide={handleClose} {...props}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <LoginIndex />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
