import styles from "../../styles/Client.module.css";
import { Container, Navbar, Nav } from "react-bootstrap";
//comp
import SerchByDate from "../../comp/report/SearchByDate";
import SearchByReport from "../../comp/report/SearchByReport";
import SearchByLocation from "../../comp/report/SearchByLocation";
import PrintByReport from "../../comp/report/PrintByReport";
import SearchByDirection from "../../comp/report/SearchByDirection";

//------------------------------------------------------

export default function PageReportNav() {
    //redux
    // const { user } = useAppSelector(selectUserData);
    //view
    return (
        <Navbar expand="lg" bg="secondary" variant="dark" className={styles.navBarRadius}>
            <Container fluid>
                <Navbar.Brand>RAPORTY</Navbar.Brand>

                <Navbar>
                    <Nav>
                        <PrintByReport />
                    </Nav>
                    <Nav>
                        <SerchByDate />
                    </Nav>
                    <Nav>
                        <SearchByDirection />
                    </Nav>
                    <Nav>
                        <SearchByReport />
                    </Nav>
                    <Nav>
                        <SearchByLocation />
                    </Nav>
                </Navbar>
            </Container>
        </Navbar>
    );
}
