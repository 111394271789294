export const dictionaryItem = (data: string) => {
    switch (data) {
        case "pending":
            return "Oczekujący";
        case "frozen":
            return "Zamrożony";
        case "depot":
            return "Magazyn";
        case "sale":
            return "Sprzedany";
    }
};

export const dictionaryAgreeP = (status: string) => {
    switch (status) {
        //agreeP
        case "open":
            return "Otwarta";
        case "extend":
            return "Przedłużona";
        case "closed":
            return "Wykupiona";
        case "frozen":
            return "Zamrożona";
        case "expired":
            return "Po terminie";
        //AgreeS
        case "pointActive":
            return "Wyst w Punkcie";
        case "allegroActive":
            return "Wyst na Allegro";
        case "pointSold":
            return "Sprz w Punkcie";
        case "allegroSold":
            return "Sprz na Allegro";
    }
};

export const dictionaryAgreeS = (data: string) => {
    switch (data) {
        case "pointActive":
            return "Wyst w Punkcie";
        case "allegroActive":
            return "Wyst na Allegro";
        case "pointSold":
            return "Sprz w Punkcie";
        case "allegroSold":
            return "Sprz na Allegro";
        case "openAgree":
            return "Umowa wstępna";
        case "depotLocal":
            return "Skup";
        case "depotPoint":
            return "Inny Punkt";
    }
};
