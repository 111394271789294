import { useState } from "react";
import styles from "../../../styles/Location.module.css";
import { Form, Row, Col, FloatingLabel, Spinner, Button, ListGroup } from "react-bootstrap";
//redux
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
    selectSetting,
    setPercent,
    selectStatus,
    setStatusPercent,
    saveSetDataAsync,
} from "../../../redux/reducer/setting/settingSlice";
import { selectUserData } from "../../../redux/reducer/user/userSlice";

//----------------------------------------------------------

export default function SetPercent() {
    //hook
    const { percent } = useAppSelector(selectSetting);
    const [per, setPer] = useState(percent);

    //redux
    const dispatch = useAppDispatch();
    const { statusUpdate } = useAppSelector(selectStatus);
    const { access_token } = useAppSelector(selectUserData);

    //new location
    const switchButtons = () => {
        switch (statusUpdate) {
            case "init": {
                return (
                    <Button
                        variant="info"
                        size="lg"
                        onClick={() => {
                            if (per > 0) {
                                dispatch(setPercent(per));
                                dispatch(
                                    saveSetDataAsync({
                                        data: { percent: per },
                                        access_token,
                                    })
                                );
                            } else {
                                alert("Wypełnij pole Procent");
                            }
                        }}
                    >
                        Aktualizuj oprocentowanie
                    </Button>
                );
            }
            case "load": {
                return (
                    <Button variant="primary" disabled>
                        <Spinner as="span" animation="border" role="status" aria-hidden="true" />
                        <span className="visually-hidden">Loading...</span>
                    </Button>
                );
            }
            case "success": {
                return (
                    <Button
                        variant="success"
                        size="lg"
                        onClick={() => {
                            dispatch(setStatusPercent("init"));
                        }}
                    >
                        Procent zaktualizowany
                    </Button>
                );
            }
            case "unsuccess": {
                return (
                    <Button
                        variant="warning"
                        size="lg"
                        onClick={() => {
                            dispatch(setStatusPercent("init"));
                        }}
                    >
                        Zapis nieudany
                    </Button>
                );
            }
            case "failed": {
                return (
                    <Button
                        variant="danger"
                        size="lg"
                        onClick={() => {
                            //dispatch(setStatus("init"));
                        }}
                    >
                        Połączenie nieudane
                    </Button>
                );
            }
            default: {
            }
        }
    };

    return (
        <Form>
            <Row>
                <Col className="d-grid gap-1">
                    <hr />
                    <ListGroup className={styles.textRight}>
                        <ListGroup.Item variant="primary">USTAW MIESIĘCZNE OPROCENTOWANIE</ListGroup.Item>
                    </ListGroup>
                    <FloatingLabel label="Procent">
                        <Form.Control
                            id="percent"
                            type="text"
                            placeholder="Procent"
                            value={per}
                            onChange={(e) => {
                                setPer(Number(e.target.value));
                            }}
                            disabled={statusUpdate === "success"}
                        />
                    </FloatingLabel>
                    {switchButtons()}
                </Col>
            </Row>
        </Form>
    );
}
