import { useState } from "react";
import { Nav, Offcanvas } from "react-bootstrap";
import { GoSettings } from "react-icons/go";
import SetPercent from "./SetPercent";

//----------------------------------------------------------------------
export default function SetNav() {
    //redux

    //hook
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //view
    return (
        <>
            <Nav.Item>
                <Nav.Link
                    eventKey="location-link"
                    onClick={() => {
                        handleShow();
                    }}
                >
                    <GoSettings />
                </Nav.Link>
            </Nav.Item>
            <Offcanvas show={show} onHide={handleClose} placement="end" scroll={true} backdrop={true}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Konfiguracja</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <SetPercent />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
