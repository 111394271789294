import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setClientData } from "../../../redux/reducer/agree/agreeSliceCreate";
import ClientEditNav from "../../../comp/navig/client/edit/ClientEditNav";
import ClientViewNav from "../../../comp/navig/client/view/ClientViewNav";
import { setStatusPesel, setStatusUpdate, setUnitClientData } from "../../../redux/reducer/client/clientSlice";
import { selectUserData } from "../../../redux/reducer/user/userSlice";
import AgreeModalHistory from "../../../comp/modal/history/HistModal";
import { getHistDataAsync } from "../../../redux/reducer/history/histSlice";

//--------------------------------------------------------------------------------------------
export default function PageClientAction({ driver, obj }: { driver: string; obj: any }) {
    //hooks
    //hook
    const [showView, setShowView] = useState(false);
    const handleCloseView = () => setShowView(false);
    const handleShowView = () => setShowView(true);

    const [showEdit, setShowEdit] = useState(false);
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);
    const [modalHistory, setModalHistory] = useState(false);

    //redux
    const dispatch = useAppDispatch();
    const { access_token } = useAppSelector(selectUserData);

    //funView
    const chooseAction = () => {
        switch (driver) {
            case "addToAgreement": {
                return (
                    <Dropdown.Item
                        eventKey="1"
                        onClick={() => {
                            dispatch(setClientData({ obj, status: true }));
                        }}
                    >
                        Dodaj do umowy
                    </Dropdown.Item>
                );
            }
            case "view": {
                return (
                    <Dropdown.Item
                        eventKey="2"
                        onClick={() => {
                            dispatch(setUnitClientData(obj));
                            dispatch(setStatusPesel("success"));
                            handleShowView();
                        }}
                    >
                        Podgląd
                    </Dropdown.Item>
                );
            }
            case "edit": {
                return (
                    <Dropdown.Item
                        eventKey="3"
                        onClick={() => {
                            dispatch(setUnitClientData(obj));
                            dispatch(setStatusUpdate("init"));
                            dispatch(setStatusPesel("success"));
                            handleShowEdit();
                        }}
                    >
                        Edytuj
                    </Dropdown.Item>
                );
            }
            case "history": {
                return (
                    <Dropdown.Item
                        eventKey="4"
                        onClick={() => {
                            dispatch(
                                getHistDataAsync({
                                    data: {
                                        what: "client",
                                        id_what: obj.id,
                                    },
                                    access_token,
                                })
                            );
                            setModalHistory(true);
                        }}
                    >
                        Historia
                    </Dropdown.Item>
                );
            }
        }
    };

    //view
    return (
        <>
            {chooseAction()}
            {/* show modal edit */}
            <ClientViewNav show={showView} handleClose={handleCloseView} />
            <ClientEditNav show={showEdit} handleClose={handleCloseEdit} />
            <AgreeModalHistory
                show={modalHistory}
                onHide={() => setModalHistory(false)}
                descript={`HISTORIA KLIENTA: ${obj.firstname} ${obj.surname}`}
            />
        </>
    );
}
