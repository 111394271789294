import { Form, Row, Col, FloatingLabel } from "react-bootstrap";
//redux
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectUserData } from "../../redux/reducer/user/userSlice";
import {
    resetData,
    searchReportDataAsync,
    selectSearch,
    setSearch,
    selectDirection,
} from "../../redux/reducer/report/reportSlice";

//---------------------------------------------------

export default function SearchByReport() {
    //redux
    const dispatch = useAppDispatch();
    const { access_token, user } = useAppSelector(selectUserData);
    const searchData = useAppSelector(selectSearch);
    const direction = useAppSelector(selectDirection);

    //onchange
    const onChangeStatus = (ev: any) => {
        const { value } = ev.target;
        if (value === "") {
            dispatch(resetData());
        } else {
            const search = { ...searchData, status: value, id_location: user.id_location };
            dispatch(setSearch(search));
            dispatch(searchReportDataAsync({ data: search, access_token }));
        }
    };

    const viewMenu = () => {
        switch (direction) {
            //wydatki
            case "spend": {
                return (
                    <>
                        <option value=""></option>
                        <option value="umwswy">UMOWY wstępne zawarte</option>
                        <option value="umskwy">UMOWY skupu zawarte</option>
                    </>
                );
            }
            //dochody
            case "income": {
                return (
                    <>
                        <option value=""></option>
                        <option value="umwsdo">UMOWY wstępne wykupione</option>
                        <option value="umwsprdo">UMOWY wstępne przedłużone</option>
                        <option value="umskdo">UMOWY skupu sprzedane</option>
                    </>
                );
            }
            //wyniki
            case "mix": {
                return (
                    <>
                        <option value=""></option>
                        <option value="ummix">Zestawienie zbiorcze</option>
                    </>
                );
            }
            case "": {
                return <option value=""></option>;
            }
        }
    };

    return (
        <Form>
            <Row className="g-0">
                <Col sm>
                    <FloatingLabel label="Umowy">
                        <Form.Select id="active" onChange={onChangeStatus} style={{ minWidth: "100px" }}>
                            {viewMenu()}
                        </Form.Select>
                    </FloatingLabel>
                </Col>
            </Row>
        </Form>
    );
}
