//css
import styles from "../../../../../styles/Login.module.css";
//bootstrap
import { ListGroup, Row, Col, Form, FloatingLabel } from "react-bootstrap";
//formik
import { useFormik } from "formik";
import * as Yup from "yup";
import { BsArrow90DegUp } from "react-icons/bs";
//redux
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import {
    updateUserDataAsync,
    selectStatusUpdateUser,
    selectUserData,
    selectUserUserData,
    user,
} from "../../../../../redux/reducer/user/userSlice";
import UserSubmit from "../UserSubmit1";

//--------------------------------------------------------------

export default function UserForm() {
    //redux
    const dispatch = useAppDispatch();
    const data: user = useAppSelector(selectUserUserData);
    const statusUpdateUser = useAppSelector(selectStatusUpdateUser);
    const { access_token } = useAppSelector(selectUserData);

    //formik
    const formik = useFormik({
        initialValues: {
            id: data.id,
            name: data.name,
            phone: data.phone,
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Required").min(3, "Min 3 chars").max(100, "Max 100 chars"),
            phone: Yup.string()
                .matches(/^[\s\d]*$/, "Only numbers")
                // .required("Required")
                .min(10, "Min 10 chars")
                .max(15, "Max 15 chars"),
        }),
        onSubmit: (values) => {
            //update values
            dispatch(updateUserDataAsync({ data: values, access_token }));
        },
    });

    //view
    return (
        <Form>
            <Row>
                <Col className="d-grid gap-1 mb-1">
                    <ListGroup className={styles.textRight}>
                        <ListGroup.Item variant="secondary">EDIT USER</ListGroup.Item>
                    </ListGroup>
                    <FloatingLabel label="Full name">
                        <Form.Control
                            id="name"
                            type="text"
                            placeholder="Full name"
                            maxLength={100}
                            disabled={statusUpdateUser !== "init"}
                            {...formik.getFieldProps("name")}
                        />
                    </FloatingLabel>
                    {formik.touched.name && formik.errors.name && (
                        <h6 className="validError">
                            <BsArrow90DegUp />
                            {formik.errors.name}
                        </h6>
                    )}

                    <FloatingLabel label="Phone">
                        <Form.Control
                            id="phone"
                            type="text"
                            placeholder="Phone"
                            maxLength={15}
                            disabled={statusUpdateUser !== "init"}
                            {...formik.getFieldProps("phone")}
                        />
                    </FloatingLabel>
                    {formik.touched.phone && formik.errors.phone && (
                        <h6 className="validError">
                            <BsArrow90DegUp />
                            {formik.errors.phone}
                        </h6>
                    )}

                    <UserSubmit formik={formik} />
                </Col>
            </Row>
        </Form>
    );
}
