import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { fetchApi } from "../../../helper/fetchApi";

interface initInitState {
    data: Array<any>;
    status: string;
}

//init state
const initialState: initInitState = {
    data: [],
    status: "init",
};

export const getDataAsync = createAsyncThunk("/getPointState/getDataAsync", async (data: any, { rejectWithValue }) => {
    try {
        const response = await fetchApi("/agree/getPoint/", data.id, data.access_token);
        return response;
    } catch (err: any) {
        return rejectWithValue(err.response.data);
    }
});

//create slice obj
export const getPointSlice = createSlice({
    name: "getPointState",
    initialState,
    reducers: {
        setStatus: (state, action: PayloadAction<any>) => {
            state.status = action.payload;
        },
        initData: (state) => {
            state.data = initialState.data;
            state.status = initialState.status;
        },
        setMovePointData: (state, action: PayloadAction<any>) => {
            state.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            //ADD PICTURE
            .addCase(getDataAsync.pending, (state) => {
                state.status = "load";
            })
            .addCase(getDataAsync.fulfilled, (state, action) => {
                //success connection?
                try {
                    //success login?
                    if (action.payload.active) {
                        const data = action.payload.data;
                        state.data = data;
                        // state.data = data.map((x: any) => {
                        //     return { ...x, id: Number(x.id) };
                        // });
                        state.status = "success";
                    } else {
                        state.status = "unsuccess";
                        state.data = initialState.data;
                    }
                } catch (err) {
                    state.status = "failed";
                    console.log("TypeError: JsonError when attempting resolve data.");
                }
            })
            .addCase(getDataAsync.rejected, (state) => {
                state.status = "failed";
                console.log("TypeError: NetworkError when attempting to fetch resource.");
            });
    },
});

//create actions obj
export const { initData, setMovePointData, setStatus } = getPointSlice.actions;

//get data from store
export const selectStatus = (state: RootState) => state.getPointState.status;
export const selectPointData = (state: RootState) => state.getPointState.data;

//create reducer
export default getPointSlice.reducer;
