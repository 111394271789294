import styles from "../../styles/Client.module.css";
import { Container, Navbar, Nav } from "react-bootstrap";
//comp
import SerchByDate from "../../comp/client/SearchByDate";
import SearchByText from "../../comp/client/SearchByText";
import PaginPage from "../../comp/client/PaginPage";
import AddButton from "../../comp/client/AddButton";

//------------------------------------------------------

export default function PageClientNav() {
    //view
    return (
        <Navbar expand="lg" bg="secondary" variant="dark" className={styles.navBarRadius}>
            <Container fluid>
                <Navbar.Brand>KLIENCI</Navbar.Brand>

                <Navbar>
                    <Nav>
                        <AddButton />
                    </Nav>
                    <Nav>
                        <PaginPage />
                    </Nav>
                    <Nav>
                        <SearchByText />
                    </Nav>
                    <Nav>
                        <SerchByDate />
                    </Nav>
                </Navbar>
            </Container>
        </Navbar>
    );
}
