import { Container, Row, Col } from "react-bootstrap";
//pages
import PageUserIndex from "./pages/users/PageUserIndex";
import PageClientIndex from "./pages/clients/PageClientIndex";
import PageItemIndex from "./pages/items/PageItemIndex";

//comp
import MainNav from "./comp/navig/MainNav";
//redux
import { useAppSelector } from "./hooks";
import { selectBoardData } from "./redux/reducer/board/boardSlice";
//css
import "../App.css";
import PageAgreeIndexP from "./pages/agreeP/PageAgreeIndex";
import PageAgreeIndexS from "./pages/agreeS/PageAgreeIndex";
import PageReportIndex from "./pages/report/PageReportIndex";

//---------------------------------------------------------
function App() {
    //redux
    const view: string = useAppSelector(selectBoardData);

    const viewMain = () => {
        switch (view) {
            case "users": {
                return <PageUserIndex />;
            }
            case "client": {
                return <PageClientIndex />;
            }
            case "item": {
                return <PageItemIndex />;
            }
            case "agreeP": {
                return <PageAgreeIndexP />;
            }
            case "agreeS": {
                return <PageAgreeIndexS />;
            }
            case "report": {
                return <PageReportIndex />;
            }
            default: {
                return <></>;
            }
        }
    };

    //view
    return (
        <Container fluid>
            <Row>
                <Col>
                    <MainNav />
                </Col>
            </Row>
            <Row className="dbClientImage">
                <Col style={{ marginTop: "70px" }}>{viewMain()}</Col>
            </Row>
        </Container>
    );
}

export default App;
