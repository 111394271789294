//bootstrap
import { Container } from "react-bootstrap";
//nav
import UserForm from "./UserForm";

//-------------------------------------------------------

export default function UserIndex() {
    return (
        <Container fluid>
            <UserForm />
        </Container>
    );
}
