import { useState } from "react";
import { Offcanvas, Button } from "react-bootstrap";
//comp
import UserIndex from "../UserIndex";
//redux
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import {
    setUserUserData,
    setStatusUpdateUser,
    setStatusUpdatePwd,
    selectUserData,
} from "../../../../../redux/reducer/user/userSlice";

//-------------------------------------------------------------------------------

export default function UserNav({ ...props }) {
    //redux
    const dispatch = useAppDispatch();
    const { user } = useAppSelector(selectUserData);

    //hook
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //view
    return (
        <>
            <Button
                variant="success"
                size="lg"
                onClick={() => {
                    dispatch(setUserUserData({ id: user.id, name: user.name, phone: user.phone }));
                    dispatch(setStatusUpdateUser("init"));
                    dispatch(setStatusUpdatePwd("init"));
                    handleShow();
                }}
            >
                Update account
            </Button>
            <Offcanvas show={show} onHide={handleClose} {...props}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Editing user</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <UserIndex />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
