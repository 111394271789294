import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { fetchApi } from "../../../helper/fetchApi";

//ts types interface user for update
export interface user {
    id: number;
    id_location?: number;
    name: string;
    phone: number;
    role?: string;
    active?: string;
}
//ts types interface global
export interface intInitState {
    data: {
        user: any;
        users: Array<any>;
        locations: Array<any>;
        access_token: string;
        active: boolean;
    };
    user: user; //store for update
    statusLogin: string; //status logowania
    statusRegister: string; //status rejestracji nowego usera
    statusUpdateUser: string; //status update danych usera
    statusUpdatePwd: string; //status update hasla
    statusEmail: string; //status weryfikacji email
    statusToken: string; //status update tokena
    statusAddLocation: string; //status update lokalizacji
    statusUpdateLocation: Array<any>; //status update lokalizacji
}

//init state
const initialState: intInitState = {
    data: {
        user: {},
        users: [],
        locations: [],
        access_token: "Basic dXNlcnN1cGVyOndZTFg1bVAkbVNFU3dZTFg1bVAkbVNFUw==",
        active: false,
    },
    user: {
        id: 0,
        id_location: 0,
        name: "",
        phone: 0,
        role: "",
        active: "",
    },
    statusLogin: "init",
    statusRegister: "init",
    statusUpdateUser: "init",
    statusUpdatePwd: "init",
    statusEmail: "init",
    statusToken: "init",
    statusAddLocation: "init",
    statusUpdateLocation: [],
};

//LOGIN USER
//--------------------------------------------------------------------------------------------------
export const loginDataAsync = createAsyncThunk(
    "/userState/loginDataAsync",
    async (data: { data: { email: string; pwd: string }; access_token: string }, thunkApi) => {
        try {
            const response = await fetchApi("/auth/login", data.data, data.access_token);
            return response;
        } catch (err: any) {
            return thunkApi.rejectWithValue(err.response.data);
        }
    }
);

//CHECK EMAIL
//--------------------------------------------------------------------------------------------------
export const checkEmailDataAsync = createAsyncThunk(
    "/userState/checkEmailDataAsync",
    async (data: { data: { email: string }; access_token: string }, thunkApi) => {
        try {
            const response = await fetchApi(`/auth/ckemail`, data.data, data.access_token);
            return response;
        } catch (err: any) {
            return thunkApi.rejectWithValue(err.response.data);
        }
    }
);

//REGISTER USER
//--------------------------------------------------------------------------------------------------
export const registerDataAsync = createAsyncThunk(
    "/userState/registerDataAsync",
    async (
        data: {
            data: {
                id_location: number;
                name: string;
                email: string;
                phone: string;
                pwd: string;
            };
            access_token: string;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await fetchApi("/auth/register", data.data, data.access_token);
            return response;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

//UPDATE USER
//--------------------------------------------------------------------------------------------------
export const updateUserDataAsync = createAsyncThunk(
    "/userState/updateUserDataAsync",
    async (data: { data: user; access_token: string }, { rejectWithValue }) => {
        try {
            const response = await fetchApi("/auth/upuser", data.data, data.access_token);
            return response;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

//UPDATE PASSWORD
//--------------------------------------------------------------------------------------------------
export const updatePwdDataAsync = createAsyncThunk(
    "/userState/updatePwdDataAsync",
    async (data: { data: { id: number; pwd: string }; access_token: string }, { rejectWithValue }) => {
        try {
            const response = await fetchApi("/auth/uppwd", data.data, data.access_token);
            return response;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

//UPDATE TOKEN
//--------------------------------------------------------------------------------------------------
export const updateTokenDataAsync = createAsyncThunk(
    "/userState/updateTokenDataAsync",
    async (data: { data: { id: number; email: string }; access_token: string }, { rejectWithValue }) => {
        try {
            const response = await fetchApi("/auth/uptoken", data.data, data.access_token);
            return response;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

//ADD LOCATION
//--------------------------------------------------------------------------------------------------
export const addLocatonDataAsync = createAsyncThunk(
    "/userState/addLocatonDataAsync",
    async (data: { data: { id: number; location: string }; access_token: string }, { rejectWithValue }) => {
        try {
            const response = await fetchApi("/auth/uplocat", data.data, data.access_token);
            return response;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

//UPDATE LOCATION
//--------------------------------------------------------------------------------------------------
export const updateLocatonDataAsync = createAsyncThunk(
    "/userState/updateLocatonDataAsync",
    async (data: { data: { id: number; location: string }; access_token: string }, { rejectWithValue }) => {
        try {
            const response = await fetchApi("/auth/uplocat", data.data, data.access_token);
            return response;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

//create slice obj
export const userSlice = createSlice({
    name: "userState",
    initialState,
    reducers: {
        setUserData: (state, action: PayloadAction<any>) => {
            state.data = action.payload;
        },
        setUserUserData: (state, action: PayloadAction<any>) => {
            state.user = action.payload;
        },
        setLocationData: (state, action: PayloadAction<any>) => {
            const inx = action.payload.inx;
            state.data.locations[inx].location = action.payload.location;
        },
        setStatusLogin: (state, action: PayloadAction<any>) => {
            state.statusLogin = action.payload;
        },
        setStatusRegister: (state, action: PayloadAction<any>) => {
            state.statusRegister = action.payload;
        },
        setStatusUpdateUser: (state, action: PayloadAction<any>) => {
            state.statusUpdateUser = action.payload;
        },
        setStatusUpdatePwd: (state, action: PayloadAction<any>) => {
            state.statusUpdatePwd = action.payload;
        },
        setStatusEmail: (state, action: PayloadAction<any>) => {
            state.statusEmail = action.payload;
        },
        setStatusToken: (state, action: PayloadAction<any>) => {
            state.statusToken = action.payload;
        },
        setStatusAddLocation: (state, action: PayloadAction<any>) => {
            state.statusAddLocation = action.payload;
        },
        setStatusUpdateLocation: (state, action: PayloadAction<any>) => {
            state.statusUpdateLocation[action.payload] = "danger";
        },
    },
    extraReducers: (builder) => {
        builder

            //LOGIN USER
            //--------------------------------------------------------------------------------------------------
            .addCase(loginDataAsync.pending, (state) => {
                state.statusLogin = "load";
            })
            .addCase(loginDataAsync.fulfilled, (state, action) => {
                try {
                    if (action.payload.active) {
                        //full update
                        const data = action.payload;
                        state.data.user = data.user;
                        state.data.users = data.users;
                        state.data.locations = data.locations;
                        state.data.access_token = data.access_token;
                        state.data.active = data.active;

                        state.statusUpdateLocation = action.payload.locations.map(() => "outline-primary");
                        state.statusLogin = "success";
                        state.statusToken = "success";
                    } else {
                        state.statusLogin = "unsuccess";
                    }
                } catch (err) {
                    state.statusLogin = "failed";
                    console.log("TypeError: JsonError when attempting resolve data.");
                }
            })
            .addCase(loginDataAsync.rejected, (state) => {
                state.statusLogin = "failed";
                console.log("TypeError: NetworkError when attempting to fetch resource.");
            })

            //REGISTER USER
            .addCase(registerDataAsync.pending, (state) => {
                state.statusRegister = "load";
            })
            .addCase(registerDataAsync.fulfilled, (state, action) => {
                try {
                    if (action.payload.active) {
                        state.statusRegister = "success";
                        state.data.users = action.payload.data;
                    } else {
                        state.statusRegister = "unsuccess";
                    }
                } catch (err) {
                    state.statusRegister = "failed";
                    console.log("TypeError: JsonError when attempting resolve data.");
                }
            })
            .addCase(registerDataAsync.rejected, (state) => {
                state.statusRegister = "failed";
                console.log("TypeError: NetworkError when attempting to fetch resource.");
            })

            //UPDATE USER
            //--------------------------------------------------------------------------------------------------
            .addCase(updateUserDataAsync.pending, (state) => {
                state.statusUpdateUser = "load";
            })
            .addCase(updateUserDataAsync.fulfilled, (state, action) => {
                //success connection?
                try {
                    //success login?
                    if (action.payload.active) {
                        state.statusUpdateUser = "success";
                        state.data.users = action.payload.data;
                    } else {
                        state.statusUpdateUser = "unsuccess";
                    }
                } catch (err) {
                    state.statusUpdateUser = "failed";
                    console.log("TypeError: JsonError when attempting resolve data.");
                }
            })
            .addCase(updateUserDataAsync.rejected, (state) => {
                state.statusUpdateUser = "failed";
                console.log("TypeError: NetworkError when attempting to fetch resource.");
            })

            //UPDATE PWD
            //--------------------------------------------------------------------------------------------------
            .addCase(updatePwdDataAsync.pending, (state) => {
                state.statusUpdatePwd = "load";
            })
            .addCase(updatePwdDataAsync.fulfilled, (state, action) => {
                //success connection?
                try {
                    //success login?
                    if (action.payload.active) {
                        state.statusUpdatePwd = "success";
                    } else {
                        state.statusUpdatePwd = "unsuccess";
                    }
                } catch (err) {
                    state.statusUpdatePwd = "failed";
                    console.log("TypeError: JsonError when attempting resolve data.");
                }
            })
            .addCase(updatePwdDataAsync.rejected, (state) => {
                state.statusUpdatePwd = "failed";
                console.log("TypeError: NetworkError when attempting to fetch resource.");
            })

            //CHECK EMAIL
            //--------------------------------------------------------------------------------------------------
            .addCase(checkEmailDataAsync.pending, (state) => {
                state.statusEmail = "load";
            })
            .addCase(checkEmailDataAsync.fulfilled, (state, action) => {
                //success connection?
                try {
                    //success login?
                    if (action.payload.active) {
                        state.statusEmail = "free";
                    } else {
                        state.statusEmail = "used";
                    }
                } catch (err) {
                    state.statusEmail = "failed";
                    console.log("TypeError: JsonError when attempting resolve data.");
                }
            })
            .addCase(checkEmailDataAsync.rejected, (state) => {
                state.statusEmail = "failed";
                console.log("TypeError: NetworkError when attempting to fetch resource.");
            })

            // UPDATE TOKEN
            // --------------------------------------------------------------------------------------------------
            .addCase(updateTokenDataAsync.pending, (state) => {
                state.statusToken = "load";
            })
            .addCase(updateTokenDataAsync.fulfilled, (state, action) => {
                //success connection?
                try {
                    //success login?
                    if (action.payload.active) {
                        state.statusToken = "success";
                        state.data.access_token = action.payload.data;
                    } else {
                        state.statusToken = "unsuccess";
                    }
                } catch (err) {
                    state.statusToken = "failed";
                    console.log("TypeError: JsonError when attempting resolve data.");
                }
            })
            .addCase(updateTokenDataAsync.rejected, (state) => {
                state.statusToken = "failed";
                console.log("TypeError: NetworkError when attempting to fetch resource.");
            })

            // ADD LOCATION
            // --------------------------------------------------------------------------------------------------
            .addCase(addLocatonDataAsync.pending, (state) => {
                state.statusAddLocation = "load";
            })
            .addCase(addLocatonDataAsync.fulfilled, (state, action) => {
                //success connection?
                try {
                    //success login?
                    if (action.payload.active) {
                        state.data.locations = action.payload.data;
                        state.statusUpdateLocation = action.payload.data.map(() => "outline-primary");
                        state.statusAddLocation = "success";
                    } else {
                        state.statusAddLocation = "unsuccess";
                    }
                } catch (err) {
                    state.statusAddLocation = "failed";
                    console.log("TypeError: JsonError when attempting resolve data.");
                }
            })
            .addCase(addLocatonDataAsync.rejected, (state) => {
                state.statusAddLocation = "failed";
                console.log("TypeError: NetworkError when attempting to fetch resource.");
            })

            // UPDATE LOCATION
            // --------------------------------------------------------------------------------------------------
            .addCase(updateLocatonDataAsync.pending, (state) => {})
            .addCase(updateLocatonDataAsync.fulfilled, (state, action) => {
                //success connection?
                try {
                    //success login?
                    if (action.payload.active) {
                        state.data.locations = action.payload.data;
                        state.statusUpdateLocation = state.statusUpdateLocation.map((x: string) =>
                            x === "danger" ? "success" : "outline-primary"
                        );
                    } else {
                        // state.statusAddLocation = "unsuccess";
                    }
                } catch (err) {
                    // state.statusAddLocation = "failed";
                    console.log("TypeError: JsonError when attempting resolve data.");
                }
            })
            .addCase(updateLocatonDataAsync.rejected, (state) => {
                // state.statusAddLocation = "failed";
                console.log("TypeError: NetworkError when attempting to fetch resource.");
            });
    },
});

//create actions obj
export const {
    setUserData,
    setUserUserData,
    setLocationData,
    setStatusLogin,
    setStatusRegister,
    setStatusUpdateUser,
    setStatusUpdatePwd,
    setStatusEmail,
    setStatusToken,
    setStatusAddLocation,
    setStatusUpdateLocation,
} = userSlice.actions;

//get data from store
export const selectUserData = (state: RootState) => state.userState.data;
export const selectUserUserData = (state: RootState) => state.userState.user;
export const selectStatusLogin = (state: RootState) => state.userState.statusLogin;
export const selectStatusRegister = (state: RootState) => state.userState.statusRegister;
export const selectStatusUpdateUser = (state: RootState) => state.userState.statusUpdateUser;
export const selectStatusUpdatePwd = (state: RootState) => state.userState.statusUpdatePwd;
export const selectStatusEmail = (state: RootState) => state.userState.statusEmail;
export const selectStatusToken = (state: RootState) => state.userState.statusToken;
export const selectStatusAddLocation = (state: RootState) => state.userState.statusAddLocation;
export const selectStatusUpdateLocation = (state: RootState) => state.userState.statusUpdateLocation;

//create reducer
export default userSlice.reducer;
