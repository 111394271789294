import { Fragment } from "react";
import { Offcanvas } from "react-bootstrap";
import { useAppSelector } from "../../../../hooks";
import { selectAgreeData } from "../../../../redux/reducer/agree/agreeSliceCreate";
import IndexView from "../../../agree/US/form/edit/item/IndexView";
// import IndexDelete from "../../../item/form/viewDelete/IndexDelete";

//---------------------------------------------------------
export default function OffcanvasItem({ show, setShow }: { show: boolean; setShow: any }) {
    //redux
    const { item } = useAppSelector(selectAgreeData);

    //view
    return (
        <Offcanvas
            show={show}
            onHide={() => {
                setShow(false);
            }}
            placement="end"
            scroll={true}
            backdrop={true}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {item.map((obj, inx) => (
                    <Fragment key={inx}>
                        <IndexView item={obj} files={[]} />
                    </Fragment>
                ))}
            </Offcanvas.Body>
        </Offcanvas>
    );
}
