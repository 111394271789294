import { Col, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addDays, toDate, toDateRev } from "../../../../../../helper/formatData";
import TableAgree from "../../TableAgree";
import FormAgree from "../../FormAgree";
import AgreeSubmit from "./AgreeSubmit";
// //redux
import { selectUserData } from "../../../../../../redux/reducer/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks";
import { selectAgreeData } from "../../../../../../redux/reducer/agree/agreeSliceCreate";
// import { selectSearchData, setFilter } from "../../../../redux/reducer/agree/agreeP/agreeSearchSliceP";
// import { selectBoardData, setBoardData } from "../../../../../redux/reducer/board/boardSlice";
import { selectSetting } from "../../../../../../redux/reducer/setting/settingSlice";
import { selectSearchData, updateAgreeDataAsync } from "../../../../../../redux/reducer/agree/agreeSliceP";
import { selectStatus } from "../../../../../../redux/reducer/agree/agreeSliceP";

//----------------------------------------------------
export default function AgreeForm() {
    //     //redux
    const dispatch = useAppDispatch();
    const { client, item } = useAppSelector(selectAgreeData);
    const { statusAgreePupdate } = useAppSelector(selectStatus);
    const { percent } = useAppSelector(selectSetting);
    const { user, access_token } = useAppSelector(selectUserData);
    const search = useAppSelector(selectSearchData);

    //     //Formik
    const formik = useFormik({
        initialValues: {
            proc: percent,
            extend: toDateRev(),
            term: toDateRev(),
            notes: "",
            // created: toDateRev(),
        },
        validationSchema: Yup.object({
            proc: Yup.string().required("Wymagane"),
            term: Yup.date()
                .required("Wymagane")
                .min(addDays(0), "Poza min zakresem")
                .max(addDays(user.role === "U" ? 30 : 365), "Poza max zakresem"),
            notes: Yup.string().nullable(),
        }),
        onSubmit: (values) => {
            //update values
            if (!item.some((x: any) => x.price === 0)) {
                const driver = {
                    ps: "P", //wybrano umowy przedstepne
                    id: 0, //wybrano zapis nowej umowy
                };
                const data = {
                    id_location: user.id_location, //id lokalizacji
                    client: JSON.stringify(client), //dane klienta
                    item: JSON.stringify(item), //dane przedmiotu
                    proc: values.proc, //zaimportowane oprocentowanie
                    term: values.term, //ustaony termin
                    notes: values.notes, //notatka
                    status: "open",
                };
                //save agreement
                dispatch(
                    updateAgreeDataAsync({
                        data: {
                            driver,
                            data,
                            search,
                        },
                        access_token,
                    })
                );
            } else {
                alert("Wpisz kwotę produktu.");
            }
        },
    });

    //view
    return (
        <Form>
            <Row>
                <Col className="d-grid gap-1 mb-1">
                    <FormAgree formik={formik} item={item} disabled={statusAgreePupdate === "success"} />
                    <TableAgree
                        data={item}
                        proc={formik.values.proc}
                        term={{
                            start: toDateRev(),
                            stop: formik.values.term,
                        }}
                    />
                    <AgreeSubmit formik={formik} />
                </Col>
            </Row>
        </Form>
    );
}
