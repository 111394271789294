import { useEffect } from "react";
import { viewSpinner } from "../../helper/viewSpinner";
import PageReportNav from "./PageReportNav";
import { useAppDispatch, useAppSelector } from "../../hooks";

import { resetData, selectSearch, selectStatus, setDirection } from "../../redux/reducer/report/reportSlice";
import PageReportTab from "./tab/PageReportTab";
import PageReportMixTab from "./tab/PageReportMixTab";

//--------------------------------------------------------------------

export default function PageReportIndex() {
    //redux
    const dispatch = useAppDispatch();
    const status = useAppSelector(selectStatus);
    const search = useAppSelector(selectSearch);

    //hooks
    useEffect(() => {
        dispatch(resetData());
        dispatch(setDirection(""));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <PageReportNav />
            {search.status.includes("mix")
                ? status !== "init" && viewSpinner(status, <PageReportMixTab />)
                : status !== "init" && viewSpinner(status, <PageReportTab />)}
        </>
    );
}
