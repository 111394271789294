import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { fetchApi } from "../../../helper/fetchApi";

interface initInitState {
    data: any;
    status: string;
}

//init state
const initialState: initInitState = {
    data: {},
    status: "init",
};

export const moveDataAsync = createAsyncThunk(
    "/moveDepotState/moveDataAsync",
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await fetchApi(data.link, data.data, data.access_token);
            return response;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

//create slice obj
export const moveDepotSlice = createSlice({
    name: "moveDepotState",
    initialState,
    reducers: {
        setStatus: (state, action: PayloadAction<any>) => {
            state.status = action.payload;
        },
        initData: (state) => {
            state.data = initialState.data;
            state.status = initialState.status;
        },
        setMoveData: (state, action: PayloadAction<any>) => {
            state.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            //ADD PICTURE
            .addCase(moveDataAsync.pending, (state) => {
                state.status = "load";
            })
            .addCase(moveDataAsync.fulfilled, (state, action) => {
                //success connection?
                try {
                    //success login?
                    if (action.payload.active) {
                        state.status = "success";
                    } else {
                        state.status = "unsuccess";
                    }
                } catch (err) {
                    state.status = "failed";
                    console.log("TypeError: JsonError when attempting resolve data.");
                }
            })
            .addCase(moveDataAsync.rejected, (state) => {
                state.status = "failed";
                console.log("TypeError: NetworkError when attempting to fetch resource.");
            });
    },
});

//create actions obj
export const { initData, setMoveData, setStatus } = moveDepotSlice.actions;

//get data from store
export const selectStatus = (state: RootState) => state.moveDepotState.status;
export const selectMoveData = (state: RootState) => state.moveDepotState.data;

//create reducer
export default moveDepotSlice.reducer;
