import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { fetchApi } from "../../../helper/fetchApi";
import { toDateRev } from "../../../helper/formatData";

//ts types interface
export interface intClient {
    id: number;
    id_location?: number;
    firstname: string;
    surname: string;
    nrDow: string;
    pesel: string;
    address: string;
    phone: string;
    notes: string;
    active?: string;
}
export interface intSearch {
    start: string;
    stop: string;
    text: string;
    interval: number;
}
export interface intInitState {
    data: {
        data: {
            amount: number;
            data: Array<any>;
        };
        client: intClient;
    };
    search: intSearch;
    status: {
        statusClient: string; //status pobierania danych klientow
        statusRegister: string; //satus dodawania klienta
        statusUpdate: string; //status poprawy danych klienta
        statusPesel: string; //status weryfikacji pesel
    };
    page: number;
}

//init state
export const initialState: intInitState = {
    data: {
        data: {
            amount: 0,
            data: [],
        },
        client: {
            id: 0,
            id_location: 0,
            firstname: "",
            surname: "",
            nrDow: "",
            pesel: "",
            address: "",
            phone: "",
            notes: "",
            active: "A",
        },
    },
    search: {
        start: "2022-06-01",
        stop: toDateRev(),
        text: "",
        interval: 0,
    },
    status: {
        statusClient: "init",
        statusRegister: "init",
        statusUpdate: "init",
        statusPesel: "init",
    },
    page: 0,
};

//READ CLIENT
//--------------------------------------------------------------------------------------------------
export const getClientDataAsync = createAsyncThunk(
    "/clientState/getClientDataAsync",
    async (data: { data: intSearch; access_token: string }, { rejectWithValue }) => {
        try {
            const response = await fetchApi("/clnt/rows", data.data, data.access_token);
            return response;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

//UPDATE/INSERT CLIENT
//--------------------------------------------------------------------------------------------------
export const updateClientDataAsync = createAsyncThunk(
    "/clientState/updateClientDataAsync",
    async (data: { data: { data: intClient; search: intSearch }; access_token: string }, { rejectWithValue }) => {
        try {
            const response = await fetchApi("/clnt/update", data.data, data.access_token);
            return response;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

//UPDATE USERS
//--------------------------------------------------------------------------------------------------
export const peselDataAsync = createAsyncThunk(
    "/clientState/peselDataAsync",
    async (data: { data: { pesel: string }; access_token: string }, { rejectWithValue }) => {
        try {
            const response = await fetchApi("/clnt/pesel", data.data, data.access_token);
            return response;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

//create slice obj
export const clientSlice = createSlice({
    name: "clientState",
    initialState,
    reducers: {
        setInitClientData: (state) => {
            state.data.data = initialState.data.data;
            state.data.client = initialState.data.client;
            state.search = initialState.search;
            state.status = initialState.status;
            state.page = 0;
        },
        setUnitClientData: (state, action: PayloadAction<any>) => {
            state.data.client = action.payload;
        },
        setStatusUpdate: (state, action: PayloadAction<any>) => {
            state.status.statusUpdate = action.payload;
        },
        setSearchData: (state, action: PayloadAction<any>) => {
            state.search = action.payload;
        },
        setPageData: (state, action: PayloadAction<any>) => {
            state.page = action.payload;
        },
        setStatusPesel: (state, action: PayloadAction<any>) => {
            state.status.statusPesel = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder

            //GET ROWS
            //--------------------------------------------------------------------------------------------------
            .addCase(getClientDataAsync.pending, (state) => {
                state.status.statusClient = "load";
            })
            .addCase(getClientDataAsync.fulfilled, (state, action) => {
                try {
                    if (action.payload.active) {
                        state.data.data = action.payload.data;
                        state.status.statusClient = "success";
                    } else {
                        state.data = initialState.data;
                        state.status.statusClient = "unsuccess";
                    }
                } catch (err) {
                    state.status.statusClient = "failed";
                    console.log("TypeError: JsonError when attempting resolve data.");
                }
            })
            .addCase(getClientDataAsync.rejected, (state) => {
                state.status.statusClient = "failed";
                console.log("TypeError: NetworkError when attempting to fetch resource.");
            })

            //UPDATE CLIENT
            //--------------------------------------------------------------------------------------------------
            .addCase(updateClientDataAsync.pending, (state) => {
                state.status.statusUpdate = "load";
            })
            .addCase(updateClientDataAsync.fulfilled, (state, action) => {
                try {
                    if (action.payload.active) {
                        state.data.data = action.payload.data;
                        state.status.statusUpdate = "success";
                        state.status.statusClient = "success";
                    } else {
                        state.data = initialState.data;
                        state.status.statusUpdate = "unsuccess";
                    }
                } catch (err) {
                    state.status.statusUpdate = "failed";
                    console.log("TypeError: JsonError when attempting resolve data.");
                }
            })
            .addCase(updateClientDataAsync.rejected, (state) => {
                state.status.statusUpdate = "failed";
                console.log("TypeError: NetworkError when attempting to fetch resource.");
            })

            //CHECK PESEL
            //--------------------------------------------------------------------------------------------------
            .addCase(peselDataAsync.pending, (state) => {
                state.status.statusPesel = "load";
            })
            .addCase(peselDataAsync.fulfilled, (state, action) => {
                try {
                    if (action.payload.active) {
                        state.status.statusPesel = "success";
                    } else {
                        state.status.statusPesel = "unsuccess";
                    }
                } catch (err) {
                    state.status.statusPesel = "failed";
                    console.log("TypeError: JsonError when attempting resolve data.");
                }
            })
            .addCase(peselDataAsync.rejected, (state) => {
                state.status.statusPesel = "failed";
                console.log("TypeError: NetworkError when attempting to fetch resource.");
            });
    },
});

//create actions obj
export const { setInitClientData, setUnitClientData, setStatusUpdate, setSearchData, setPageData, setStatusPesel } =
    clientSlice.actions;

//get data from store
export const selectClientData = (state: RootState) => state.clientState.data;
export const selectSearchData = (state: RootState) => state.clientState.search;
export const selectStatusData = (state: RootState) => state.clientState.status;
export const selectPageData = (state: RootState) => state.clientState.page;

//create reducer
export default clientSlice.reducer;
