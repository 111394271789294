import { useEffect } from "react";
//helper
import { viewSpinner } from "../../helper/viewSpinner";
//comp
import PageClientNav from "./PageClientNav";
import PageClientTab from "./tab/PageClientTab";
//redux
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
    getClientDataAsync,
    setInitClientData,
    selectSearchData,
    selectStatusData,
} from "../../redux/reducer/client/clientSlice";
import { selectUserData } from "../../redux/reducer/user/userSlice";

//----------------------------------------------------------------

export default function PageClientIndex() {
    //redux
    const dispatch = useAppDispatch();
    const { access_token } = useAppSelector(selectUserData);
    const searchData = useAppSelector(selectSearchData);
    const { statusClient } = useAppSelector(selectStatusData);

    //hook
    useEffect(() => {
        dispatch(getClientDataAsync({ data: searchData, access_token }));
        return () => {
            dispatch(setInitClientData());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //view
    return (
        <>
            <PageClientNav />
            {viewSpinner(statusClient, <PageClientTab />)}
        </>
    );
}
