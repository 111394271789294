import { FloatingLabel, Form } from "react-bootstrap";
import { BsArrow90DegUp } from "react-icons/bs";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { peselDataAsync, selectStatusData, setStatusPesel } from "../../../redux/reducer/client/clientSlice";
import { selectUserData } from "../../../redux/reducer/user/userSlice";

//------------------------------------------------------
export default function FormClient({ formik, disabled }: { formik: any; disabled: boolean }) {
    //redux
    const dispatch = useAppDispatch();
    const { statusPesel } = useAppSelector(selectStatusData);
    const { access_token } = useAppSelector(selectUserData);

    //checkpesel
    const checkPesel = () => {
        if (formik.values.pesel.length === 11) {
            const data = formik.values.pesel;
            dispatch(peselDataAsync({ data: { pesel: data }, access_token }));
        } else {
            dispatch(setStatusPesel("init"));
        }
    };

    //view
    return (
        <>
            <FloatingLabel label="Imię">
                <Form.Control
                    id="firstname"
                    type="text"
                    maxLength={20}
                    disabled={disabled}
                    placeholder="Imię"
                    {...formik.getFieldProps("firstname")}
                />
            </FloatingLabel>
            {formik.touched.firstname && formik.errors.firstname && (
                <h6 className="validError">
                    <BsArrow90DegUp />
                    {formik.errors.firstname}
                </h6>
            )}

            <FloatingLabel label="Nazwisko">
                <Form.Control
                    id="surname"
                    type="text"
                    maxLength={30}
                    disabled={disabled}
                    placeholder="Nazwisko"
                    {...formik.getFieldProps("surname")}
                />
            </FloatingLabel>
            {formik.touched.surname && formik.errors.surname && (
                <h6 className="validError">
                    <BsArrow90DegUp />
                    {formik.errors.surname}
                </h6>
            )}

            <FloatingLabel label="Dowód osobisty">
                <Form.Control
                    id="nrDow"
                    type="text"
                    maxLength={9}
                    disabled={disabled}
                    placeholder="Dowód osobisty"
                    {...formik.getFieldProps("nrDow")}
                />
            </FloatingLabel>
            {formik.touched.nrDow && formik.errors.nrDow && (
                <h6 className="validError">
                    <BsArrow90DegUp />
                    {formik.errors.nrDow}
                </h6>
            )}

            <FloatingLabel label={`PESEL(${statusPesel})`}>
                <Form.Control
                    id="pesel"
                    type="text"
                    maxLength={11}
                    disabled={disabled}
                    placeholder={`PESEL(${statusPesel})`}
                    onKeyUp={checkPesel}
                    {...formik.getFieldProps("pesel")}
                />
            </FloatingLabel>
            {formik.touched.pesel && formik.errors.pesel && (
                <h6 className="validError">
                    <BsArrow90DegUp />
                    {formik.errors.pesel}
                </h6>
            )}

            <FloatingLabel label="Adres">
                <Form.Control
                    id="address"
                    as="textarea"
                    disabled={disabled}
                    placeholder="Adres"
                    {...formik.getFieldProps("address")}
                />
            </FloatingLabel>
            {formik.touched.address && formik.errors.address && (
                <h6 className="validError">
                    <BsArrow90DegUp />
                    {formik.errors.address}
                </h6>
            )}

            <FloatingLabel label="Telefon">
                <Form.Control
                    id="phone"
                    type="text"
                    maxLength={9}
                    disabled={disabled}
                    placeholder="Telefon"
                    {...formik.getFieldProps("phone")}
                />
            </FloatingLabel>
            {formik.touched.phone && formik.errors.phone && (
                <h6 className="validError">
                    <BsArrow90DegUp />
                    {formik.errors.phone}
                </h6>
            )}

            <FloatingLabel label="Notatki">
                <Form.Control
                    id="notes"
                    as="textarea"
                    disabled={disabled}
                    placeholder="Notatki"
                    {...formik.getFieldProps("notes")}
                />
            </FloatingLabel>
            {formik.touched.notes && formik.errors.notes && (
                <h6 className="validError">
                    <BsArrow90DegUp />
                    {formik.errors.notes}
                </h6>
            )}

            <FloatingLabel label="Aktywny">
                <Form.Select id="active" disabled={disabled} {...formik.getFieldProps("active")}>
                    <option value="A">Aktywny</option>
                    <option value="U">Nieaktywny</option>
                </Form.Select>
            </FloatingLabel>
        </>
    );
}
