import { Modal, Button } from "react-bootstrap";
import MoveIndex from "./MoveIndex";
//redux
import { initData, selectStatus } from "../../../../../redux/reducer/moveDepot/moveDepotSlice";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { setBoardData } from "../../../../../redux/reducer/board/boardSlice";

//---------------------------------------------------------------

export default function MoveModal({ show, onHide }: { show: boolean; onHide: any }) {
    const dispatch = useAppDispatch();
    const status = useAppSelector(selectStatus);

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            //size="lg"
            backdrop="static"
            keyboard={false}
            scrollable={true}
            onExited={() => {
                if (status === "success") {
                    dispatch(initData());
                    dispatch(setBoardData("agreeP"));
                }
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Przenoszenie umowy do wstępnych</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <MoveIndex />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}
