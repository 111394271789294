//styles
import { Pagination } from "react-bootstrap";
import styles from "../../styles/Client.module.css";
//redux
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectUserData } from "../../redux/reducer/user/userSlice";
import {
    getClientDataAsync,
    selectClientData,
    selectSearchData,
    setPageData,
    selectPageData,
} from "../../redux/reducer/client/clientSlice";

//---------------------------------------------------------

export default function PaginPage() {
    //redux
    const dispatch = useAppDispatch();
    const { data } = useAppSelector(selectClientData);
    const searchData = useAppSelector(selectSearchData);
    const pageData = useAppSelector(selectPageData);
    const { access_token } = useAppSelector(selectUserData);

    //variable
    const rows = 20;
    const pages = Math.ceil(data.amount / rows);

    //setPagin
    const setPagin = (direction: number) => {
        const update = pageData + direction;
        const interval = update * rows;
        const data = { ...searchData, interval };
        dispatch(setPageData(update));
        dispatch(getClientDataAsync({ data, access_token }));
    };

    //view
    return pages > 1 ? (
        <Pagination size="lg" className="mt-0 mb-0" style={{ marginRight: "10px" }}>
            <Pagination.Item>
                <span className={styles.textColor}>{data.amount}</span>
            </Pagination.Item>

            {pageData > 0 && (
                <>
                    <Pagination.First
                        onClick={() => {
                            dispatch(setPageData(0));
                            dispatch(getClientDataAsync({ data: { ...searchData, interval: 0 }, access_token }));
                        }}
                    />
                    <Pagination.Prev
                        onClick={() => {
                            setPagin(-1);
                        }}
                    />
                </>
            )}

            {pageData + 1 < pages && (
                <>
                    <Pagination.Next
                        onClick={() => {
                            setPagin(+1);
                        }}
                    />
                    <Pagination.Last
                        onClick={() => {
                            dispatch(setPageData(pages - 1));
                            dispatch(
                                getClientDataAsync({
                                    data: { ...searchData, interval: (pages - 1) * 20 },
                                    access_token,
                                })
                            );
                        }}
                    />
                </>
            )}

            <Pagination.Item>
                <span className={styles.textColor}>
                    {pageData + 1}/{pages}
                </span>
            </Pagination.Item>
        </Pagination>
    ) : (
        <></>
    );
}
