export function fetchApi(url='', data={}, access_token=''){

    return fetch(url,{
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'authorization': access_token                
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
    })
    .then(res => res.json());
    //.catch(err => { console.log(err) });    
  }