import { FloatingLabel, Form } from "react-bootstrap";
import { BsArrow90DegUp } from "react-icons/bs";

//-----------------------------------------------------
export default function FormItem({ formik, disabled }: { formik: any; disabled: boolean }) {
    return (
        <>
            <FloatingLabel label="Id">
                <Form.Control id="id" type="text" disabled={true} placeholder="Id" {...formik.getFieldProps("id")} />
            </FloatingLabel>

            <FloatingLabel label="Nazwa">
                <Form.Control
                    id="name"
                    type="text"
                    maxLength={50}
                    disabled={disabled}
                    placeholder="Nazwa"
                    {...formik.getFieldProps("name")}
                />
            </FloatingLabel>
            {formik.touched.name && formik.errors.name && (
                <h6 className="validError">
                    <BsArrow90DegUp />
                    {formik.errors.name}
                </h6>
            )}

            <FloatingLabel label="Stan">
                <Form.Select id="quality" disabled={disabled} {...formik.getFieldProps("quality")}>
                    <option value="">Wybierz stan</option>
                    <option value="new">Nowy</option>
                    <option value="used">Używany</option>
                    <option value="damaged">Uszkodzony</option>
                </Form.Select>
            </FloatingLabel>
            {formik.touched.quality && formik.errors.quality && (
                <h6 className="validError">
                    <BsArrow90DegUp />
                    {formik.errors.quality}
                </h6>
            )}

            <FloatingLabel label="Sztuk">
                <Form.Control
                    id="amount"
                    type="text"
                    maxLength={5}
                    disabled={disabled}
                    placeholder="Sztuk"
                    {...formik.getFieldProps("amount")}
                />
            </FloatingLabel>
            {formik.touched.amount && formik.errors.amount && (
                <h6 className="validError">
                    <BsArrow90DegUp />
                    {formik.errors.amount}
                </h6>
            )}

            <FloatingLabel label="Notatki">
                <Form.Control
                    id="notes"
                    as="textarea"
                    disabled={disabled}
                    placeholder="Notatki"
                    {...formik.getFieldProps("notes")}
                />
            </FloatingLabel>
            {formik.touched.notes && formik.errors.notes && (
                <h6 className="validError">
                    <BsArrow90DegUp />
                    {formik.errors.notes}
                </h6>
            )}
        </>
    );
}
