import { Form, Row, Col, FloatingLabel } from "react-bootstrap";
//redux
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectUserData } from "../../redux/reducer/user/userSlice";
import {
    getClientDataAsync,
    setSearchData,
    selectSearchData,
    setPageData,
    selectPageData,
} from "../../redux/reducer/client/clientSlice";

//-----------------------------------------------------

export default function SearchByText() {
    //redux
    const dispatch = useAppDispatch();
    const { access_token } = useAppSelector(selectUserData);
    const searchData = useAppSelector(selectSearchData);
    const pageData = useAppSelector(selectPageData);

    //onchange
    const onChangeText = (ev: any) => {
        const { value } = ev.target;
        const data = { ...searchData, text: value };
        dispatch(setSearchData(data));
        if (value.length > 1 || value.length === 0) {
            if (pageData > 1) dispatch(setPageData(0));
            dispatch(getClientDataAsync({ data, access_token }));
        }
    };

    //view
    return (
        <Form style={{ marginRight: "10px" }}>
            <Row className="g-0">
                <Col sm>
                    <FloatingLabel label="Szukaj po">
                        <Form.Control
                            type="text"
                            value={searchData.text}
                            onChange={onChangeText}
                            placeholder="Szukaj po"
                        />
                    </FloatingLabel>
                </Col>
            </Row>
        </Form>
    );
}
