import styles from "../../styles/Client.module.css";
import { Container, Navbar, Nav } from "react-bootstrap";
//comp
import SerchByDate from "../../comp/item/SearchByDate";
import SearchByText from "../../comp/item/SearchByText";
import PaginPage from "../../comp/item/PaginPage";
import AddButton from "../../comp/item/AddButton";
import SearchByStatus from "../../comp/item/SearchByStatus";
import SearchByLocation from "../../comp/item/SearchByLocation";
import { useAppSelector } from "../../hooks";
import { selectUserData } from "../../redux/reducer/user/userSlice";

//------------------------------------------------
export default function PageItemNav() {
    //redux
    const { user } = useAppSelector(selectUserData);
    //view
    return (
        <Navbar expand="lg" bg="secondary" variant="dark" className={styles.navBarRadius}>
            <Container fluid>
                <Navbar.Brand>PRZEDMIOTY</Navbar.Brand>

                <Navbar>
                    <Nav>
                        <AddButton />
                    </Nav>
                    <Nav>
                        <PaginPage />
                    </Nav>
                    <Nav>
                        <SearchByText />
                    </Nav>
                    <Nav>
                        <SerchByDate />
                    </Nav>
                    <Nav>
                        <SearchByStatus />
                    </Nav>
                    <Nav>{user.role === "A" && <SearchByLocation />}</Nav>
                </Navbar>
            </Container>
        </Navbar>
    );
}
