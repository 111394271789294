import { Nav } from "react-bootstrap";
//redux
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { selectBoardData, setBoardData } from "../../../redux/reducer/board/boardSlice";

//-------------------------------------------------------------------
export default function AgreeNavP() {
    //redux
    const dispatch = useAppDispatch();
    const data = useAppSelector(selectBoardData);

    //view
    return (
        <Nav.Item>
            <Nav.Link
                active={data === "agreeP"}
                eventKey="agreeP-link"
                onClick={() => {
                    dispatch(setBoardData("agreeP"));
                }}
            >
                Umowy wstępne
            </Nav.Link>
        </Nav.Item>
    );
}
