//bootstrap
import { Button, Spinner } from "react-bootstrap";
//redux
import { useAppDispatch, useAppSelector } from "../../../../../../hooks";
import { selectStatus, setStatusUpdate } from "../../../../../../redux/reducer/agree/agreeSliceP";

//----------------------------------------------------------

export default function AgreeSubmit({ formik }: any) {
    //redux
    const dispatch = useAppDispatch();
    const { statusAgreePupdate } = useAppSelector(selectStatus);

    //function
    const switchButtons = () => {
        switch (statusAgreePupdate) {
            case "init": {
                return (
                    <Button
                        variant="primary"
                        onClick={() => {
                            formik.handleSubmit();
                        }}
                    >
                        Aktualizuj umowę
                    </Button>
                );
            }
            case "load": {
                return (
                    <Button variant="primary" disabled>
                        <Spinner as="span" animation="border" role="status" aria-hidden="true" />
                        <span className="visually-hidden">Loading...</span>
                    </Button>
                );
            }
            case "success": {
                return (
                    <Button variant="success" disabled>
                        Umowa zaktualizowana
                    </Button>
                );
            }
            case "unsuccess": {
                return (
                    <Button
                        variant="warning"
                        onClick={() => {
                            dispatch(setStatusUpdate("init"));
                        }}
                    >
                        Zapis nieudany
                    </Button>
                );
            }
            case "failed": {
                return (
                    <Button
                        variant="danger"
                        onClick={() => {
                            dispatch(setStatusUpdate("init"));
                        }}
                    >
                        Połączenie nieudane
                    </Button>
                );
            }
            default: {
            }
        }
    };

    //view
    return <>{switchButtons()}</>;
}
