import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { fetchApi } from "../../../helper/fetchApi";

//init state
const initialState = {
    data: {},
    status: "init",
};

export const updateAgreePdataAsync = createAsyncThunk(
    "/extendState/updateAgreePdataAsync",
    async (data: { data: any; access_token: string }, { rejectWithValue }) => {
        try {
            const response = await fetchApi("/agree/extend", data.data, data.access_token);
            return response;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

//create slice obj
export const extendSlice = createSlice({
    name: "extendState",
    initialState,
    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload;
        },
        initData: (state) => {
            state.data = initialState.data;
            state.status = initialState.status;
        },
        setExtendData: (state, action) => {
            state.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            //ADD PICTURE
            .addCase(updateAgreePdataAsync.pending, (state) => {
                state.status = "load";
            })
            .addCase(updateAgreePdataAsync.fulfilled, (state, action) => {
                //success connection?
                try {
                    //success login?
                    if (action.payload.active) {
                        state.status = "success";
                    } else {
                        state.status = "unsuccess";
                    }
                } catch (err) {
                    state.status = "failed";
                    console.log("TypeError: JsonError when attempting resolve data.");
                }
            })
            .addCase(updateAgreePdataAsync.rejected, (state) => {
                state.status = "failed";
                console.log("TypeError: NetworkError when attempting to fetch resource.");
            });
    },
});

//create actions obj
export const { setStatus, initData, setExtendData } = extendSlice.actions;

//get data from store
export const selectExtendData = (state: RootState) => state.extendState.data;
export const selectStatus = (state: RootState) => state.extendState.status;

//create reducer
export default extendSlice.reducer;
