import { useState } from "react";
import { Form, Button, Table, Spinner } from "react-bootstrap";
//helper
import { retrieveImg } from "../../../../helper/manageImage";
//redux
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { selectUnitData, setUnitItemData, selectStatusData } from "../../../../redux/reducer/item/itemSlice";

//--------------------------------------------------------------------------
export default function IteEditFileList({ files, setFiles }) {
    //redux
    const dispatch = useAppDispatch();
    const unitData = useAppSelector(selectUnitData);
    const { statusUpdate } = useAppSelector(selectStatusData);

    //spiderButton
    const [showSpiner, setShowSpiner] = useState(false);

    //onchange
    const onChangeHendler = (event) => {
        new Promise((solve) => {
            setShowSpiner(true);
            retrieveImg(Array.from(event.target.files), (f) => solve(f));
        }).then((files) => {
            setFiles([...files]);
            setShowSpiner(false);
        });
    };

    //view
    return (
        <>
            <Form.Control
                id="file"
                name="file"
                type="file"
                style={{ display: "none" }}
                onChange={onChangeHendler}
                accept="image/*"
                multiple
            />
            {showSpiner ? (
                <Button variant="secondary" disabled>
                    <Spinner as="span" animation="border" role="status" aria-hidden="true" />
                    <span className="visually-hidden">Loading...</span>
                </Button>
            ) : (
                <Button
                    variant="secondary"
                    size="lg"
                    disabled={statusUpdate !== "init"}
                    onClick={() => {
                        document.getElementById("file").click();
                    }}
                >
                    {files.length > 0 ? `Wybrano: ${files.length + unitData.image.length}` : "Wybierz zdjęcia"}
                </Button>
            )}
            <Table bordered size="sm">
                <tbody>
                    {files.map((file, inx) => {
                        return (
                            <tr key={inx}>
                                <td>
                                    <Table>
                                        <tbody>
                                            <tr>
                                                <td>{file.name + "(" + Math.round(file.size * 0.001) + "KB)"}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Button
                                                        disabled={statusUpdate === "success"}
                                                        // style={{ marginLeft: "10px" }}
                                                        variant="danger"
                                                        onClick={() => {
                                                            setFiles(files.filter((x, index) => index !== inx));
                                                        }}
                                                    >
                                                        usuń
                                                    </Button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <img
                                                        width="350px"
                                                        alt={file.name}
                                                        src={URL.createObjectURL(file)}
                                                    />
                                                </td>{" "}
                                            </tr>
                                        </tbody>
                                    </Table>
                                </td>
                            </tr>
                        );
                    })}

                    {unitData.image.map((path, inx) => {
                        return (
                            <tr key={inx}>
                                <td>
                                    <Table>
                                        <tbody>
                                            <tr>
                                                <td>Na serwerze</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Button
                                                        disabled={statusUpdate !== "init"}
                                                        variant="danger"
                                                        onClick={() => {
                                                            dispatch(
                                                                setUnitItemData({
                                                                    ...unitData,
                                                                    image: unitData.image.filter(
                                                                        (x, index) => index !== inx
                                                                    ),
                                                                })
                                                            );
                                                        }}
                                                    >
                                                        usuń
                                                    </Button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <img
                                                        width="350px"
                                                        alt="foto"
                                                        src={`${process.env.PUBLIC_URL}/item/image/${path}`}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </>
    );
}
