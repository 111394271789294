import { Container, Navbar, Nav } from "react-bootstrap";
//comp
import LoginNav from "./login/LoginNav";
import ClockNav from "../clock/ClockNav";
import ClientNav from "./client/ClientNav";
import ItemNav from "./item/ItemNav";
import LocationNav from "./location/LocationNav";
//redux
import { useAppSelector } from "../../hooks";
import { selectUserData } from "../../redux/reducer/user/userSlice";
import AgreeNavPasek from "./agree/BottomBar/AgreeNavPasek";
import AgreeNavP from "./agree/AgreeNavP";
import AgreeNavS from "./agree/AgreeNavS";
import SetNav from "./setting/SetNav";
import ReportNav from "./report/ReportNav";

//---------------------------------------------------------------

export default function MainNav() {
    //redux
    const { active, user } = useAppSelector(selectUserData);

    //view
    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top">
            <Container fluid>
                <Navbar.Brand onClick={() => {}}>LOMBARD</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    {active ? (
                        <>
                            <Nav className="me-auto">
                                {/* LINK1 */}
                                <ClientNav />
                                {/* LINK2 */}
                                <ItemNav />
                                {/* LINK3 */}
                                <AgreeNavP />
                                {/* LINK3 */}
                                <AgreeNavS />
                                {/* LINK4 */}
                                <ReportNav />
                            </Nav>
                            <Nav>
                                {/* LINK4 */}
                                <LocationNav />
                                {/* LINK5 */}
                                <ClockNav />
                                {/* LINK6 */}
                                {user.role === "A" && <SetNav />}
                            </Nav>
                        </>
                    ) : (
                        <Nav className="me-auto">
                            <Nav.Item>
                                <Nav.Link eventKey="disabled" disabled>
                                    Info: 08.05.2023
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    )}
                    <Nav>
                        {/* LINK13 */}
                        <LoginNav
                            name={active ? `${user.name}(${user.role})` : "Login"}
                            placement="end"
                            scroll={true}
                            backdrop={true}
                        />
                    </Nav>
                </Navbar.Collapse>
                <AgreeNavPasek />
            </Container>
        </Navbar>
    );
}
