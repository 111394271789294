import { Col, Form, Row, ListGroup } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormClient from "../FormClient";
import Submit from "./Submit";
import styles from "../../../../styles/Client.module.css";

/**
 *
 * @client Dane klienta
 * @callback Funkcja do update
 * @disabled Disable all fields
 *
 * @returns
 */
export default function Index({ client, callback, disabled }: { client: any; callback: any; disabled: boolean }) {
    //Formik
    const formik = useFormik({
        initialValues: {
            id: client.id,
            firstname: client.firstname,
            surname: client.surname,
            nrDow: client.nrDow,
            pesel: client.pesel,
            address: client.address,
            phone: client.phone,
            notes: client.notes,
            active: client.active,
        },
        validationSchema: Yup.object({
            firstname: Yup.string().required("Wymagane").min(3, "Min 3 chars").max(20, "Max 20 chars"),
            surname: Yup.string().required("Wymagane").min(3, "Min 3 chars").max(30, "Max 30 chars"),
            nrDow: Yup.string().required("Wymagane").min(9, "Min 9 chars").max(9, "Max 9 chars"),
            pesel: Yup.string()
                .matches(/^[\d]*$/, "Tylko cyfry")
                .required("Wymagane")
                .min(11, "Min 11 chars")
                .max(11, "Max 11 chars"),
            address: Yup.string().required("Wymagane").min(6, "Min 6 chars"),
            phone: Yup.string()
                .matches(/^[\d]*$/, "Tylko cyfry")
                .required("Wymagane")
                .min(9, "Min 9 chars")
                .max(9, "Max 9 chars"),
            notes: Yup.string().nullable(),
        }),
        onSubmit: (values) => {
            callback(values);
        },
    });

    //view
    return (
        <Form>
            <Row>
                <Col className="d-grid gap-1 mb-1">
                    <ListGroup className={styles.textRight}>
                        <ListGroup.Item variant="secondary">EDYTUJ KLIENTA</ListGroup.Item>
                    </ListGroup>
                    <FormClient formik={formik} disabled={disabled} />
                    <Submit formik={formik} />
                </Col>
            </Row>
        </Form>
    );
}
