import styles from "../../styles/Client.module.css";
import { Container, Navbar, Nav } from "react-bootstrap";
//comp
import SerchByDate from "../../comp/agree/agreeS/SearchByDate";
import SearchByText from "../../comp/agree/agreeS/SearchByText";
import PaginPage from "../../comp/agree/agreeS/PaginPage";
import SearchByStatus from "../../comp/agree/agreeS/SearchByStatus";
import SearchByLocation from "../../comp/agree/agreeS/SearchByLocation";
import { useAppSelector } from "../../hooks";
import { selectUserData } from "../../redux/reducer/user/userSlice";
import ImportAgree from "../../comp/agree/agreeS/ImportAgree.";

//------------------------------------------------------
export default function PageAgreeNav() {
    //redux
    const { user } = useAppSelector(selectUserData);
    //view
    return (
        <Navbar expand="lg" bg="secondary" variant="dark" className={styles.navBarRadius}>
            <Container fluid>
                <Navbar.Brand>
                    UMOWY SKUPU
                    <Nav>
                        <ImportAgree />
                    </Nav>
                </Navbar.Brand>

                <Navbar>
                    <Nav>
                        <PaginPage />
                    </Nav>
                    <Nav>
                        <SearchByText />
                    </Nav>
                    <Nav>
                        <SerchByDate />
                    </Nav>
                    <Nav>
                        <SearchByStatus />
                    </Nav>
                    <Nav>{user.role === "A" && <SearchByLocation />}</Nav>
                </Navbar>
            </Container>
        </Navbar>
    );
}
