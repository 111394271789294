import { useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { selectUserData } from "../../../../../redux/reducer/user/userSlice";
import { setDataAsync, selectStatus, setStatus } from "../../../../../redux/reducer/movePoint/setPointSlice";

//-----------------------------------------------------------------------

export default function GetPointButton({ obj, inx }: any) {
    //hook
    useEffect(() => {
        dispatch(setStatus({ status: "init", inx }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    //reux
    const dispatch = useAppDispatch();
    const status = useAppSelector(selectStatus);
    const { access_token } = useAppSelector(selectUserData);

    //request
    const moveAction = () => {
        dispatch(setStatus({ status: "init", inx }));
        dispatch(
            setDataAsync({
                data: {
                    id_agree: obj.id_agree,
                    id_movePoint: obj.id,
                    id_location_source: obj.id_location_source,
                    id_location_dest: obj.id_location_dest,
                    price: obj.price,
                    notes: obj.notes,
                },
                access_token,
            })
        );
    };
    //view
    const viewButton = () => {
        switch (status[inx]) {
            case "init": {
                return (
                    <Button
                        variant="info"
                        onClick={() => {
                            moveAction();
                        }}
                    >
                        Przenieś
                    </Button>
                );
            }
            case "load": {
                return (
                    <Button variant="primary" disabled>
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        <span className="visually-hidden">Load...</span>
                    </Button>
                );
            }
            case "success": {
                return (
                    <Button variant="success" disabled>
                        OK
                    </Button>
                );
            }
            case "unsuccess": {
                return (
                    <Button
                        variant="warning"
                        onClick={() => {
                            dispatch(setStatus({ status: "init", inx }));
                        }}
                    >
                        Nieudane
                    </Button>
                );
            }
            case "failed": {
                return (
                    <Button
                        variant="danger"
                        onClick={() => {
                            dispatch(setStatus({ status: "init", inx }));
                        }}
                    >
                        Nieudane
                    </Button>
                );
            }
            default: {
            }
        }
    };
    //view
    return <>{viewButton()}</>;
}
