import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { fetchApi } from "../../../helper/fetchApi";

interface initInitState {
    status: Array<any>;
    inx: number;
}

//init state
const initialState: initInitState = {
    status: [],
    inx: 0,
};

export const setDataAsync = createAsyncThunk("/setPointState/setDataAsync", async (data: any, { rejectWithValue }) => {
    try {
        const response = await fetchApi(`/agree/setPoint`, data.data, data.access_token);
        return response;
    } catch (err: any) {
        return rejectWithValue(err.response.data);
    }
});

//create slice obj
export const setPointSlice = createSlice({
    name: "setPointState",
    initialState,
    reducers: {
        setStatus: (state, action: PayloadAction<any>) => {
            const inx = action.payload.inx;
            state.inx = inx;
            state.status[inx] = action.payload.status;
        },
        initData: (state) => {
            // state.data = initialState.data;
            state.status = initialState.status;
            state.inx = initialState.inx;
        },
    },
    extraReducers: (builder) => {
        builder
            //ADD PICTURE
            .addCase(setDataAsync.pending, (state) => {
                const inx = state.inx;
                state.status[inx] = "load";
            })
            .addCase(setDataAsync.fulfilled, (state, action) => {
                const inx = state.inx;
                //success connection?
                try {
                    //success login?
                    if (action.payload.active) {
                        state.status[inx] = "success";
                    } else {
                        state.status[inx] = "unsuccess";
                    }
                } catch (err) {
                    state.status[inx] = "failed";
                    console.log("TypeError: JsonError when attempting resolve data.");
                }
            })
            .addCase(setDataAsync.rejected, (state) => {
                const inx = state.inx;
                state.status[inx] = "failed";
                console.log("TypeError: NetworkError when attempting to fetch resource.");
            });
    },
});

//create actions obj
export const { initData, setStatus } = setPointSlice.actions;

//get data from store
export const selectStatus = (state: RootState) => state.setPointState.status;

//create reducer
export default setPointSlice.reducer;
