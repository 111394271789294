import { Modal, Button } from "react-bootstrap";
//import MoveIndex from './MoveIndex';
//redux
import { initData } from "../../../../../redux/reducer/movePoint/movePointSlice";
import MovePointIndex from "./MovePointIndex";
import { useAppDispatch } from "../../../../../hooks";

//---------------------------------------------------------------

export default function MovePointModal({ show, onHide }: { show: boolean; onHide: any }) {
    const dispatch = useAppDispatch();

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            //size="lg"
            backdrop="static"
            keyboard={false}
            scrollable={true}
            onExited={() => {
                dispatch(initData());
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Przenieś Umowę na inny Punkt</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <MovePointIndex />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}
