//css
import { Button, Spinner } from "react-bootstrap";
//redux
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
    selectStatusAddLocation,
    addLocatonDataAsync,
    setStatusAddLocation,
} from "../../../redux/reducer/user/userSlice";
import { selectUserData } from "../../../redux/reducer/user/userSlice";

//----------------------------------------------------------
export default function LocationSubmit({ newPoint }: any) {
    //redux
    const dispatch = useAppDispatch();
    const { access_token } = useAppSelector(selectUserData);
    const statusAddLocation = useAppSelector(selectStatusAddLocation);

    //new location
    const switchButtons = () => {
        switch (statusAddLocation) {
            case "init": {
                return (
                    <Button
                        variant="primary"
                        size="lg"
                        onClick={() => {
                            if (newPoint.length > 2) {
                                dispatch(addLocatonDataAsync({ data: { id: 0, location: newPoint }, access_token }));
                            } else {
                                alert("Wypełnij pole Punkt");
                            }
                        }}
                    >
                        Dodaj lokalizację
                    </Button>
                );
            }
            case "load": {
                return (
                    <Button variant="primary" disabled>
                        <Spinner as="span" animation="border" role="status" aria-hidden="true" />
                        <span className="visually-hidden">Loading...</span>
                    </Button>
                );
            }
            case "success": {
                return (
                    <Button
                        variant="success"
                        size="lg"
                        onClick={() => {
                            dispatch(setStatusAddLocation("init"));
                        }}
                    >
                        Punkt został dodany
                    </Button>
                );
            }
            case "unsuccess": {
                return (
                    <Button
                        variant="warning"
                        size="lg"
                        onClick={() => {
                            dispatch(setStatusAddLocation("init"));
                        }}
                    >
                        Zapis nieudany
                    </Button>
                );
            }
            case "failed": {
                return (
                    <Button
                        variant="danger"
                        size="lg"
                        onClick={() => {
                            dispatch(setStatusAddLocation("init"));
                        }}
                    >
                        Połączenie nieudane
                    </Button>
                );
            }
            default: {
                return <></>;
            }
        }
    };

    //view
    return <>{switchButtons()}</>;
}
