import { Spinner } from "react-bootstrap";

//view with spiner function
export const viewSpinner = (status: string, comp: any) => {
    switch (status) {
        case "load": {
            return (
                <div style={{ margin: "auto", textAlign: "center", paddingTop: "10%" }}>
                    <Spinner
                        as="span"
                        animation="border"
                        // size="lg"
                        role="status"
                        aria-hidden="true"
                        variant="warning"
                    />
                </div>
            );
        }
        case "unsuccess": {
            return (
                <strong style={{ margin: "auto", textAlign: "center", paddingTop: "10%", color: "white" }}>
                    Brak wyników wyszukiwania
                </strong>
            );
        }
        case "success": {
            return comp;
        }
        default: {
            return (
                <strong style={{ margin: "auto", textAlign: "center", paddingTop: "10%", color: "white" }}>
                    Połączenie lub autoryzacja nieudana
                </strong>
            );
        }
    }
};
