import { useState, useEffect } from "react";
import { Nav } from "react-bootstrap";
//redux
// import { useAppDispatch, useAppSelector } from "../../hooks";
import { useDispatch } from "react-redux";
import { setStatusToken } from "../../redux/reducer/user/userSlice";

export default function Clocker(props) {
    //rendering
    useEffect(() => {
        renewToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //redux
    const dispatch = useDispatch();

    const [colour, setColour] = useState("#80FF00");
    const [counter, setCounter] = useState(3600);
    const [refer, setRefer] = useState();

    //parse time
    const ParseTime = (init = 3600) => {
        let multi = init * 1000;
        let time = new Date(0, 0, 0, 0, 0, 0, multi);
        return time.toLocaleTimeString("en-UK");
    };

    //counter
    const renewToken = () => {
        let count = 3600;
        clearInterval(refer);
        setRefer(
            setInterval(() => {
                setCounter(count);
                if (count === 0) {
                    clearInterval(refer);
                    dispatch(setStatusToken("unsuccess"));
                }
                switch (count) {
                    case 3600:
                        setColour("#80FF00");
                        break; //1h
                    case 900:
                        setColour("#FF8000");
                        break; //15m
                    case 150:
                        setColour("#FF0000");
                        break; //5m
                    default:
                }
                count--;
            }, 1000)
        );
    };

    //view
    return (
        <Nav.Link
            onClick={() => {
                props.updateToken();
            }}
            style={{ color: colour }}
        >
            {ParseTime(counter)}
        </Nav.Link>
    );
}
