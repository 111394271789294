import { Form, Row, Col, FloatingLabel } from "react-bootstrap";
//redux
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectUserData } from "../../redux/reducer/user/userSlice";
// import {
//     setPageData,
//     selectPageData,
//     searchAgreeDataAsync,
//     selectSearchData,
//     setSearch,
// } from "../../redux/reducer/agree/agreeSliceP";
import {
    getItemDataAsync,
    selectPageData,
    selectSearchData,
    setPageData,
    setSearchData,
} from "../../redux/reducer/item/itemSlice";

//---------------------------------------------------

export default function SearchByStatus() {
    //redux
    const dispatch = useAppDispatch();
    const { access_token } = useAppSelector(selectUserData);
    const searchData = useAppSelector(selectSearchData);
    const pageData = useAppSelector(selectPageData);

    //onchange
    const onChangeStatus = (ev: any) => {
        const { value } = ev.target;
        const search = { ...searchData, status: value };
        dispatch(setSearchData(search));

        if (pageData > 1) dispatch(setPageData(0));
        dispatch(
            getItemDataAsync({
                data: search,
                access_token,
            })
        );
    };

    return (
        <Form>
            <Row className="g-0">
                <Col sm>
                    <FloatingLabel label="Status">
                        <Form.Select id="active" onChange={onChangeStatus}>
                            <option value="all">Wszystkie</option>
                            <option value="pending">Oczekujące</option>
                            <option value="frozen">Zamrożone</option>
                            <option value="depot">Na magazynie</option>
                            <option value="sale">Sprzedane</option>
                        </Form.Select>
                    </FloatingLabel>
                </Col>
            </Row>
        </Form>
    );
}
